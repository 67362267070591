import './msg_box.css'

export class MsgBox {

    static showWarningMsg_2s(msg) {
        this._showWarningMsg(msg, 2000);
    }

    static showWarningMsg_4s(msg) {
        this._showWarningMsg(msg, 4000);
    }

    static _showWarningMsg(msg, time) {
        if ($("#warningMsgBox").length == 0) {
            $('body').append('<div id="warningMsgBox" class="msgbox warning-msgbox"><div class="msgbox-cotent">' + msg + '</div></div>');
        } else {
            $("#warningMsgBox .msgbox-cotent").html(msg);
        }
        if (this.WarningMsgTimeout) {
            clearTimeout(this.WarningMsgTimeout);
            $("#warningMsgBox").css({
                top: '-50px'
            })
        }

        $("#warningMsgBox").animate({
            top: '10px'
        }, 200);
        this.WarningMsgTimeout = setTimeout(function () {
            $("#warningMsgBox").animate({
                top: '-50px'
            }, 200);
        }, time);
    }

    static showSuccessMsg_2s(msg) {
        this._showSuccessMsg(msg, 2000)
    }

    static _showSuccessMsg(msg, time) {
        if ($("#successMsgBox").length == 0) {
            $('body').append('<div id="successMsgBox" class="msgbox success-msgbox"><div class="msgbox-cotent">' + msg + '</div></div>');
        } else {
            $("#successMsgBox .msgbox-cotent").html(msg);
        }
        if (this.SuccessMsgTimeout) {
            clearTimeout(this.SuccessMsgTimeout);
            $("#successMsgBox").css({
                top: '-50px'
            })
        }

        $("#successMsgBox").animate({
            top: "10px"
        }, 200);
        this.SuccessMsgTimeout = setTimeout(function () {
            $("#successMsgBox").animate({
                top: '-50px'
            }, 200);
        }, time);
    }

    static showErrorMsg_2s(msg) {
        this._showErrorMsg(msg, 2000)
    }

    static showErrorMsg_4s(msg) {
        this._showErrorMsg(msg, 4000)
    }

    static _showErrorMsg(msg, time) {
        if ($("#errorMsgBox").length == 0) {
            $('body').append('<div id="errorMsgBox" class="msgbox error-msgbox"><div class="msgbox-cotent">' + msg + '</div></div>');
        } else {
            $("#errorMsgBox .msgbox-cotent").html(msg);
        }
        if (this.ErrorMsgTimeout) {
            clearTimeout(this.ErrorMsgTimeout);
            $("#errorMsgBox").css({
                top: '-50px'
            })
        }

        $("#errorMsgBox").animate({
            top: "10px"
        }, 200);
        this.ErrorMsgTimeout = setTimeout(function () {
            $("#errorMsgBox").animate({
                top: '-50px'
            }, 200);
        }, time);
    }
}
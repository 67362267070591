import React from 'react'
import sidebar from '../../../config/SidebarConfNew';
import {Path} from "../../path";
import {Link, withRouter} from "react-router-dom";
import {Popover} from "antd";
import Message from "../ant/Message";

class SideBarLib extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarVisible: false,
        }
    }

    componentDidMount() {

    }

    goPage(menu) {
        if (menu.path) {
            if (menu.key == 'operateDesc' || menu.target == '_blank') {
                window.open(menu.path, "_blank");
            } else {
                this.props.history.push(menu.path);
            }
            this.setState({
                sidebarVisible: false
            })
        }
    }

    onVisibleChange(flag, visible) {
        if (flag) {
            visible = !flag
        }
        this.setState({
            sidebarVisible: visible
        });
    }

    MessageTip(flag, e) {
        e.stopPropagation();
        if (flag) {
            Message.warning("请先保存当前快捷菜单")
            this.setState({
                sidebarVisible: false
            });
            return
        }
    }

    render() {
        return (
            <Popover getPopupContainer={triggerNode => triggerNode.parentNode}
                     content={
                         <div className={'sideBarLib'}>
                             {
                                 sidebar.map((side) => {
                                     if (!side.hide) {
                                         return (
                                             <div className={'item'}>
                                                 <p className={'sName'}>
                                                     <i className={"img " + side.first.icon}></i>
                                                     {side.first.title}
                                                 </p>
                                                 {
                                                     side.second && side.second.menulist.length > 0 ?
                                                         <ul>
                                                             {
                                                                 side.second.menulist.map((menu) => (
                                                                     <li onClick={this.goPage.bind(this, menu)}>
                                                                         {menu.title}<i className={menu.suffixIcon}></i>
                                                                     </li>
                                                                 ))
                                                             }
                                                         </ul> : ''
                                                 }
                                             </div>
                                         )
                                     }
                                 })
                             }

                         </div>
                     }
                     placement={"bottomLeft"}
                     onVisibleChange={this.onVisibleChange.bind(this, this.props.visible)}
                     overlayClassName={'sidebarPopover'}
                     visible={this.state.sidebarVisible}
            >
                <span
                    className={'cursor-pointer siteNavLib' + (this.state.sidebarVisible ? ' active' : '')}
                    onClick={this.MessageTip.bind(this, this.props.visible)}
                >
                    产品
                    {
                        this.state.sidebarVisible ?
                            <i className={'siteNav_icon siteNav_up'}></i> :
                            <i className={'siteNav_icon siteNav_down'}></i>
                    }</span>

            </Popover>
        )
    }

}

export default withRouter(SideBarLib);
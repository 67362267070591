import React from 'react';
import {Redirect, Route} from "react-router-dom";
import loadable from "../../loadable";

const TaskList = loadable(() => import('./task/list/list.bundle'));
const AuthList = loadable(() => import('./authorize/list.bundle'));
const AuthEWM = loadable(() => import('./authorize/authorize.bundle'));
const MemberList = loadable(() => import('./member/list.bundle'));
const TaskCreate = loadable(() => import('./task/matrix/taskposter.bundle'));
const CorpData = loadable(() => import('./data/index.bundle'));
const TaskFans = loadable(() => import('./task/fans/index.bundle'));
const FormCreate = loadable(() => import("../ext/form/form_create_new.bundle"));
const FormManage = loadable(() => import("../ext/form/form_manage.bundle"));
const FormEdit = loadable(() => import("../ext/form/form_edit.bundle"));
const FormData = loadable(() => import("../ext/form/form_data.bundle"));
const FormLook = loadable(() => import("../ext/form/look_list.bundle"));
const RedeemcodeManage = loadable(() => import("../ext/redeemcode/redeemcode_manage.bundle"));
const RedeemcodeCreate = loadable(() => import("../ext/redeemcode/redeemcode_create.bundle"));
const RedeemcodeData = loadable(() => import("../ext/redeemcode/redeemcode_data.bundle"));
const LogisticsList = loadable(() => import("../logistics/list/list.bundle"));

/**
 * 店铺
 */
class Group extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/task/list"}/>
                )}/>
                <Route path={this.props.match.url + "/task/list"} component={TaskList}/>
                <Route path={this.props.match.url + "/auth/list"} component={AuthList}/>
                <Route path={this.props.match.url + "/auth/ewm"} component={AuthEWM}/>
                <Route path={this.props.match.url + "/member/list"} component={MemberList}/>
                <Route path={this.props.match.url + "/task/create"} render={(props) => {
                    props.editType = 'create';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/edit/:id"} render={(props) => {
                    props.editType = 'edit';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/data/:id"} render={(props) => {
                    return <CorpData {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/copy/:id"} render={(props) => {
                    props.editType = 'copy';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/fans/:id"} render={(props) => {
                    return <TaskFans {...props}/>
                }}/>
                <Route path={this.props.match.url + "/mform/create"} component={FormCreate}/>
                <Route path={this.props.match.url + "/mform/manage"} component={FormManage}/>
                <Route path={this.props.match.url + "/mform/edit/:id"} component={FormEdit}/>
                <Route path={this.props.match.url + "/mform/data/:id"}
                       component={FormData}/>
                <Route path={this.props.match.url + "/mform/look/:id"}
                       component={FormLook}/>

                <Route path={this.props.match.url + "/redeemcode/manage"}
                       component={RedeemcodeManage}/>
                <Route path={this.props.match.url + "/redeemcode/create"}
                       component={RedeemcodeCreate}/>
                <Route path={this.props.match.url + "/redeemcode/data/:id"}
                       component={RedeemcodeData}/>

                {/*物流查询*/}
                <Route path={this.props.match.url + "/logistics/list"} component={LogisticsList}/>
            </div>
        );
    }
}

export default Group
import React from 'react'
import {withRouter, Link} from "react-router-dom";
import conf from '../../config/SidebarConf';
import {Menu} from 'antd';
import Util from "../utils";

import {URL_WEBSITE} from '../variables'

let CollapseMenu = [];
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SideBarNew extends React.Component {

    constructor(props) {
        super(props);
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toSecondPage = this.toSecondPage.bind(this);
        this.initMenu = this.initMenu.bind(this);
        this.activeSecondPage = this.activeSecondPage.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);
        this.activeFirstPage = this.activeFirstPage.bind(this);
        this.state = {
            sidebar: conf,
            defaultOpenKeys: {},
            theme: 1
        }
    }

    componentWillMount() {
        let sidebar = this.state.sidebar;
        let obj = this.state.defaultOpenKeys;
        sidebar.map((side) => {
            if (side.second && side.second.menulist.length > 0) {
                let id = side.first.key;
                let arr = [];
                let itemObj = {};
                let str = null;
                for (let i = 0; i < side.second.menulist.length; i++) {
                    arr.push(String(side.second.menulist[i].key));
                }
                arr.push(side.second.menulist[0].key);
                itemObj.openKeys = arr;
                obj[id] = itemObj;
            }
        });
        this.setState({
            defaultOpenKeys: obj
        });
        this.initMenu();
    }

    componentDidMount() {
        let sidebar = this.state.sidebar;
        let obj = this.state.defaultOpenKeys;
        let scrolls = {};
        sidebar.map((side) => {
            let that = this;
            if (side.second && side.second.menulist.length > 0) {
                let id = side.first.key;
                if (this.refs[id]) {
                    window[id + 'Scroll'] = Util.initScroll(this.refs[id]);
                }
                $("#" + id).mouseDelay(150).hover(function () {
                    $(this).addClass('hover');
                    window[id + 'Scroll'].refresh();

                    that.hoverShow(this);
                }, function () {
                    $(this).removeClass('hover');

                    that.hoverShow(this);
                })


            }
        });
        this.initMenu();
        $('.mxd-sidebar-second .bscroll-indicator').addClass('opacity');
        $('.mxd-sidebar-second').mouseover(function () {
            $(this).find('.bscroll-indicator').removeClass('opacity')
        });
        $('.mxd-sidebar-second').mouseleave(function () {
            $(this).find('.bscroll-indicator').addClass('opacity')
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.initMenu();
        if (this.state.theme != nextProps.theme) {
            this.setState({
                theme: nextProps.theme
            });
            if (nextProps.theme == 0) {
                $('.mxd-sidebar').removeClass('theme2')
            } else {
                $('.mxd-sidebar').addClass('theme2')
            }
        }

    }

    hoverShow(obj) {
        if ($(obj).hasClass('hover')) {
            if (!$(obj).hasClass('active')) {
                if ($(obj).find('.mxd-sidebar-hover').length > 0) {
                    $(obj).find('.mxd-sidebar-hover').addClass('mxd-slideUp');
                    $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-head').removeClass('mxd-collapsed');
                    $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-body').show();
                } else {
                    let el = $(obj).find('.mxd-sidebar-rmenu')[0];
                    $(el).addClass('mxd-slideUp');
                    $(el).find('.mxd-sidebar-rmenu-head').removeClass('mxd-collapsed');
                    $(el).find('.mxd-sidebar-rmenu-body').show();
                }
            } else {

            }
        } else {
            $(obj).find('.mxd-sidebar-hover').removeClass('mxd-slideUp');
            $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-head').addClass('mxd-collapsed');
            $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-body').hide();
        }

    }

    initMenu() {
        let cm = window.sessionStorage.getItem("CollapseMenu");
        if (cm) {
            CollapseMenu = JSON.parse(cm);
        }
        let sidebar = this.state.sidebar;
        let activeFirstPage = this.activeFirstPage;
        let activeSecondPage = this.activeSecondPage;
        let noSecond = this.noSecond;
        let hasTitle = false;
        let foldMenu = this.foldMenu;
        let unFoldMeu = this.unFoldMeu;
        sidebar.map(function (side) {
            let first = side.first;
            if (side.second && side.second.menulist.length > 0) {
                let menulist = side.second.menulist;
                menulist.map(function (menu) {
                    foldMenu(menu.key, 0);
                    if (menu.submenu && menu.submenu.length > 0) {
                        menu.submenu.map(function (submenu) {
                            if (submenu.pathIn && submenu.pathIn()) {
                                activeFirstPage(first.key);
                                activeSecondPage(submenu.key, first.key)
                                hasTitle = true;
                                unFoldMeu(menu.key, 0)
                            }
                        });
                    } else {
                        if (menu.pathIn && menu.pathIn()) {
                            activeFirstPage(first.key);
                            activeSecondPage(menu.key, first.key)
                            unFoldMeu(menu.key, 0)
                        }
                    }
                })
            } else {
                if (first.pathIn && first.pathIn()) {
                    activeFirstPage(first.key);
                    noSecond();
                }
            }
        });
        if (!hasTitle) {
            sessionStorage.removeItem('navTitle')
        }
        // let foldMenu = this.foldMenu;
        // CollapseMenu.map(function (menuId) {
        //     foldMenu(menuId);
        // })
    }

    collapseMenu(e) {
        let p = $(e.currentTarget);
        let id = p.attr('id');


        if (p.hasClass('mxd-collapsed')) {
            $('.mxd-sidebar-rmenu').removeClass('mxd-slideUp');
            $('.mxd-sidebar-rmenu').find('.mxd-sidebar-rmenu-head').addClass('mxd-collapsed');
            $('.mxd-sidebar-rmenu').find('.mxd-sidebar-rmenu-body').slideUp();

            this.unFoldMeu(id);//展开
            let index = CollapseMenu.indexOf(id);
            CollapseMenu.splice(index, 1);
        } else {
            this.foldMenu(id);//收起
            CollapseMenu.push(id);
        }
        window.sessionStorage.setItem("CollapseMenu", JSON.stringify(CollapseMenu));
    }

    foldMenu(id, flag) {
        let target = $("#" + id);
        target.addClass('mxd-collapsed');
        if (flag == 0) {
            target.siblings('.mxd-sidebar-rmenu-body').hide();
        } else {
            target.siblings('.mxd-sidebar-rmenu-body').slideUp();
        }

        target.parent().removeClass('mxd-slideUp');
    }

    unFoldMeu(id, flag) {
        let target = $("#" + id);
        target.removeClass('mxd-collapsed');
        if (flag == 0) {
            target.siblings('.mxd-sidebar-rmenu-body').show();
        } else {
            target.siblings('.mxd-sidebar-rmenu-body').slideDown();
        }

        target.parent().addClass('mxd-slideUp');
    }

    toFirstPage(side) {
        let first = side.first.key;
        $(".mxd-sidebar-lmenu").each(function (data) {
            if ($(this).attr("id") == first) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
        if (side.first.path) {
            this.props.history.push(side.first.path);
        }
        if (side.second && side.second.menulist.length > 0) {
            this.hasSecond();
        } else {
            //没有第二级菜单
            this.noSecond();
        }
    }

    noSecond() {
        $("#mxdIndex").addClass("mxd-sidebar-nosecond");
        sessionStorage.removeItem('navTitle')
    }

    hasSecond() {
        $("#mxdIndex").removeClass("mxd-sidebar-nosecond");
    }

    toSecondPage(side, menu) {
        if (menu.path) {
            if (menu.key == 'operateDesc' || menu.target == '_blank') {
                window.open(menu.path, "_blank");
            } else {
                let first = side.first.key;
                this.activeFirstPage(first);
                this.activeSecondPage(menu.key);
                this.props.history.push(menu.path);
            }
        }
    }

    activeFirstPage(id) {
        $(".mxd-sidebar-lmenu").removeClass("active");
        $("#" + id).addClass("active");
    }

    activeSecondPage(id, key) {
        // $(".mxd-sidebar-rmenu .ant-menu-item-selected").removeClass("ant-menu-item-selected");
        // $("#" + id).addClass("ant-menu-item-selected");
        $('.mxd-sidebar-rmenu').removeClass('active');
        $(".mxd-sidebar-rmenu li").removeClass("active");
        $("#" + id).addClass("active");
        $('#' + id).parents('.mxd-sidebar-rmenu').addClass('active');

        if (key) {
            var obj = this.state.defaultOpenKeys;
            let arr = [];
            arr.push(id)
            obj[key].selectedKeys = arr;
            this.setState({
                defaultOpenKeys: obj
            })
            sessionStorage.setItem('navTitle', $("#" + id).text())
            // this.props.showNavTitle()
        }

        this.hasSecond();
    }

    render() {
        let collapseMenu = this.collapseMenu;
        let toFirstPage = this.toFirstPage;
        let toSecondPage = this.toSecondPage;

        return (
            <div className="mxd-sidebar theme1 theme4">
                <div className="mxd-sidebar-first">
                    <a href={URL_WEBSITE}>
                        <div className="mxd-sidebar-logo">
                            <span>

                            </span>
                        </div>
                    </a>
                    <ul>
                        {
                            this.state.sidebar.map((side) => {
                                return (
                                    <div id={side.first.key} key={side.first.key} className="mxd-sidebar-lmenu">
                                        <li onClick={toFirstPage.bind(this, side)}>
                                            <span>
                                                <i className={"sidebar-icon " + side.first.icon}></i>
                                                {side.first.title}
                                            </span>
                                        </li>
                                        {/*{*/}
                                        {/*    side.second&&side.second.menulist.length>0?*/}
                                        {/*        <div className=' mxd-sidebar-second'>*/}
                                        {/*            <div className="mxd-sidebar-second-title">{side.second.title}</div>*/}
                                        {/*            <div ref={side.first.key} className='scrollDiv'>*/}
                                        {/*                <Menu*/}
                                        {/*                    selectedKeys={this.state.defaultOpenKeys[side.first.key].selectedKeys}*/}
                                        {/*                    defaultOpenKeys={this.state.defaultOpenKeys[side.first.key].openKeys}*/}
                                        {/*                    // defaultOpenKeys={[]}*/}
                                        {/*                    // forceSubMenuRender={true}*/}
                                        {/*                    // inlineCollapsed={false}*/}
                                        {/*                    className={'text-menu'}*/}
                                        {/*                    mode="inline"*/}
                                        {/*                >*/}

                                        {/*                    {*/}

                                        {/*                        side.second.menulist.map((menu,i)=>{*/}

                                        {/*                            return(*/}
                                        {/*                                <SubMenu key={menu.key}*/}
                                        {/*                                         title={*/}
                                        {/*                                             <span onClick={menu.submenu && menu.submenu.length > 0 ?*/}
                                        {/*                                            collapseMenu :*/}
                                        {/*                                            toSecondPage.bind(this, side, menu)}>{menu.title}</span>*/}

                                        {/*                                         }*/}
                                        {/*                                         className='mxd-sidebar-rmenu'*/}
                                        {/*                                         id={menu.key}*/}
                                        {/*                                >*/}
                                        {/*                                    {*/}
                                        {/*                                        menu.submenu && menu.submenu.length > 0 ?*/}
                                        {/*                                            menu.submenu.map((submenu)=>{*/}
                                        {/*                                                return(*/}
                                        {/*                                                    <Menu.Item key={submenu.key}*/}
                                        {/*                                                               onClick={toSecondPage.bind(this, side, submenu)}*/}
                                        {/*                                                               className='submenuItem'*/}
                                        {/*                                                               id={submenu.key}*/}
                                        {/*                                                    >*/}
                                        {/*                                                        {submenu.title}*/}
                                        {/*                                                    </Menu.Item>*/}
                                        {/*                                                )*/}
                                        {/*                                            }):''*/}
                                        {/*                                    }*/}
                                        {/*                                </SubMenu>*/}
                                        {/*                            )*/}
                                        {/*                        })*/}

                                        {/*                    }*/}

                                        {/*                </Menu>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*        :''*/}

                                        {/*}*/}
                                        {
                                            side.second && side.second.menulist.length > 0 ?
                                                <ul className="mxd-sidebar-second">
                                                    <div className="mxd-sidebar-second-title">{side.second.title}</div>
                                                    <div ref={side.first.key} className='scrollDiv'>
                                                        <ul>
                                                            {
                                                                side.second.menulist.map(function (menu) {
                                                                    return (
                                                                        <li key={menu.key}
                                                                            className={`mxd-sidebar-rmenu ${menu.hoverOpen ? 'mxd-sidebar-hover' : ''}`}>
                                                                            <div id={menu.key}
                                                                                 className={"mxd-sidebar-rmenu-head" + (menu.submenu && menu.submenu.length > 0 ? "" : " nosubmenu")}
                                                                                 onClick={menu.submenu && menu.submenu.length > 0 ? collapseMenu : toSecondPage.bind(this, side, menu)}>
                                                                                {
                                                                                    menu.submenu && menu.submenu.length > 0 ?
                                                                                        <i className="mxd-dashboard-arrow-icon mxd-sidebar-arrow"></i> : ''
                                                                                }
                                                                                <i className={`menuIcon ${menu.icon ? menu.icon : ''}`}></i>
                                                                                <span>{menu.title}</span>
                                                                            </div>
                                                                            <ul className="mxd-sidebar-rmenu-body">
                                                                                {
                                                                                    menu.submenu && menu.submenu.length > 0 ? menu.submenu.map(function (submenu) {
                                                                                        return (
                                                                                            <li id={submenu.key}
                                                                                                key={submenu.key}
                                                                                                onClick={toSecondPage.bind(this, side, submenu)}>{submenu.title}</li>
                                                                                        )
                                                                                    }) : ''
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </ul> : ''
                                        }
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(SideBarNew)
import {URL_GET_AURH_URI,
    URL_LOGOUT,
    URL_PROMPTION_COUPONCODE,
    URL_SWITCH_SUBS,
    URL_USER_RESETPWD,
    URL_NOTICE_LIST,
    URL_NOTICE_READ
} from "../variables";
import React from "react";
import {FetchUtil} from "../fetch_util";
import styles from './index.module.css'
import UserUtil from '../user_util'
import './style/headset.less'
import Util from "../utils";
import {Path} from '../path'
import { Empty } from 'antd';

let userUtil = new UserUtil();

let fetchUtil = new FetchUtil();

class Notice extends React.Component {

    constructor(props) {
        super(props);
        this.getNoticeList=this.getNoticeList.bind(this);
        this.getNoticeRead=this.getNoticeRead.bind(this);
        this.state = {
            msgList:[]
        }
    }

    componentDidMount() {
        $("#mxdIndex").addClass("mxd-sidebar-nosecond");
        $('.mxd-sidebar-lmenu').removeClass('active');
        this.getNoticeList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(sessionStorage.getItem('sign')&&sessionStorage.getItem('sign')==1){
            this.getNoticeList();
            sessionStorage.setItem('sign','0')
        }
    }

    getNoticeList(){
        fetchUtil.init()
            .setUrl(URL_NOTICE_LIST+'?sign=0')
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.setState({
                        msgList:msg.respMsg.noticeList
                    })
                }
            })
            .catch(() => {

            });
    }

    getNoticeRead(nid,url,sign){
        let str='';
        if(nid){
            str='nid='+nid;
        }
        // if(sign){
        //     str='sign=1';
        // }

        fetchUtil.init()
            .setUrl(URL_NOTICE_READ+'?'+str)
            .setMethod('POST')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.getNoticeList();
                }
            })
            .catch(() => {

            });
    }

    render() {
        return (
            <div className='contentBody'>
                <div className="comBread">
                    <div className="ant-breadcrumb"><span><span className="ant-breadcrumb-link"><a
                        href={Path.getNoticeList()}>消息列表</a></span><span className="ant-breadcrumb-separator">/</span></span>
                    </div>
                </div>
                <div className={'mxd-panel minHeight'}>
                    <div className='mxd-form-head'>消息列表</div>
                    {
                        this.state.msgList&&this.state.msgList.length>0?
                            <ul>
                                {
                                    this.state.msgList.map((data,i)=>{
                                        return(
                                            <li onClick={data.status==0?this.getNoticeRead.bind(this,data.id,data.url):''}
                                                className={'noticeItem'}>
                                                <a href={data.url} target='_black'>
                                                    <div className='noticeTitle'>{data.title}{data.status==0?<span className='dot'></span>:''}
                                                        <span className='msgTime'>{Util.getDateLite(data.createDate)}</span>
                                                    </div>
                                                    <div className='msgCon'>{data.content}</div>
                                                    <div className={'clearfixb'}>
                                                        <span className={'seeDetail'}>查看详情</span>
                                                    </div>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>:
                            <div className='noticeEmpty'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                    }

                </div>
            </div>
        )
    }
}

export default Notice;
import {
    URL_LOGOUT,
    URL_NOTICE_LIST,
    URL_NOTICE_READ,
    URL_PROMPTION_COUPONCODE,
    URL_USER_RESETPWD
} from "../variables";
import {Path, toSignIn} from "../path";
import {Link, withRouter} from "react-router-dom";
import Store from "../store";
import React from "react";
import {FetchUtil} from "../fetch_util";
import styles from './index.module.css'
import {connect} from "react-redux";
import {MsgBox} from "../common/msg_box";
import ImgCheckMark from '../../images/audit_success_1@2x.png'
import Util, {checkPassword, hashPwd} from "../utils";
import './style/headset.less'
import {Dropdown, Input, Menu, Modal, Tooltip, message, Select} from 'antd';
import Empty from '../common/ant/Empty'
import Api from '../api'
import Message from "../common/ant/Message";

let fetchUtil = new FetchUtil();
const Option = Select.Option;

class HdSet extends React.Component {

    constructor(props) {
        super(props);
        this.exit = this.exit.bind(this);
        // this.toAuth = this.toAuth.bind(this);
        this.setUserInfo = this.setUserInfo.bind(this);
        this.getNoticeList = this.getNoticeList.bind(this);
        this.goOrderList = this.goOrderList.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.getUpgradeNewCount = this.getUpgradeNewCount.bind(this);
        this.viewClick = this.viewClick.bind(this);
        this.state = {
            userEmail: '',
            // subsList: [],
            hasNew: false,
            msgList: [],
            showSale: false,
            showModify: false,
            needReadNum: 0,
            spliceEmail: null,
            upgradeNewCount: 0
        }
    }

    componentDidMount() {
        this.getNoticeList();
        this.getUpgradeNewCount();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userInfo) {
            this.setUserInfo(nextProps.userInfo);
        }
    }

    getUpgradeNewCount() {
        Api.getUpgradeNewCount((data) => {
            this.setState({
                upgradeNewCount: data.newCount
            })
        })
    }

    getNoticeList() {
        fetchUtil.init()
            .setUrl(URL_NOTICE_LIST + '?sign=0')
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.setState({
                        msgList: msg.respMsg.noticeList
                    })
                    let list = msg.respMsg.noticeList;
                    let hasNew = false;
                    let num = 0;
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].status == 0) {
                            hasNew = true;
                            num++;
                        }
                    }
                    this.setState({
                        hasNew: hasNew,
                        needReadNum: num
                    })
                }
            })
            .catch(() => {

            });
    }

    getNoticeRead(nid, sign) {
        let str = '';
        if (nid) {
            str = 'nid=' + nid;
        }
        if (sign == 1) {
            str = 'sign=1';
        }
        fetchUtil.init()
            .setUrl(URL_NOTICE_READ + '?' + str)
            .setMethod('POST')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.getNoticeList();
                    sessionStorage.setItem('sign', '1')
                }
            })
            .catch(() => {

            });
    }

    setUserInfo(userInfo) {
        this.setState({
            userEmail: userInfo.email,
        });
        Store.setUserInfo(userInfo);
        this.spliceEmail(userInfo.email)
    }

    exit() {
        var pros = this.props;
        var pathToSignIn = toSignIn;
        fetchUtil.init()
            .setUrl(URL_LOGOUT)
            .setMethod('POST')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0 || msg.respCode == 1050) {
                    //注销成功
                    pathToSignIn(pros);
                }
            })
            .catch(() => {

            });
    }

    // //公众号授权
    // toAuth() {
    //     fetchUtil.init()
    //         .setUrl(URL_GET_AURH_URI)
    //         .setMethod('GET')
    //         .setCookieCors()
    //         .setOvertime(2000)
    //         .setReturnType('text')
    //         .dofetch()
    //         .then((data) => {
    //             if ('error' != data) {
    //                 window.location.href = data
    //             }
    //         })
    //         .catch(() => {
    //
    //         });
    // }

    // /**
    //  * 切换公众号
    //  */
    // switchSubs(appId) {
    //     fetchUtil.init()
    //         .setUrl(URL_SWITCH_SUBS)
    //         .setMethod('POST')
    //         .setHeader({
    //             'Content-Type': 'application/json'
    //         })
    //         .setCookieCors()
    //         .setBodyType('json')
    //         .setBody({
    //             appId: appId
    //         })
    //         .dofetch()
    //         .then((msg) => {
    //             var respCode = msg.respCode;
    //             if (respCode == 0) {
    //                 window.location.href = Path.getExtPath();
    //             }
    //         })
    // }

    showCoupon() {
        this.setState({
            showSale: true
        })
        this.handleVisibleChange(false);
    }

    changeSale() {
        this.setState({
            showSale: false
        })
    }

    showModifyPwd() {
        this.setState({
            showModify: true
        })
        this.handleVisibleChange(false);
    }

    changeModify() {
        this.setState({
            showModify: false
        })
    }

    spliceEmail(email) {
        let str = '';
        let index = email.indexOf('@');
        if (email.length > 16) {
            if (index != -1) {
                let num = email.length - index;
                str = email.substr(0, 12 - num) + '****' + email.substr(index, num);
            } else {
                str = email.substr(0, 6) + '****' + email.substr(email.length - 6, 6);
            }

        } else {
            str = email;
        }
        this.setState({
            spliceEmail: str
        });
    }

    goOrderList() {
        this.handleVisibleChange(false);
    }

    handleVisibleChange(visible) {
        this.setState({
            dropVisible: visible
        });
    }

    MessageTip(flag) {
        if (flag) {
            Message.warning("请先保存当前快捷菜单")
            this.setState({
                dropVisible: !flag
            });
            return
        }
    }

    viewClick() {
        Api.upgradeViewClick((data) => {
            let url = 'https://www.yuque.com/docs/share/932d3db5-091d-44fc-b87b-3e35bb61c86b?#';
            window.open(url);
            this.setState({
                upgradeNewCount: 0
            })
        })
    }

    render() {
        let toAuth = this.toAuth;
        let state = this.state;
        let switchSubs = this.switchSubs;

        const menu = (
            <Menu className='msgInfo'>
                <div className='msgTop'>
                    消息
                    {
                        this.state.hasNew ?
                            <span
                                onClick={this.getNoticeRead.bind(this, null, 1)}>全部标记为已读</span> : ''
                    }
                </div>
                <div>
                    {
                        this.state.msgList && this.state.msgList.length > 0 ?
                            <ul>
                                {
                                    this.state.msgList.map((data, i) => {
                                        return (
                                            i < 3 ?
                                                <li onClick={data.status == 0 ? this.getNoticeRead.bind(this, data.id) : ''}>
                                                    <a href={data.url} target='_blank'>
                                                        <div
                                                            className='msgTitle'>{data.title}{data.status == 0 ?
                                                            <span
                                                                className='dot'></span> : ''}</div>
                                                        <div className='msgCon'>{data.content}</div>
                                                        <span
                                                            className='msgTime'>{Util.getDateLite(data.createDate)}</span>
                                                    </a>
                                                </li> : ''
                                        )
                                    })
                                }
                            </ul> :
                            <div className='noticeEmpty'><Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
                    }

                </div>
                {
                    this.state.msgList && this.state.msgList.length > 0 ?
                        <Link to={Path.getNoticeList()}>
                            <a href='javascript:' className='seeMore'>查看全部</a>
                        </Link> : ''
                }


            </Menu>
        );

        const selfInfo = (
            <Menu className='selfInfo'>
                <ul>
                    {
                        this.state.spliceEmail ?
                            <li className={'userInfoEmail'}>
                                <div>{this.state.spliceEmail}</div>
                            </li> : ''
                    }

                    <li onClick={this.showCoupon.bind(this)}>
                        <div><span className='iconSale'></span>我要优惠</div>
                    </li>
                    <li>
                        <div onClick={this.goOrderList.bind(this)}><Link className='text-inherit'
                                                                         to={Path.getOrderList()}><span
                            className='iconRecord'></span>消费记录</Link>
                        </div>
                    </li>
                    <li onClick={this.showModifyPwd.bind(this)}>
                        <div><span className='iconPwd'></span>修改密码</div>
                    </li>
                    <li onClick={this.exit} className='exitLi'>
                        <div><span className='iconExit'></span>退出登录</div>
                    </li>
                </ul>
            </Menu>
        );
        var domain = window.location.href.split('/');
        let href = domain[0] + "//" + domain[1] + domain[2] + "/help"
        return (
            <div className={styles.hdSet} id='headSet'>
                <ul>
                    <li className={'headSet ' + styles.hdSetItem}>
                        {
                            this.props.visible ?
                                <a href="javascript:" onClick={() => {
                                    Message.warning("请先保存当前快捷菜单")
                                }}>
                                    <i className='icon-vip'></i><span className='headShop'>购买产品</span>
                                </a> :
                                <a href="javascript:">
                                    <Link to={Path.getOrderAdd()}>
                                        <i className='icon-vip'></i><span className='headShop'>购买产品</span>
                                    </Link>
                                </a>
                        }
                        {/*<Tooltip title='帮助'>*/}
                        {/*<a  href="https://shimo.im/docs/I8Gmwhe7TtkMuow7/" target="_blank">*/}
                        {/*<span className='headHelp'></span>*/}
                        {/*</a>*/}
                        {/*</Tooltip>*/}

                        <a href="https://www.yuque.com/books/share/b1324abe-fc39-489b-8243-488e4259373b?#"
                           target="_blank">
                            <span className='headHelp'>帮助</span>
                        </a>
                        <a className='upgradeWraper' onClick={this.viewClick.bind(this)}>
                            <span>更新日志</span>
                            {
                                this.state.upgradeNewCount ?
                                    <span
                                        className='newCount'>{this.state.upgradeNewCount > 99 ? 99 : this.state.upgradeNewCount}</span> : ''
                            }
                        </a>
                        <Tooltip title='意见反馈'>
                            <a href="http://meixiangdao94.mikecrm.com/0w86Lc6"
                               target="_blank">
                                <span className='headFault'></span>
                            </a>
                        </Tooltip>

                        {/*<Tooltip title='消息通知'>*/}
                        {/*<Dropdown overlay={menu} trigger="click"*/}
                        {/*getPopupContainer={() => document.getElementById('headSet')}*/}
                        {/*>*/}
                        {/*<a href="#"*/}
                        {/*className={`ant-dropdown-link ${this.state.hasNew ? 'msgDot' : ''}`}>*/}
                        {/*{*/}
                        {/*this.state.needReadNum > 0 ?*/}
                        {/*<span*/}
                        {/*className='needReadNum'>{this.state.needReadNum >= 10 ?*/}
                        {/*<span>...</span> : this.state.needReadNum}</span> : ''*/}
                        {/*}*/}
                        {/*<span className='headInfo'></span>*/}
                        {/*</a>*/}
                        {/*</Dropdown>*/}
                        {/*</Tooltip>*/}


                    </li>
                    <li className={"dropdown " + styles.hdSetItem}
                        onClick={this.MessageTip.bind(this, this.props.visible)}>

                        <Dropdown overlay={selfInfo} trigger="click"
                                  getPopupContainer={() => document.getElementById('headSet')}
                                  onVisibleChange={this.handleVisibleChange.bind(this)}
                                  visible={this.state.dropVisible}
                        >
                            <a className={`ant-dropdown-link`}>
                                <span className='avatarSpan'></span>
                                <span className={!this.state.dropVisible ? "caretNew" : "caretNewDown"}></span>
                            </a>
                        </Dropdown>

                    </li>
                </ul>
                <ModifyPwd email={this.state.userEmail} showModify={this.state.showModify}
                           changeModify={this.changeModify.bind(this)}/>
                <Coupon visible={this.state.showSale} refreshUserInfo={this.props.refreshUserInfo}
                        changeSale={this.changeSale.bind(this)}/>
            </div>
        )
    }
}

class ModifyPwd extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.resetSuccess = this.resetSuccess.bind(this);
        this.init = this.init.bind(this);
        this.state = {
            resetSuccess: false,
            email: this.props.email,
            modifyVisible: props.showModify
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            email: nextProps.email
        })
        if (this.state.modifyVisible != nextProps.showModify) {
            this.setState({
                modifyVisible: nextProps.showModify
            })
        }
    }

    resetSuccess() {
        this.setState({
            resetSuccess: true
        })
    }

    init() {
        $("#hidePwd").val('');
        this.setState({
            resetSuccess: false
        });
        // Modal.hide("#modifyPwdModal");
        // this.setState({
        //     modifyVisible:false
        // })
        this.props.changeModify(false)
    }

    submit() {
        if (this.state.resetSuccess) {
            this.init();
        } else {
            var pwd = $("#hidePwd").val();
            if (pwd.length == 0) {
                MsgBox.showWarningMsg_2s("请输入旧密码完成校验");
                $("#hidePwd").focus();
                return;
            }
            if (!checkPassword(pwd)) {
                MsgBox.showWarningMsg_2s("旧密码不正确");
                return;
            }
            Util.disabledBtn("#modifyPwdBtn", true);
            fetchUtil.init()
                .setUrl(URL_USER_RESETPWD)
                .setMethod('POST')
                .setCookieCors()
                .setHeader({
                    'Content-Type': 'application/json'
                })
                .setBody({
                    pwd: hashPwd(pwd)
                })
                .setBodyType('json')
                .dofetch()
                .then((msg) => {
                    if (msg.respCode == 0) {
                        //密码设置成功
                        this.resetSuccess();
                    } else if (msg.respCode == 1031) {
                        //密码错误
                        MsgBox.showWarningMsg_2s("旧密码不正确");
                    }
                    Util.disabledBtn("#modifyPwdBtn", false);
                })
                .catch(() => {
                    Util.disabledBtn("#modifyPwdBtn", false);
                });
        }
    }

    render() {
        return (
            <Modal id="modifyPwdModal"
                   title='重置密码'
                   visible={this.state.modifyVisible}
                   onOk={this.submit}
                   onCancel={this.init}
                   className={'headSetModal'}
            >
                <div className="mxd-modal-body">
                    {
                        this.state.resetSuccess ? <div className="text-center">
                            <p>已发送 重置密码链接 到绑定的邮箱</p>

                            <p>{Util.coverEmail(this.state.email)}</p>

                            <p>请登录邮箱完成密码修改</p>
                        </div> : <div style={{'margin-top': '20px'}}>
                            <Input id="hidePwd" type="text" placeholder="请输入旧密码完成校验"
                                   autoComplete="off"/>
                        </div>
                    }
                </div>
                <div className="mxd-modal-footer-space"></div>
                {/*<div className="mxd-modal-footer">*/}
                {/*    <Button id="modifyPwdBtn" type="button" type='primary' onClick={this.submit}>确定*/}
                {/*    </Button>*/}
                {/*    <Button type="button" onClick={this.init}>取消*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Modal>
        )
    }
}

class Coupon extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.setSuccess = this.setSuccess.bind(this);
        this.init = this.init.bind(this);
        this.initCancel = this.initCancel.bind(this);
        this.state = {
            success: false,
            visible: props.visible,
            subsList: null,
            couponCode: null,
            showSubs: false
        }
    }

    componentDidMount() {
        Api.getSubsList((msg) => {
            this.setState({
                subsList: msg.subsList,
                currentSubs: 0
            })
        }, () => {
            // message.warning('网络连接超时，请重试')
        })
    }

    changeSubs(index) {
        this.setState({
            currentSubs: index
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.visible != nextProps.visible) {
            this.setState({
                visible: nextProps.visible,
                showSubs: false
            })
        }
    }

    init() {
        $("#couponCode").val('');
        // this.setSuccess(false);
        // this.setState({
        //     visible:false
        // })
        // this.props.changeSale();
        this.setState({
            couponCode: null
        })
    }

    initCancel() {
        $("#couponCode").val('');
        this.setSuccess(false);
        this.props.changeSale();
        this.setState({
            couponCode: null
        })
    }


    setSuccess(flag) {
        this.setState({
            success: flag
        })
    }

    changeCode(e) {
        this.setState({
            couponCode: e.target.value
        })
    }

    submit() {
        if (this.state.success) {
            this.initCancel();
        } else {
            var couponCode = $("#couponCode").val();
            if (couponCode.length == 0) {
                MsgBox.showWarningMsg_2s("请输入优惠码");
                $("#couponCode").focus();
                return;
            }
            Util.disabledBtn('#couponBtn', true);

            let params = {};
            params.code = couponCode;
            if (this.state.showSubs) {
                params.appId = this.state.subsList[this.state.currentSubs].appId
            }
            fetchUtil.init()
                .setUrl(URL_PROMPTION_COUPONCODE)
                .setMethod('POST')
                .setCookieCors()
                .setBody(params)
                .dofetch()
                .then((msg) => {
                    if (msg.respCode == 0) {
                        this.setSuccess(true);
                        this.props.refreshUserInfo();
                    } else {
                        var errcode = msg.respMsg.errcode;
                        if (errcode == 1) {
                            MsgBox.showWarningMsg_4s('你已经享有该优惠了，请不要重复兑换');
                        } else if (errcode == 2) {
                            MsgBox.showWarningMsg_4s('优惠码不存在');
                        } else if (errcode == 3) {
                            MsgBox.showWarningMsg_4s('优惠码已被使用');
                        } else if (errcode == 4) {
                            MsgBox.showWarningMsg_4s('优惠码已过期');
                        } else if (errcode == 5) {
                            MsgBox.showWarningMsg_4s('该优惠已经被抢完，期待下次优惠吧');
                        } else if (errcode == 6) {
                            MsgBox.showWarningMsg_4s('你已经兑换过7天邀请有礼福利了，想要获取无限时长，赶紧去分享吧');
                        } else if (errcode == 7) {
                            MsgBox.showWarningMsg_4s('只有新授权的公众号才能使用该优惠');
                        } else if (errcode == 8) {
                            MsgBox.showWarningMsg_4s('没有认证的公众号无法兑换时长');
                        } else if (errcode == 3059) {
                            this.setState({
                                showSubs: true
                            });
                            MsgBox.showWarningMsg_4s('该优惠码需要公众号，请选择');
                        } else if (errcode == 9) {
                            MsgBox.showWarningMsg_4s(msg.respMsg.errmsg);
                        }
                    }
                    Util.disabledBtn('#couponBtn', false);
                    if (errcode != 3059) {
                        this.init();
                    }

                })
                .catch(() => {
                    Util.disabledBtn('#couponBtn', false);
                })
        }
    }

    render() {
        return (
            <Modal id="coupon"
                   title='我要优惠'
                   visible={this.state.visible}
                   onOk={this.submit}
                   onCancel={this.initCancel}
                   className={'headSetModal'}
            >
                <div>
                    {
                        this.state.success ? <div className="text-center">
                                <img src={ImgCheckMark} width="60px" height="60px"/>
                                <p>&nbsp;</p>
                                <p><p>兑换成功，请到「涨粉-公众号-账号授权」查看</p></p>
                            </div> :
                            <div style={{'margin-top': '20px'}}>
                                <div className='headSetItem'>
                                    <label>优惠码:</label>
                                    <div>
                                        <Input id="couponCode" type="text"
                                               placeholder="请输入优惠码"
                                               autoComplete="off"
                                               value={this.state.couponCode}
                                               onChange={this.changeCode.bind(this)}
                                        />
                                    </div>
                                </div>

                                {
                                    this.state.showSubs ?
                                        <div className='headSetItem'>
                                            <label>公众号:</label>
                                            <div>
                                                <Select defaultValue={0}
                                                        value={this.state.currentSubs}
                                                        onChange={this.changeSubs.bind(this)}
                                                >
                                                    {
                                                        this.state.subsList ?
                                                            this.state.subsList.map((data, i) => {
                                                                return (
                                                                    <Option
                                                                        value={i}>{data.nickName}</Option>
                                                                )
                                                            }) : ''
                                                    }
                                                </Select>
                                            </div>
                                        </div> : ''
                                }


                            </div>
                    }
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo
    }
}

export default connect(mapStateToProps)(withRouter(HdSet))
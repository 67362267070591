import React from 'react'
import {Link} from "react-router-dom";
import styles from './vip.module.css'
import CustomQRCode from '../../images/vip_custom_qrcode.jpg'
import {Path} from "../path";
import {URL_GET_USER_BASEINFO} from "../variables";
import {FetchUtil} from "../fetch_util";
import Util from "../utils";

let fetchUtil = new FetchUtil();

class VipPurchase extends React.Component {

    constructor(props) {
        super(props);
        this.pareseUserInfo = this.pareseUserInfo.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.setPrice = this.setPrice.bind(this);
        // var data = this.props.location.state;
        var type = 2, price = 2999;
        // if (data) {
        //     type = data.type;
        //     if (type == 1) {
        //         price = 999;
        //     } else if (type == 2) {
        //         price = 2599
        //     } else if (type == 3) {
        //         price = 99
        //     }
        // }
        this.state = {
            email: '',
            subsName: '',
            price: price,
            type: type
        };
        this.product = {
            type: type, //1：消息通 2：任务宝
            duration: 1 // 1：12个月 2：6个月 3：1个月
        }
    }

    componentDidMount() {
        fetchUtil.init()
            .setUrl(URL_GET_USER_BASEINFO)
            .setMethod('GET')
            .setCookieCors()
            .setOvertime(2000)
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    this.pareseUserInfo(data.respMsg);
                }
            })
            .catch(() => {

            });
        window.contentScroll = Util.initScroll(this.refs.contentScroll);
    }

    pareseUserInfo(userInfo) {
        if (userInfo) {
            this.setState({
                email: userInfo.email,
                subsName: userInfo.subsInfo.nickname
            })
        }
    }

    selectChange(type, value, e) {
        if (type == 'type' && this.state.type != value) {
            this.setState({
                type: value
            })
        }
        if (type == 'type' && (value == 1)) {
            this.product[type] = value;
            this.product['duration'] = 1;
            $("#duration1").siblings('.' + styles.selectbtn).removeClass(styles.selectbtnActive);
            if (!$("#duration1").hasClass(styles.selectbtnActive)) {
                $("#duration1").addClass(styles.selectbtnActive);
            }
        } else {
            this.product[type] = value;
        }
        var target = $(e.target);
        target.siblings('.' + styles.selectbtn).removeClass(styles.selectbtnActive);
        if (!target.hasClass(styles.selectbtnActive)) {
            target.addClass(styles.selectbtnActive);
        }
        if (this.product.type == 2) {
            //任务宝
            if (this.product.duration == 1) {
                //12个月
                this.setPrice(2999);
            }
            if (this.product.duration == 2) {
                //6个月
                this.setPrice(1999);
            }
            if (this.product.duration == 3) {
                //1个月
                this.setPrice(999);
            }
        } else if (this.product.type == 1) {
            if (this.product.duration == 1) {
                //12个月
                this.setPrice(1499);
            }
            if (this.product.duration == 2) {
                //6个月
                this.setPrice(999);
            }
        } else if (this.product.type == 3) {
            //分享宝
            if (this.product.duration == 1) {
                //12个月
                this.setPrice(1999);
            }
            if (this.product.duration == 2) {
                //6个月
                this.setPrice(1499);
            }
            if (this.product.duration == 3) {
                //1个月
                this.setPrice(499);
            }
        } else if (this.product.type == 4) {
            //渠道码
            if (this.product.duration == 1) {
                //12个月
                this.setPrice(999);
            }
            if (this.product.duration == 2) {
                //6个月
                this.setPrice(799);
            }
            if (this.product.duration == 3) {
                //1个月
                this.setPrice(299);
            }
        }
    }

    setPrice(price) {
        $("." + styles.price).html('¥' + price);
    }

    render() {
        return (
            <div className={styles.contentScroll} ref="contentScroll">
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Link to={Path.getExtPath()}>
                            <div className={styles.logo}></div>
                        </Link>
                        <div className={styles.right}>
                            <Link to={Path.getExtPath()}>
                                <button className="mxd-btn mxd-btn-primary">
                                    返回系统
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.vippur}>
                        <div className={styles.viptitle}>
                            会员升级
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                当前账号
                            </div>
                            <div className={styles.right}>
                                <span>{this.state.email}</span>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                公众号
                            </div>
                            <div className={styles.right}>
                                <span>{this.state.subsName}</span>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                产品类型
                            </div>
                            <div className={styles.right}>
                                <div
                                    className={styles.selectbtn + (this.state.type == 2 ? (' ' + styles.selectbtnActive) : '')}
                                    onClick={this.selectChange.bind(this, 'type', 2)}>
                                    任务宝
                                </div>
                                <div
                                    className={styles.selectbtn + (this.state.type == 1 ? (' ' + styles.selectbtnActive) : '')}
                                    onClick={this.selectChange.bind(this, 'type', 1)}>
                                    消息宝
                                </div>
                                <div
                                    className={styles.selectbtn + (this.state.type == 3 ? (' ' + styles.selectbtnActive) : '')}
                                    onClick={this.selectChange.bind(this, 'type', 3)}>
                                    分享宝
                                </div>
                                <div
                                    className={styles.selectbtn + (this.state.type == 4 ? (' ' + styles.selectbtnActive) : '')}
                                    onClick={this.selectChange.bind(this, 'type', 4)}>
                                    渠道码
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                开通时长
                            </div>
                            <div className={styles.right}>
                                <div id="duration1" className={styles.selectbtn + ' ' + styles.selectbtnActive}
                                     onClick={this.selectChange.bind(this, 'duration', 1)}>
                                    一年
                                </div>
                                <div className={styles.selectbtn}
                                     onClick={this.selectChange.bind(this, 'duration', 2)}>
                                    6个月
                                </div>
                                {
                                    this.state.type == 1 ? '' : <div
                                        className={styles.selectbtn}
                                        onClick={this.selectChange.bind(this, 'duration', 3)}>1个月</div>
                                }
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                支付金额
                            </div>
                            <div className={styles.right}>
                                <span className={styles.price}>¥2999</span>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={styles.puritem}>
                            <div className={styles.left}>
                                联系客服
                            </div>
                            <div className={styles.right}>
                                <div className={styles.custom}>
                                    <img src={CustomQRCode}/>
                                    <div className={styles.customTip}>扫码申请客服办理</div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VipPurchase
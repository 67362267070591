import {Path, PATH, PATH_NAME} from "../cmpts/path";

const SidebarConf = [
    {
        first: {
            title: '首页',
            key: 'home',
            path: PATH.home,
            pathIn: Path.isHome,
            icon: 'sidebar-icon-home'
        }
    },
    {
        first: {
            title: '涨粉',
            key: 'subsFission',
            path: PATH.subsAuth,
            pathIn: Path.isExt,
            icon: 'sidebar-icon-subsFission'
        },
        second: {
            title: '公众号涨粉',
            menulist: [
                {
                    title: '公众号',
                    key: 'subsManage',
                    icon: 'icon-nav-public',
                    submenu: [
                        {
                            title: '账号授权',
                            key: 'subsAuth',
                            path: PATH.subsAuth,
                            pathIn: Path.isSubsAuth
                        },
                        {
                            title: '粉丝管理',
                            key: 'fansManage',
                            path: PATH.fansManage,
                            pathIn: Path.isFansManage
                        }
                    ]
                },
                {
                    title: '任务宝',
                    key: 'taskposter',
                    icon: 'icon-nav-task',
                    // hoverOpen: true,
                    submenu: [
                        {
                            title: '活动管理',
                            key: 'tpManage',
                            path: PATH.tpManage,
                            pathIn: Path.isTpManage
                        },
                        // {
                        //     title: '活动标签',
                        //     key: 'tpTags',
                        //     path: Path.getTpTags(),
                        //     pathIn: Path.isTpTags
                        // },
                        // {
                        //     title: 'SOP',
                        //     key: 'tpSop',
                        //     path: Path.getTpSop(),
                        //     pathIn: Path.isTpSop
                        // },
                        {
                            title: '数据统计',
                            key: 'tpStas',
                            path: PATH.tpStas,
                            pathIn: Path.isTpStas
                        },
                        {
                            title: '推广码',
                            key: 'tpChannel',
                            path: PATH.tpChannel,
                            pathIn: Path.isTpChannel
                        },
                        {
                            title: '黑名单',
                            key: 'tpBlacklist',
                            path: PATH.tpBlacklist,
                            pathIn: Path.isTpBlacklist
                        },
                        {
                            title: '表单管理',
                            key: 'formManage',
                            path: PATH.formManage,
                            pathIn: Path.isFormManage
                        },
                        {
                            title: '兑换码',
                            key: 'redeemCodeManage',
                            path: PATH.redeemCodeManage,
                            pathIn: Path.isRedeemcodeManage
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/55728bc8-aa90-4322-9a34-5df5da13482a?#'
                        },

                        // {
                        //     title: '新建活动',
                        //     key: 'tpCreate',
                        //     path: PATH.tpCreate,
                        //     pathIn: Path.isTpCreate
                        // },
                    ]
                },
                {
                    title: '消息宝',
                    key: 'message',
                    icon: 'icon-nav-msg',
                    submenu: [
                        {
                            title: '推送管理',
                            key: 'tmRecord',
                            path: PATH.tmRecord,
                            pathIn: Path.isTMPushRecord
                        },
                        {
                            title: 'SOP计划',
                            key: 'pushSOP',
                            path: Path.getPushList(),
                            pathIn: Path.isPush
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/a7b6fb22-6c57-4143-afd8-ff768cf9fa0e?#'
                        },
                    ]
                },
                {
                    title: '渠道码',
                    key: 'channel',
                    icon: 'icon-nav-ma',
                    submenu: [
                        {
                            title: '码管理',
                            key: 'channelQRList',
                            path: PATH.channelQRList,
                            pathIn: Path.isCodeManage
                        },
                        // {
                        //     title: '新建码',
                        //     key: 'channelQRCreate',
                        //     path: PATH.channelQRCreate,
                        //     pathIn:Path.isCodeCreate
                        // },
                        {
                            title: '数据统计',
                            key: 'channelQRStats',
                            path: PATH.channelQRStats,
                            pathIn: Path.isCodeDetail
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/619268f2-75cd-40d3-852f-e03a2484e406?# '
                        },


                    ]
                },
                {
                    title: '分享宝',
                    key: 'share',
                    icon: 'icon-nav-share',
                    submenu: [
                        {
                            title: '分享管理',
                            key: 'stManage',
                            path: PATH.stManage,
                            pathIn: Path.isStManage
                        },
                        // {
                        //     title: '新建分享',
                        //     key: 'stCreate',
                        //     path: PATH.stCreate,
                        //     pathIn:Path.isStCreate
                        // },
                        {
                            title: '数据统计',
                            key: 'stDetail',
                            path: PATH.stDetail,
                            pathIn: Path.isStDetail
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/a7481aa5-0974-4592-bfa2-a997199241ec?#'
                        },

                    ]
                },
                {
                    title: '菜单管理',
                    key: 'wxmenu',
                    icon: 'icon-nav-menu',
                    submenu: [
                        {
                            title: '自定义菜单',
                            key: 'wxMenuCreate',
                            path: Path.getWXCreate(),
                            pathIn: Path.isWxCreate
                        },
                        {
                            title: '个性化菜单',
                            key: 'wxMenuIndividuation',
                            path: Path.getWXIndividuationList(),
                            pathIn: Path.isWXIndCreate
                        },
                        {
                            title: '自动回复',
                            key: 'autoReply',
                            path: Path.getWXAutoReplay(),
                            pathIn: Path.isWXAutoReplay
                        }
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '商品',
            key: 'goods',
            path: PATH.myShop,
            pathIn: Path.isMyShop,
            icon: 'sidebar-icon-goods'
        },
        second: {
            title: '商品管理',
            menulist: [
                {
                    title: '商品管理',
                    key: 'goodsManage',
                    icon: 'icon-nav-goods',
                    submenu: [
                        {
                            title: '商品管理',
                            key: 'goodsList',
                            path: PATH.goodsList,
                            pathIn: Path.isGoodsList
                        },
                        {
                            title: '订单管理',
                            key: 'orderManage',
                            path: PATH.goodsOrderList,
                            pathIn: Path.isGoodsOrderList
                        }
                    ]
                }
            ]
        }
    },
    // {
    //     first: {
    //         title: '建群',
    //         key: 'qunFission',
    //         path: PATH.qunFission,
    //         icon: 'sidebar-icon-qunFission'
    //     },
    //     second: {
    //         title: '微信群管理',
    //         menulist: [
    //             {
    //                 title: '进群宝',
    //                 key: 'groupFission',
    //                 submenu: [
    //                     {
    //                         title: '群任务',
    //                         key: 'groupTaskLIst',
    //                         path: Path.getGroupTaskList(),
    //                         pathIn: Path.isGroupTaskList
    //                     },
    //                     {
    //                         title: '群购买',
    //                         key: 'groupPurchase',
    //                         path: Path.getGroupPurchaseAccount(),
    //                         pathIn: Path.isGroupPurchase
    //                     },
    //                     {
    //                         title: '黑名单',
    //                         key: 'groupBlackList',
    //                         path: Path.getGroupBlackList(),
    //                         pathIn: Path.isGroupTaskBlackList
    //                     },
    //                     {
    //                         title: '数据统计',
    //                         key: 'groupDataCount',
    //                         path: Path.getGroupDataCount(),
    //                         pathIn: Path.isGroupDataCount
    //                     },
    //                     {
    //                         title: '群机器人管理',
    //                         key: 'groupRobotList',
    //                         path: Path.getGroupRobotList(),
    //                         pathIn: Path.isGroupRobotList
    //                     },
    //                     {
    //                         title: '群发消息',
    //                         key: 'groupSendList',
    //                         path: Path.getGroupSendList(),
    //                         pathIn: Path.isGroupSendList
    //                     },
    //
    //                 ]
    //             }
    //         ]
    //     }
    // },
    {
        first: {
            title: '分销',
            key: 'distribution',
            path: Path.getDis(),
            pathIn: Path.isDis,
            icon: 'sidebar-icon-distri'
        },
        second: {
            title: '分销管理',
            menulist: [
                {
                    title: '分销管理',
                    key: 'retailManage',
                    icon: 'icon-nav-retail',
                    submenu: [
                        {
                            title: '分销管理',
                            key: 'retailList',
                            path: Path.getDisRetailList(),
                            pathIn: Path.isDisRetail
                        },
                        {
                            title: '渠道管理',
                            key: 'retailChannelData',
                            path: Path.getDisChannelList(),
                            pathIn: Path.isDisChannel
                        },
                        {
                            title: '收益报表',
                            key: 'retailReport',
                            path: Path.getDisRetailReport() + '/0',
                            pathIn: Path.isDisRetailReport
                        },
                        {
                            title: '分销提现',
                            key: 'retailCashOut',
                            path: Path.getDisCashList(),
                            pathIn: Path.isDisCash
                        },
                        {
                            title: '消息模板',
                            key: 'retailMsg',
                            path: Path.getDisMsg(),
                            pathIn: Path.isDisMsg
                        },
                        // {
                        //     title: '产品购买',
                        //     key: 'retailPurchase',
                        //     path: Path.getDisPushase(),
                        //     pathIn: Path.isDisPurchase
                        // },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/1944b3a4-83a2-4896-b023-5ce749c77439?#'
                        },
                    ]
                },
                {
                    title: '核销管理',
                    key: 'writeOffManage',
                    icon: 'icon-nav-writeoff',
                    submenu: [
                        {
                            title: '核销员管理',
                            key: 'retailWriteoff',
                            path: Path.getWriteOffList(),
                            pathIn: Path.isWriteOff
                        },
                        // {
                        //     title: '核销员记录',
                        //     key: 'retailWriteoffData',
                        //     path: Path.getWriteOffData(),
                        //     pathIn: Path.isWriteOffData
                        // }
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '拼团',
            key: 'groupBuy',
            path: Path.getGroupBuy(),
            pathIn: Path.isGroupBuy,
            icon: 'sidebar-icon-groupbuy'
        },
        second: {
            title: '拼团管理',
            menulist: [
                {
                    title: '拼团管理',
                    key: 'groupBuyManage',
                    icon: 'icon-nav-groupbuy',
                    submenu: [
                        {
                            title: '拼团管理',
                            key: 'groupBuyList',
                            path: Path.getGroupBuyList(),
                            pathIn: Path.isGroupBuyList
                        },
                        {
                            title: '订单列表',
                            key: 'groupBuyOrder',
                            path: Path.getGroupBuyOrder(),
                            pathIn: Path.isGroupBuyOrder
                        },
                        {
                            title: '拼团提现',
                            key: 'groupBuyCash',
                            path: Path.getGroupBuyCash(),
                            pathIn: Path.isGroupBuyCash
                        },
                        {
                            title: '消息模板',
                            key: 'groupBuyMsg',
                            path: Path.getGroupBuyMsg(),
                            pathIn: Path.isGroupBuyMsg
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/1b13109d-c94e-4a0a-acde-7b172c61fc0e?# '
                        },
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '活码',
            key: 'liveCode',
            path: Path.getLiveCodeGroupList(),
            pathIn: Path.isLiveCodeGroup,
            icon: 'sidebar-icon-livecode'
        },
        second: {
            title: '活码管理',
            menulist: [
                {
                    title: '活码管理',
                    key: 'livecodeManage',
                    icon: 'icon-nav-livecode',
                    submenu: [
                        {
                            title: '群活码',
                            key: 'livecodeGroupList',
                            path: Path.getLiveCodeGroupList(),
                            pathIn: Path.isLiveCodeGroup
                        },
                        {
                            title: '客服活码',
                            key: 'livecodeCustomerList',
                            path: Path.getLiveCodeCustomerList(),
                            pathIn: Path.isLiveCodeCustomer
                        },
                        {
                            title: '数据统计',
                            key: 'livecodeDataStat',
                            path: Path.getLiveCustomCodeDataStat(),
                            pathIn: Path.isLiveCodeStat
                        },
                        // {
                        //     title: '产品购买',
                        //     key: 'livecodePurchase',
                        //     path: Path.getLiveCodePurchase(),
                        //     pathIn: Path.isLiveCodePurchase
                        // },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/aee3059d-a1e8-4fbe-9f5e-0d371954a8ce?#'
                        },
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '购买',
            key: 'mine',
            path: Path.getMinePurchase(),
            pathIn: Path.isMinePurchase,
            icon: 'sidebar-icon-mine'
        },
        second: {
            title: '个人中心',
            menulist: [
                {
                    title: '消费管理',
                    key: 'mineOrderManage',
                    icon: 'icon-nav-manage',
                    submenu: [
                        // {
                        //     title: '消费记录',
                        //     key: 'orderList',
                        //     path: PATH.orderList,
                        //     pathIn: Path.isOrderList
                        // },
                        // {
                        //     title: '购买产品',
                        //     key: 'orderAdd',
                        //     path: PATH.orderAdd,
                        //     pathIn: Path.isOrderAdd
                        // },
                        {
                            title: '消费记录',
                            key: 'orderList',
                            path: Path.getMineOrderList(),
                            pathIn: Path.isOrderList
                        },
                        {
                            title: '购买产品',
                            key: 'orderAdd',
                            path: Path.getMinePurchase(),
                            pathIn: Path.isMinePurchase
                        }
                    ]
                }
            ]
        }
    }
]

export default SidebarConf
import React from 'react';
import {Redirect, Route} from "react-router-dom";
import loadable from "../../loadable";

const GoodsList = loadable(() => import('./index.bundle'));
/**
 * 店铺
 */
class Myshop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={()=>(
                    <Redirect to={this.props.match.url+"/con"}/>
                )}/>
                <Route path={this.props.match.url + "/con"} component={GoodsList}/>

            </div>
        );
    }
}

export default Myshop
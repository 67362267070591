import React, {createRef, useEffect} from 'react'

const ref = createRef();
class CropMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        WWOpenData.bind(ref.current)
    }

    componentDidUpdate() {
        WWOpenData.bind(ref.current)
    }

    render() {
        let type = this.props.type;
        let openid = this.props.openid;
        return (
            <ww-open-data ref={ref} type={type} openid={openid}/>
        )
    }

}

export default CropMember;

import React from 'react';
import {Redirect, Route} from "react-router-dom";

import loadable from "../../../../loadable";

const PushList = loadable(() => import('./push/push_list.bundle'));
const PushAdd = loadable(() => import('./push/push_add.bundle'));

/**
 * 店铺
 */
class Push extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/list"}/>
                )}/>
                <Route path={this.props.match.url + "/list"} component={PushList}/>
                <Route path={this.props.match.url + "/create"} render={(props) => {
                    props.type = 'create';
                    return <PushAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <PushAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <PushAdd {...props}/>
                }}/>
            </div>
        );
    }
}

export default Push
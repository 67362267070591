import {message} from 'antd';

const defaultDuration = 3;

message.config({
    duration: 2,
    maxCount: 1
});

class Message {

    static success(content, duration, onClose, err) {
        message.success(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

    static error(content, duration, onClose, err) {
        message.error(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

    static info(content, duration, onClose, err) {
        message.info(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

    static warning(content, duration, onClose, err) {
        message.warning(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

    static warn(content, duration, onClose, err) {
        message.warn(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

    static loading(content, duration, onClose, err) {
        message.loading(content, duration ? duration : defaultDuration, onClose);
        if (err) {
            throw Error(content);
        }
    }

}

export default Message
import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import SiteNav from './sitenav';
import {connect} from 'react-redux'
import {URL_GET_USER_BASEINFO, URL_LOGIN_STATUS} from "../variables";
import {FetchUtil} from "../fetch_util";
import ProbationWarn from "../warn/probation_warn";
import SideBar from "./sidebar";
import ProductExpired from "../warn/product_expired";
import UserUtil from "../user_util";
import Ext from '../ext/ext'
import Mine from '../mine/mine'
import MyShop from '../myshop/myshop'
import Util from "../utils";
import Welcome from "./welcome";
import Customer from "../common/customer ";
import Notice from './notice_list'
import Purchase from '../common/purchase/Purchase'
import Group from '../group/group'
import Distribution from '../distribution/distribution'
import Groupbuy from '../groupbuy/groupbuy'
import LiveCode from '../livecode/livecode'
import Dnd from '../dnd/dnd'
import SideBarNew from './sidebar/index'
import Corp from '../corp/corp'
import Logistics from '../logistics/index'

let userUtil = new UserUtil();
let fetchUtil = new FetchUtil();

class Index extends React.Component {

    constructor(props) {
        super(props);
        // this.setSubsInfo = this.setSubsInfo.bind(this);
        this.checkLoginStatus = this.checkLoginStatus.bind(this);
        this.toIndex = this.toIndex.bind(this);
        this.state = {
            sideBar: {},
            theme: 0
        }
    }

    componentDidMount() {
        fetchUtil.init()
            .setUrl(URL_GET_USER_BASEINFO)
            .setMethod('GET')
            .setCookieCors()
            .setOvertime(2000)
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    this.props.setUserInfo(data.respMsg);
                }
            })
            .catch(() => {

            });
        window.contentScroll = Util.initScroll(this.refs.contentWrapper);
    }

    toIndex() {
        this.props.history.push("/index");
    }

    toHome() {
        window.location.replace('home.html');
    }

    checkLoginStatus() {
        var pros = this.props;
        var pathToIndex = this.toIndex;
        var pathToHome = this.toHome;
        let fetchUtil = new FetchUtil();
        fetchUtil.init()
            .setUrl(URL_LOGIN_STATUS)
            .setMethod('GET')
            .setOvertime(5000)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                var respMsg = msg.respMsg;
                if (msg.respCode == 0 && respMsg.loginStatus == 'ok') {
                    //登陆成功
                    pathToIndex(pros);
                } else {
                    // pathToSignIn(pros);
                    pathToHome();
                }
            })
            .catch(() => {
                // pathToSignIn(pros);
                pathToHome();
            });
    }

    getNavTheme(flag) {
        this.setState({
            theme: flag
        })
    }

    render() {
        return (
            <div id="mxdIndex">

                <SideBarNew theme={this.state.theme} sideBar={this.state.sideBar}/>
                <div className="contentWrapper" ref="contentWrapper">
                    <div>
                        <SiteNav
                            getTheme={this.getNavTheme.bind(this)}
                            refreshUserInfo={this.props.getUserInfo}
                            props={this.props}
                        />
                        <Switch>
                            <Route exact path={this.props.match.url + "/dashboard"} component={Welcome}/>
                            <Route path={this.props.match.url + "/ext"} component={Ext}/>
                            <Route path={this.props.match.url + "/mine"} component={Mine}/>
                            <Route path={this.props.match.url + "/myshop"} component={MyShop}/>
                            <Route path={this.props.match.url + "/notice"} component={Notice}/>
                            <Route path={this.props.match.url + "/purchase"} component={Purchase}/>
                            <Route path={this.props.match.url + "/group"} component={Group}/>
                            <Route path={this.props.match.url + "/distribution"} component={Distribution}/>
                            <Route path={this.props.match.url + "/groupbuy"} component={Groupbuy}/>
                            <Route path={this.props.match.url + "/livecode"} component={LiveCode}/>
                            <Route path={this.props.match.url + "/dnd"} component={Dnd}/>
                            <Route path={this.props.match.url + "/corp"} component={Corp}/>
                            <Route path={this.props.match.url + "/logistics"} component={Logistics}/>
                            <Route exact path={this.props.match.url}
                                   render={() => (<Redirect to={this.props.match.url + "/dashboard"}/>)}></Route>
                        </Switch>
                    </div>
                </div>
                <div>
                    {/*<ProductExpired/>*/}
                    <Customer/>
                    {/*<ProbationWarn probation={this.state.probation}/>*/}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state
}

function mapDispatchToProps(dispatch) {
    return {
        getUserInfo() {
            fetchUtil.init()
                .setUrl(URL_GET_USER_BASEINFO)
                .setMethod('GET')
                .setCookieCors()
                .setOvertime(2000)
                .dofetch()
                .then((data) => {
                    if (data.respCode == 0) {
                        dispatch({
                            type: 'SET_USER_INFO',
                            userInfo: data.respMsg
                        })
                    }
                })
                .catch(() => {

                });
        },
        setUserInfo(userInfo) {
            dispatch({
                type: 'SET_USER_INFO',
                userInfo: userInfo
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
import React from 'react';
import {Redirect, Route} from "react-router-dom";

import LivePurchase from './purchase/purchase'
import loadable from "../../loadable";

const CustomerCodeList = loadable(() => import('./customer_code/code_list.bundle'));
const CustomerCodeAdd = loadable(() => import('./customer_code/code_add.bundle'));
const CustomerCodeManage = loadable(() => import('./customer_code/qrcode_manage.bundle'));
const GroupCodeList = loadable(() => import('./group_code/code_list.bundle'));
const GroupCodeAdd = loadable(() => import('./group_code/code_add.bundle'));
const GroupCodeManage = loadable(() => import('./group_code/qrcode_manage.bundle'));
const DataStatus = loadable(() => import('./dataStats/data_status.bundle'));

/**
 * 店铺
 */
class Myshop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/customer/list"}/>
                )}/>
                <Route path={this.props.match.url + "/customer/list"} component={CustomerCodeList}/>
                <Route path={this.props.match.url + "/customer/create"} render={(props) => {
                    props.type = 'create';
                    return <CustomerCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/customer/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <CustomerCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/customer/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <CustomerCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/customer/qrcode/:id"} render={(props) => {
                    props.type = 'copy';
                    return <CustomerCodeManage {...props}/>
                }}/>

                <Route path={this.props.match.url + "/group/list"} component={GroupCodeList}/>
                <Route path={this.props.match.url + "/group/create"} render={(props) => {
                    props.type = 'create';
                    return <GroupCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/group/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <GroupCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/group/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <GroupCodeAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/group/qrcode/:id"} render={(props) => {
                    props.type = 'copy';
                    return <GroupCodeManage {...props}/>
                }}/>

                <Route path={this.props.match.url + "/customer/stat"} render={(props) => {
                    props.codeType = '1';
                    return <DataStatus {...props}/>
                }}/>

                <Route path={this.props.match.url + "/group/stat"} render={(props) => {
                    props.codeType = '2';
                    return <DataStatus {...props}/>
                }}/>
                <Route path={this.props.match.url + "/purchase"} component={LivePurchase}/>
            </div>
        );
    }
}

export default Myshop
import React from "react";
import HdSet from './head_set'
import SiteNavLeft from './siteNavLeft'
import {Button} from "antd";
// import Breadcrumb from '../common/Breadcrumb/breadCrumb'
const ButtonGroup = Button.Group;

class SiteNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navTitle: null,
            theme: 0,
            visible:false
        }
    }

    componentWillMount() {
        this.setState({
            navTitle: sessionStorage.getItem('navTitle') ? sessionStorage.getItem('navTitle') : ''
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (sessionStorage.getItem('navTitle')) {
            let navTitle = sessionStorage.getItem('navTitle');
            if (navTitle != this.state.navTitle) {
                this.setState({
                    navTitle: navTitle
                })
            }
        } else {
            this.setState({
                navTitle: null
            })
        }
    }

    changeTheme(flag) {
        this.setState({
            theme: flag
        })
        this.props.getTheme(flag)
        // sessionStorage.setItem('theme',flag)
    }

    visibleFn(flag){
        this.setState({
            visible:flag
        })
    }

    render() {
        let styles = {
            'line-height': '60px',
            'padding-left': '15px',
            'padding-right': '15px'
        }
        return (
            <header>
                <div className="sitenav clearfix">
                    <SiteNavLeft props={this.props.props}
                                 visibleFn={this.visibleFn.bind(this)}
                    ></SiteNavLeft>
                    {/*<span className='hdSetTitle' style={styles}>{this.state.navTitle}</span>*/}
                    {/*<Breadcrumb></Breadcrumb>*/}
                    {/*<ButtonGroup>*/}
                    {/*    <Button className={this.state.theme==0?'btnPrimaryActive':''} onClick={this.changeTheme.bind(this, 0)}>theme1</Button>*/}
                    {/*    <Button className={this.state.theme==1?'btnPrimaryActive':''} onClick={this.changeTheme.bind(this, 1)}>theme2</Button>*/}
                    {/*</ButtonGroup>*/}
                    <HdSet
                        visible={this.state.visible}
                        refreshUserInfo={this.props.refreshUserInfo}/>
                </div>
            </header>
        );
    }
}

export default SiteNav
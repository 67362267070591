import React from "react";
import SignUpForm from './signup_form'
import Footer from './footer'
import styles from './signflow.module.css'
import Store from "../store";


class SignUp extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Store.setAlertData(1);
        var _hmt = _hmt || [];
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?d9840c819949bbbb7adf5af9fd69a182";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    }

    render() {
        return (
            <div className={styles.signBg}>
                <div className={styles.upperpart}></div>
                <SignUpForm/>
                <Footer/>
            </div>
        );
    }
}


export default SignUp


import React from "react";
import {Link} from 'react-router-dom'
import HdSet from './head_set'
import {Button, Menu, Popover, Modal, Checkbox} from "antd";
import Util from "../utils";
import siteNavData from "../../config/SidebarConfNew.js";
import {
    URL_NAV_CONFIG
} from "../variables";
import {FetchUtil} from "../fetch_util";
import Message from "../common/ant/Message";
import SideBarLib from '../common/sidebar/index'
import SidebarLogo from "./commen/logo";
import {PATH, Path} from "../path";

let fetchUtil = new FetchUtil();
// import Breadcrumb from '../common/Breadcrumb/breadCrumb'
const ButtonGroup = Button.Group;

const {SubMenu} = Menu;

class SiteNavLeft extends React.Component {

    constructor(props) {
        super(props);
        this.getSiteNavData = this.getSiteNavData.bind(this)
        this.getSelectData = this.getSelectData.bind(this)
        this.state = {
            arrowFlag: true,//true为收起，false为放出
            siteNavData: [],
            selectSiteData: [],
            selectSiteDataOld: [],
            visible: false,
            selectSiteNo: "",
            siteNavSelectData: [],
            selectedKeys: ""
        }
    }

    componentWillMount() {
        this.getSiteNavData()

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.props.location.pathname != this.props.props.location.pathname) {
            this.getSiteNavData()
        }
    }

    getSiteNavData() {
        let sidebar = siteNavData, secondArr = [], selectedKeys = []
        let href = window.location.href

        sidebar.map(function (side) {
            let first = side.first;
            if (side.second && side.second.menulist.length > 0) {
                let menulist = side.second.menulist;
                menulist.map(function (menu) {
                    if (menu.submenu && menu.submenu.length > 0) {
                        menu.submenu.map(function (submenu) {
                            if (submenu.submenu && submenu.submenu.length > 0) {
                                submenu.submenu.map(function (thirdMenu) {
                                    if (thirdMenu.pathIn && thirdMenu.pathIn()) {
                                        selectedKeys.push(menu.type)
                                    }
                                })
                            } else {
                                if (submenu.pathIn && submenu.pathIn()) {
                                    selectedKeys.push(menu.type)
                                }
                            }

                        });
                    } else {
                        if (menu.pathIn && menu.pathIn()) {
                            selectedKeys.push(menu.type)
                        }
                    }
                    secondArr.push(menu)
                })
            }
        });

        this.setState({
            siteNavData: secondArr,
            selectedKeys: JSON.stringify(selectedKeys)
        })
        this.getSelectData(secondArr)
    }

    getSelectData(secondArr) {
        fetchUtil.init()
            .setUrl(URL_NAV_CONFIG)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    let list = msg.respMsg.navConfig
                    this.setState({
                        selectSiteData: JSON.parse(list)
                    })
                    this.getSelectValue(JSON.parse(list), secondArr)
                }
            })
            .catch(() => {

            });
    }

    manageSiteNav(selectSite) {
        if (this.state.visible == true) {
            return
        }
        let arr = []
        selectSite.map((item) => {
            arr.push(item)
        })
        this.setState({
            selectSiteDataOld: arr,
            visible: true

        })
        this.props.visibleFn(true)
    }

    getSelectValue(select, list) {
        let arr = []
        if (select.length > 0) {
            select.map((item) => {
                list.map((data) => {
                    if (item == data.type) {
                        arr.push(data)
                    }
                })
            })
        }

        this.setState({
            siteNavSelectData: arr
        })
    }

    selectSiteNav(type, selectSite, e) {
        e.stopPropagation();
        if (selectSite.length >= 10 && e.target.checked) {
            this.setState({
                selectSiteNo: type
            })
            return
        } else {
            this.setState({
                selectSiteNo: ""
            })
        }
        if (e.target.checked) {
            selectSite.push(type)
        } else {
            let index = selectSite.indexOf(type)
            selectSite.splice(index, 1)
        }

        this.setState({
            selectSite: selectSite
        })

        this.getSelectValue(selectSite, this.state.siteNavData)
    }

    hideModal(key, selectName, old) {
        let arr = []
        old.map((item) => {
            arr.push(item)
        })
        this.setState({
            [selectName]: arr,
            [key]: false,
        })
        this.props.visibleFn(false)
        this.getSelectValue(arr, this.state.siteNavData)
    }

    submitData(key, selectArr) {

        fetchUtil.init()
            .setUrl(URL_NAV_CONFIG)
            .setMethod('POST')
            .setBodyType('json')
            .setBody({
                navConfig: JSON.stringify(selectArr)
            })
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.setState({
                        [key]: false
                    })
                    this.props.visibleFn(false)
                }
            })
            .catch(() => {

            });

    }

    gotoPath(data) {
        if (this.state.visible) {
            Message.warning("请先保存当前快捷菜单")
            return
        }
        let arr = []
        arr.push(data.type)
        this.setState({
            selectedKeys: JSON.stringify(arr)
        })
        this.props.props.history.push(data.path)
    }

    onVisibleChange(visible) {
        if (visible) {
            this.setState({
                arrowFlag: false
            })
        } else {
            this.setState({
                arrowFlag: true
            })
        }
    }

    goToHomepage(flag) {
        if (flag) {
            Message.warning("请先保存当前快捷菜单")
            return
        }
        this.setState({
            selectedKeys: ""
        })
        this.props.props.history.push(Path.getHomePath())
    }

    render() {
        let siteNavDataNew = this.state.siteNavData,
            selectSite = this.state.selectSiteData,
            siteNavDataOld = this.state.selectSiteDataOld,
            siteNavSelectData = this.state.siteNavSelectData,
            href = window.location.href


        return (
            <div className={'siteNavLeft'}>
                <SidebarLogo visible={this.state.visible}></SidebarLogo>
                <div className={'siteNavCon'}>
                    <div className={'siteConLeft'}>
                        <div
                            className={'cursor-pointer siteNavLib ' + (Path.isHome() == true ? 'homePageActive' : '')}>
                            <span onClick={this.goToHomepage.bind(this, this.state.visible)}>首页</span>
                        </div>
                        <div>
                            <SideBarLib visible={this.state.visible}></SideBarLib>
                        </div>
                    </div>
                    <div className={'siteLine'}></div>
                    <ul className={'siteConRight'}>
                        {
                            siteNavSelectData.length > 0 ?
                                <Menu mode="horizontal"
                                      selectedKeys={this.state.selectedKeys}
                                >
                                    {
                                        siteNavSelectData.map((data, index) => {
                                            return (
                                                <Menu.Item key={data.type}
                                                           onClick={this.gotoPath.bind(this, data)}
                                                           getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                    {data.title}
                                                </Menu.Item>
                                            )
                                        })
                                    }

                                </Menu> : ""
                        }
                        {
                            selectSite.length <= 0 || !this.state.visible ?
                                <Popover
                                    title="管理快捷菜单"
                                    content="管理快捷菜单"
                                    className={'addTooltip'}
                                    icon={null}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                >
                                    <i className={'site-add-icon'}
                                       onClick={this.manageSiteNav.bind(this, selectSite)}></i>
                                </Popover> : ""
                        }

                    </ul>
                </div>
                <Modal
                    title=""
                    visible={this.state.visible}
                    footer={null}
                    wrapClassName={'siteModal'}
                    zIndex='-1'
                    width={'100vw'}
                >
                    <div className={'siteModalCon'}>
                        <p className={'siteModalTitle'}>{'固定至导航(' + (selectSite.length) + '/10)'}</p>
                        <div className={'sideBarLib'}>
                            {
                                siteNavData.map((side) => {
                                    if (!side.hide) {
                                        return (
                                            <div className={'item'}>
                                                <p className={'sName'}>
                                                    <i className={"img " + side.first.icon}></i>
                                                    {side.first.title}
                                                </p>
                                                {
                                                    side.second && side.second.menulist.length > 0 ?
                                                        <ul>
                                                            {
                                                                side.second.menulist.map((menu) => (
                                                                    <li>
                                                                        <Checkbox
                                                                            checked={selectSite.indexOf(menu.type) >= 0}
                                                                            onChange={this.selectSiteNav.bind(this, menu.type, selectSite)}
                                                                        >
                                                                            {menu.title}
                                                                        </Checkbox>
                                                                        <Popover content={'最多选择10个产品'}
                                                                                 title=""
                                                                                 trigger="click"
                                                                                 visible={this.state.selectSiteNo == menu.type}
                                                                                 placement={'left'}
                                                                                 getPopupContainer={triggerNode => triggerNode.parentNode}
                                                                        >
                                                                        </Popover>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul> : ''
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                        <div className={'siteBtn'}>
                            <Button
                                onClick={this.hideModal.bind(this, "visible", 'selectSiteData', siteNavDataOld)}>取消</Button>
                            <Button type={'primary'}
                                    onClick={this.submitData.bind(this, 'visible', selectSite)}>保存</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SiteNavLeft
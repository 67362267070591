import React from 'react';
import {Redirect, Route} from "react-router-dom";

import loadable from "../../../loadable";

const Recharge = loadable(() => import('./pay_process/recharge.bundle'));
const PayConfirm = loadable(() => import('./pay_process/pay_confirm.bundle'));
const Pay = loadable(() => import('./pay_process/pay.bundle'));
const PayRresult = loadable(() => import('./pay_process/pay_result.bundle'));
const PurchaseRecordList = loadable(() => import('./purchase_record/purchase_record_list.bundle'));
const PurchaseRecordDetail = loadable(() => import('./purchase_record/purchase_record_detail.bundle'));
const PurchaseStreamList = loadable(() => import('./purchase_record/stream.bundle'));

/**
 * 店铺
 */
class Group extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/record"}/>
                )}/>
                <Route path={this.props.match.url + "/recharge"} component={Recharge}/>
                <Route path={this.props.match.url + "/pay"} component={Pay}/>
                <Route path={this.props.match.url + "/confirm"} component={PayConfirm}/>
                <Route path={this.props.match.url + "/result"} component={PayRresult}/>
                <Route path={this.props.match.url + "/record"} component={PurchaseRecordList}/>
                <Route path={this.props.match.url + "/detail"} component={PurchaseRecordDetail}/>
                <Route path={this.props.match.url + "/stream"} component={PurchaseStreamList}/>
            </div>
        );
    }
}

export default Group
import React from 'react'
import styles from './find_pwd.module.css'
import signflowStyles from './signflow.module.css'
import Footer from './footer'
import {FetchUtil} from "../fetch_util";
import {InputErrMask, InputNullMask} from './inputmask'
import {checkPassword, getUrlArg, hashPwd} from '../utils'
import ImgErr from '../../images/audit_fail_1@2x.png'
import ImgSuccess from '../../images/auth_success@2x.png'
import {Path} from "../path";
import {URL_FP_CHECK_VC, URL_FP_SETPWD} from "../variables";

let fetchUtil = new FetchUtil();

class ResetPwd extends React.Component {

    constructor(props) {
        super(props);
        this.setVerifyCodeErr = this.setVerifyCodeErr.bind(this);
        this.resetPwdSuccess = this.resetPwdSuccess.bind(this);
        this.state = {
            verifyCodeErr: "None",
            resetPwdSuccess: false
        }
    }

    setVerifyCodeErr(flag) {
        this.setState({
            verifyCodeErr: flag
        })
    }

    resetPwdSuccess() {
        this.setState({
            resetPwdSuccess: true
        })
    }

    componentDidMount() {
        var vc = getUrlArg('vc');
        if (vc == null) {
            this.setVerifyCodeErr(true);
        }
        fetchUtil.init()
            .setUrl(URL_FP_CHECK_VC)
            .setMethod('POST')
            .setCookieCors()
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setBody({
                vc: vc
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == -1) {
                    this.setVerifyCodeErr(true);
                } else {
                    this.setVerifyCodeErr(false);
                }
            })
            .catch((error) => {
                this.setVerifyCodeErr(true);
            });
    }

    render() {
        return (
            <div>
                <div>
                    {
                        this.state.verifyCodeErr == 'None' ? '' : this.state.verifyCodeErr == true ?
                            <VerifyCodeErr/> : this.state.resetPwdSuccess ? <ResetPwdSuccess/> :
                                <ResetPwdForm resetPwdSuccess={this.resetPwdSuccess}/>
                    }
                    <Footer/>
                </div>
            </div>
        )
    }
}

class ResetPwdSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.countdown = this.countdown.bind(this);
    }

    componentDidMount() {
        $("#countdown").html("4");
        this.countdown();
    }


    countdown() {
        var ses = Number($("#countdown").html());
        if (ses <= 1) {
            window.location.href = Path.getSigninHttpPath();
        } else {
            $("#countdown").html(ses - 1);
            setTimeout(this.countdown, 1000);
        }
    }

    render() {
        return (
            <div className={signflowStyles.container + ' ' + styles.fpFormContainer}>
                <div className={signflowStyles.logoWhite}>
                </div>
                <div className={signflowStyles.signContainer}>
                    <div className={signflowStyles.formTitle}>重置密码</div>
                    <div className="text-center">
                        <div className={styles.resultImg}>
                            <img src={ImgSuccess}/>
                        </div>
                        <p className="text-gray">重置密码成功,&nbsp;<span id="countdown"
                                                                    className="text-red text-bold">3</span>&nbsp;秒后跳转到<a
                            className="text-basecolor" href={Path.getSigninHttpPath()}>登录</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

class VerifyCodeErr extends React.Component {

    constructor(props) {
        super(props);
        this.countdown = this.countdown.bind(this);
    }

    componentDidMount() {
        $("#countdown").html("4");
        this.countdown();
    }


    countdown() {
        var ses = Number($("#countdown").html());
        if (ses <= 1) {
            window.location.href = Path.getHomeHttpPath();
        } else {
            $("#countdown").html(ses - 1);
            setTimeout(this.countdown, 1000);
        }
    }

    render() {
        return (
            <div className={signflowStyles.container + ' ' + styles.fpFormContainer}>
                <div className={signflowStyles.logoWhite}>
                </div>

                <div className={signflowStyles.signContainer}>
                    <div className={signflowStyles.formTitle}>重置密码</div>
                    <div className="text-center">
                        <div className={styles.resultImg}>
                            <img src={ImgErr}/>
                        </div>
                        <p className="text-gray">链接失效,&nbsp;<span id="countdown"
                                                                  className="text-red text-bold">3</span>&nbsp;秒后跳转到<a
                            className="text-basecolor" href={Path.getHomeHttpPath()}>首页</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

class ResetPwdForm extends React.Component {

    constructor(props) {
        super(props);
        this.showInputPwd = this.showInputPwd.bind(this);
        this.hidePwdMask = this.hidePwdMask.bind(this);
        this.showInputConfirmPwd = this.showInputConfirmPwd.bind(this);
        this.hideConfirmPwdMask = this.hideConfirmPwdMask.bind(this);
        this.submit = this.submit.bind(this);
        this.pwd = this.pwd.bind(this);
        this.confirmPwd = this.confirmPwd.bind(this);
        this.showInputPwdErr = this.showInputPwdErr.bind(this);
        this.showInputConfirmPwdErr = this.showInputConfirmPwdErr.bind(this);
        this.setIsResetIng = this.setIsResetIng.bind(this);
        this.state = {
            pwd: '',
            confirmPwd: '',
            inputPwdMsg: '请输入新密码',
            inputPwdErrMsg: '',
            inputPwd: false,
            inputPwdErr: false,
            inputConfirmPwd: false,
            inputConfirmPwdErr: false,
            inputConfirmPwdMsg: '请输入确认密码',
            inputConfirmPwdErrMsg: '新密码与确认密码不一致',
            isReseting: false
        }
    }

    showInputPwd() {
        if (this.state.pwd.length == 0) {
            this.setState({
                inputPwd: true
            });
        }
    }

    setIsResetIng(isReseting) {
        this.setState({
            isReseting: isReseting
        })
    }


    showInputPwdErr(errMsg) {
        this.setState({
            inputPwdErr: true,
            inputPwdErrMsg: errMsg
        });
    }

    hidePwdMask() {
        this.setState({
            inputPwd: false,
            inputPwdErr: false
        });
        this.refs.pwd.focus();
    }

    showInputConfirmPwd() {
        if (this.state.confirmPwd.length == 0) {
            this.setState({
                inputConfirmPwd: true
            });
        }
    }

    showInputConfirmPwdErr() {
        this.setState({
            inputConfirmPwdErr: true
        });
    }

    hideConfirmPwdMask() {
        this.setState({
            inputConfirmPwd: false,
            inputConfirmPwdErr: false
        });
        this.refs.confirmPwd.focus();
    }

    pwd(e) {
        let value = e.target.value;
        this.setState({
            pwd: value
        });
    }

    confirmPwd(e) {
        let value = e.target.value;
        this.setState({
            confirmPwd: value
        });
    }

    submit(event) {
        event.preventDefault();
        var pwd = this.state.pwd;
        var confirmPwd = this.state.confirmPwd;
        if (pwd.length == 0 || confirmPwd.length == 0) {
            this.showInputPwd();
            this.showInputConfirmPwd();
            return;
        }
        if (!checkPassword(pwd)) {
            this.showInputPwdErr("密码格式不正确");
            return;
        }
        if (pwd != confirmPwd) {
            this.showInputConfirmPwdErr();
            return;
        }
        this.setIsResetIng(true);
        fetchUtil.init()
            .setUrl(URL_FP_SETPWD)
            .setMethod('POST')
            .setCookieCors()
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setBody({
                password: hashPwd(pwd),
                vc: getUrlArg("vc")
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    //密码设置成功
                    this.props.resetPwdSuccess();
                }
                this.setIsResetIng(false);
            })
            .catch((error) => {
                this.setIsResetIng(false)
            });
    }

    render() {
        var inputPwdNullMask = this.state.inputPwd ? <InputNullMask errMsg={this.state.inputPwdMsg}/> : '';
        var inputPwdErrMask = this.state.inputPwdErr ? <InputErrMask errMsg={this.state.inputPwdErrMsg}/> : '';
        var inputConfirmPwdNullMask = this.state.inputConfirmPwd ?
            <InputNullMask errMsg={this.state.inputConfirmPwdMsg}/> : '';
        var inputConfirmPwdErrMask = this.state.inputConfirmPwdErr ?
            <InputErrMask errMsg={this.state.inputConfirmPwdErrMsg}/> : '';
        return (
            <div className={signflowStyles.container + ' ' + styles.fpFormContainer}>
                <div className={signflowStyles.logoWhite}>
                </div>
                <div className={signflowStyles.signContainer}>
                    <div className={signflowStyles.formTitle}>重置密码</div>
                    <div>
                        <label>包含字母和数字两种且长度不小于8</label>
                        <div className={signflowStyles.inputWraper} onClick={this.hidePwdMask}>
                            <input ref="pwd" type="password" placeholder="新密码" className={signflowStyles.signInput}
                                   onBlur={this.showInputPwd} onChange={this.pwd}/>
                            {inputPwdNullMask}
                            {inputPwdErrMask}
                        </div>
                        <div className={signflowStyles.inputWraper} onClick={this.hideConfirmPwdMask}>
                            <input ref="confirmPwd" type="password" placeholder="确认密码"
                                   className={signflowStyles.signInput}
                                   onBlur={this.showInputConfirmPwd} onChange={this.confirmPwd}/>
                            {inputConfirmPwdNullMask}
                            {inputConfirmPwdErrMask}
                        </div>
                        <div>
                            <button
                                className={"mxd-btn mxd-btn-primary " + signflowStyles.signBtn} onClick={this.submit}
                                disabled={this.state.isReseting}>{this.state.isReseting ? '提交中...' : '提交'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPwd
let KEY_TASKPOSTER_DATA = 'taskposter_data';
let KEY_FESTIVALSIGN_DATA = 'festivalsign_data';
let KEY_GROUP_TASK_DATA = 'group_task_data';
let KEY_RETAIL_DATA = 'retail_data';
let KEY_GROUPBUY_DATA = 'groupbuy_data';
let KEY_LIVECODE_DATA = 'livecode_data';
let KEY_LIVECODE_KF_DATA = 'livecode_kf_data';
let KEY_FORM_DATA = 'form_data';
let KEY_USERINFO = 'user';
let KEY_ALERT = 'alert';
let KEY_MSG_PUSH_APPID = 'msgpushappid';
let KEY_TASKPOSTER_ACTIVE_PAGENUM = 'tpactive_pnum';
let KEY_TASKPOSTER_BLACKACTIVE_PAGENUM = 'tpblackactive_pnum';

class Store {

    static setMsgPushAppId(appId) {
        if (appId) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_MSG_PUSH_APPID + user.id, appId);
            }
        }
    }

    static getMsgPushAppId() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let appId = window.sessionStorage.getItem(KEY_MSG_PUSH_APPID + user.id);
            if (appId) {
                return appId
            } else {
                return '';
            }
        }
    }

    static setTaskposterData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO)
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_TASKPOSTER_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getTaskposterData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO)
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_TASKPOSTER_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteTaskposterData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO)
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_TASKPOSTER_DATA + user.id);
        }
    }

    static setFestivalSignData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO)
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_FESTIVALSIGN_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getFestivalSignData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO)
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_FESTIVALSIGN_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteFestivalSignData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO)
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_FESTIVALSIGN_DATA + user.id);
        }
    }

    static setUserInfo(info) {
        if (info) {
            window.sessionStorage.setItem(KEY_USERINFO, JSON.stringify(info));
        }
    }

    static getUserInfo() {
        var userInfo = window.sessionStorage.getItem(KEY_USERINFO);
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            return userInfo;
        }
    }

    static setTagList(tagList) {
        var list = [];
        for (var i = 0; i < tagList.length; i++) {
            list.push({
                id: tagList[i].id,
                wId: tagList[i].wId,
                name: tagList[i].name,
            })
        }
        this.tagList = list;
    }

    static getTagList() {
        return this.tagList;
    }

    static setGroupTaskData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_GROUP_TASK_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getGroupTaskData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_GROUP_TASK_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteGroupTaskData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_GROUP_TASK_DATA + user.id);
        }
    }


    static setRetailData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_RETAIL_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getRetailData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_RETAIL_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteRetailData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_RETAIL_DATA + user.id);
        }
    }

    static setGroupBuyData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_GROUPBUY_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getGroupBuyData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_GROUPBUY_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteGroupBuyData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_GROUPBUY_DATA + user.id);
        }
    }


    // 活码
    static setLiveCodeData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_LIVECODE_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getLiveCodeData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_LIVECODE_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteLiveCodeData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_LIVECODE_DATA + user.id);
        }
    }

    // 客服活码
    static setLiveCodeKfData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_LIVECODE_KF_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getLiveCodeKfData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_LIVECODE_KF_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteLiveCodeKfData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_LIVECODE_KF_DATA + user.id);
        }
    }

    // 海报弹窗
    static setAlertData(data) {
        if (data) {
            let user = '_modal';
            if (user) {
                window.localStorage.setItem(KEY_ALERT + user, data);
            }
        }
    }

    static getAlertData() {
        let user = '_modal';
        if (user) {
            let data = window.localStorage.getItem(KEY_ALERT + user);
            if (data) {
                return data;
            }
        }
    }

    static deleteAlertData() {
        let user = '_modal';
        if (user) {
            window.localStorage.removeItem(KEY_ALERT + user);
        }
    }

    // 表单
    static setFormData(data) {
        if (data) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_FORM_DATA + user.id, JSON.stringify(data));
            }
        }
    }

    static getFormData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_FORM_DATA + user.id);
            if (data) {
                return JSON.parse(data);
            }
        }
    }

    static deleteFormData() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            window.sessionStorage.removeItem(KEY_FORM_DATA + user.id);
        }
    }

    static setTaskposterActivePageNum(pageNum) {
        if (pageNum) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_TASKPOSTER_ACTIVE_PAGENUM + user.id, pageNum);
            }
        }
    }

    static getTaskposterActivePageNum() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_TASKPOSTER_ACTIVE_PAGENUM + user.id);
            if (data) {
                return data;
            } else {
                return 0;
            }
        }
    }

    static setTaskposterBlackActivePageNum(pageNum) {
        if (pageNum) {
            let user = window.sessionStorage.getItem(KEY_USERINFO);
            if (user) {
                user = JSON.parse(user);
                window.sessionStorage.setItem(KEY_TASKPOSTER_BLACKACTIVE_PAGENUM + user.id, pageNum);
            }
        }
    }

    static getTaskposterBlackActivePageNum() {
        let user = window.sessionStorage.getItem(KEY_USERINFO);
        if (user) {
            user = JSON.parse(user);
            let data = window.sessionStorage.getItem(KEY_TASKPOSTER_BLACKACTIVE_PAGENUM + user.id);
            if (data) {
                return data;
            } else {
                return 0;
            }
        }
    }
}

export default Store
import React from 'react'
import {withRouter, Link} from "react-router-dom";
import conf from '../../../config/SidebarConfNew';
import {Menu} from 'antd';
import Util from "../../utils";

import {URL_WEBSITE} from '../../variables'
import SidebarLogo from "../commen/logo";

let CollapseMenu = [];
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SideBarNew extends React.Component {

    constructor(props) {
        super(props);
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toSecondPage = this.toSecondPage.bind(this);
        this.initMenu = this.initMenu.bind(this);
        this.activeSecondPage = this.activeSecondPage.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);
        this.activeFirstPage = this.activeFirstPage.bind(this);
        this.setMenu = this.setMenu.bind(this);
        this.active = this.active.bind(this);
        this.activeThirdPage = this.activeThirdPage.bind(this);
        this.toggleThirdMenu = this.toggleThirdMenu.bind(this);
        this.state = {
            sidebar: conf,
            defaultOpenKeys: {},
            theme: 1,
            menu: {}
        }
    }

    componentWillMount() {
        let sidebar = this.state.sidebar;
        let obj = this.state.defaultOpenKeys;
        sidebar.map((side) => {
            if (side.second && side.second.menulist.length > 0) {
                let id = side.first.key;
                let arr = [];
                let itemObj = {};
                let str = null;
                for (let i = 0; i < side.second.menulist.length; i++) {
                    arr.push(String(side.second.menulist[i].key));
                }
                arr.push(side.second.menulist[0].key);
                itemObj.openKeys = arr;
                obj[id] = itemObj;
            }
        });
        this.setState({
            defaultOpenKeys: obj
        });
        this.initMenu();
    }

    componentDidMount() {
        let sidebar = this.state.sidebar;
        let obj = this.state.defaultOpenKeys;
        let scrolls = {};
        // sidebar.map((side) => {
        //     let that = this;
        //     if (side.second && side.second.menulist.length > 0) {
        //         let id = side.first.key;
        //         if (this.refs[id]) {
        //             window[id + 'Scroll'] = Util.initScroll(this.refs[id]);
        //         }
        //         $("#" + id).mouseDelay(150).hover(function () {
        //             $(this).addClass('hover');
        //             window[id + 'Scroll'].refresh();
        //
        //             that.hoverShow(this);
        //         }, function () {
        //             $(this).removeClass('hover');
        //
        //             that.hoverShow(this);
        //         })
        //
        //
        //     }
        // });
        this.initMenu();
        $('.mxd-sidebar-second .bscroll-indicator').addClass('opacity');
        $('.mxd-sidebar-second').mouseover(function () {
            $(this).find('.bscroll-indicator').removeClass('opacity')
        });
        $('.mxd-sidebar-second').mouseleave(function () {
            $(this).find('.bscroll-indicator').addClass('opacity')
        })
        Util.initScroll(this.refs.scrollDiv)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.initMenu();
        if (this.state.theme != nextProps.theme) {
            this.setState({
                theme: nextProps.theme
            });
            if (nextProps.theme == 0) {
                $('.mxd-sidebar').removeClass('theme2')
            } else {
                $('.mxd-sidebar').addClass('theme2')
            }
        }

    }

    hoverShow(obj) {
        if ($(obj).hasClass('hover')) {
            if (!$(obj).hasClass('active')) {
                if ($(obj).find('.mxd-sidebar-hover').length > 0) {
                    $(obj).find('.mxd-sidebar-hover').addClass('mxd-slideUp');
                    $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-head').removeClass('mxd-collapsed');
                    $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-body').show();
                } else {
                    let el = $(obj).find('.mxd-sidebar-rmenu')[0];
                    $(el).addClass('mxd-slideUp');
                    $(el).find('.mxd-sidebar-rmenu-head').removeClass('mxd-collapsed');
                    $(el).find('.mxd-sidebar-rmenu-body').show();
                }
            } else {

            }
        } else {
            $(obj).find('.mxd-sidebar-hover').removeClass('mxd-slideUp');
            $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-head').addClass('mxd-collapsed');
            $(obj).find('.mxd-sidebar-hover .mxd-sidebar-rmenu-body').hide();
        }

    }

    setMenu(data) {
        this.setState({
            menu: data
        })
    }

    active(id) {
        let target = $("#" + id);
        $('.second-active').removeClass('second-active');
        target.addClass('second-active');
    }

    initMenu() {
        $('.mxd-selected').removeClass('mxd-selected')
        let cm = window.sessionStorage.getItem("CollapseMenu");
        if (cm) {
            CollapseMenu = JSON.parse(cm);
        }
        let sidebar = this.state.sidebar;
        let activeFirstPage = this.activeFirstPage;
        let activeSecondPage = this.activeSecondPage;
        let activeThirdPage = this.activeThirdPage;
        let noSecond = this.noSecond;
        let hasTitle = false;
        let foldMenu = this.foldMenu;
        let unFoldMeu = this.unFoldMeu;
        let setMenu = this.setMenu;
        let toggleThirdMenu = this.toggleThirdMenu;
        sidebar.map(function (side) {
            let first = side.first;
            if (side.second && side.second.menulist.length > 0) {
                let menulist = side.second.menulist;
                menulist.map(function (menu) {
                    foldMenu(menu.key, 0);
                    if (menu.submenu && menu.submenu.length > 0) {
                        menu.submenu.map(function (submenu) {

                            if (submenu.submenu && submenu.submenu.length > 0) {
                                submenu.submenu.map(function (thirdMenu) {
                                    if (thirdMenu.pathIn && thirdMenu.pathIn()) {
                                        activeFirstPage(first.key);
                                        activeSecondPage(submenu.key, first.key)
                                        activeThirdPage(thirdMenu.key, submenu.key, first.key)
                                        hasTitle = true;
                                    }
                                })
                            } else {
                                if (submenu.pathIn && submenu.pathIn()) {
                                    activeFirstPage(first.key);
                                    activeSecondPage(submenu.key, first.key)
                                    hasTitle = true;
                                    unFoldMeu(submenu.key, 0)
                                    setMenu(menu)
                                }
                            }

                        });
                    } else {
                        if (menu.pathIn && menu.pathIn()) {
                            activeFirstPage(first.key);
                            activeSecondPage(menu.key, first.key)
                            unFoldMeu(menu.key, 0)
                        }
                    }
                })
            } else {
                if (first.pathIn && first.pathIn()) {
                    activeFirstPage(first.key);
                    noSecond();
                }
            }
        });
        if (!hasTitle) {
            sessionStorage.removeItem('navTitle')
        }
        // let foldMenu = this.foldMenu;
        // CollapseMenu.map(function (menuId) {
        //     foldMenu(menuId);
        // })
    }

    collapseMenu(e) {
        let p = $(e.currentTarget);
        let id = p.attr('id');


        if (p.hasClass('mxd-collapsed')) {
            $('.mxd-sidebar-rmenu').removeClass('mxd-slideUp');
            $('.mxd-sidebar-rmenu').find('.mxd-sidebar-rmenu-head').addClass('mxd-collapsed');
            $('.mxd-sidebar-rmenu').find('.mxd-sidebar-rmenu-body').slideUp();

            this.unFoldMeu(id);//展开
            let index = CollapseMenu.indexOf(id);
            CollapseMenu.splice(index, 1);
        } else {
            this.foldMenu(id);//收起
            CollapseMenu.push(id);
        }
        window.sessionStorage.setItem("CollapseMenu", JSON.stringify(CollapseMenu));
    }

    foldMenu(id, flag) {
        let target = $("#" + id);
        target.addClass('mxd-collapsed');
        if (flag == 0) {
            // target.siblings('.mxd-sidebar-rmenu-body').hide();
        } else {
            // target.siblings('.mxd-sidebar-rmenu-body').slideUp();
        }

        target.parent().removeClass('mxd-slideUp');
    }

    unFoldMeu(id, flag) {
        let target = $("#" + id);
        target.removeClass('mxd-collapsed');
        target.addClass('mxd-selected');
        if (flag == 0) {
            target.siblings('.mxd-sidebar-rmenu-body').show();
        } else {
            // target.siblings('.mxd-sidebar-rmenu-body').slideDown();
        }
        target.parent().addClass('mxd-slideUp');
    }

    toFirstPage(side) {
        let first = side.first.key;
        $(".mxd-sidebar-lmenu").each(function (data) {
            if ($(this).attr("id") == first) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
        if (side.first.path) {
            this.props.history.push(side.first.path);
        }
        if (side.second && side.second.menulist.length > 0) {
            this.hasSecond();
        } else {
            //没有第二级菜单
            this.noSecond();
        }
    }

    noSecond() {
        $("#mxdIndex").addClass("mxd-sidebar-nosecond");
        sessionStorage.removeItem('navTitle')
    }

    hasSecond() {
        $("#mxdIndex").removeClass("mxd-sidebar-nosecond");
    }

    toSecondPage(menu) {
        if (menu.path) {
            if (menu.key == 'operateDesc' || menu.target == '_blank') {
                window.open(menu.path, "_blank");
            } else {
                $(".mxd-sidebar-thirdMenu-body div").removeClass("active");
                $(".mxd-sidebar-thirdMenu").find('.mxd-sidebar-thirdMenu-body').slideUp();
                $(".mxd-sidebar-thirdMenu").removeClass('slideDown');
                this.props.history.push(menu.path);
            }
        }
    }

    activeFirstPage(id) {
        $(".mxd-sidebar-lmenu").removeClass("active");
        $("#" + id).addClass("active");
    }

    activeSecondPage(id, key) {
        // $(".mxd-sidebar-rmenu .ant-menu-item-selected").removeClass("ant-menu-item-selected");
        // $("#" + id).addClass("ant-menu-item-selected");
        $('.mxd-sidebar-rmenu').removeClass('active');
        $(".mxd-sidebar-rmenu li").removeClass("active");
        $("#" + id).addClass("active");
        $('#' + id).parents('.mxd-sidebar-rmenu').addClass('active');

        if (key) {
            var obj = this.state.defaultOpenKeys;
            let arr = [];
            arr.push(id)
            obj[key].selectedKeys = arr;
            this.setState({
                defaultOpenKeys: obj
            })
            sessionStorage.setItem('navTitle', $("#" + id).text())
            // this.props.showNavTitle()
        }

        this.hasSecond();
    }

    activeThirdPage(id3, id2, key) {
        $(".mxd-sidebar-thirdMenu-body div").removeClass("active");
        $('#' + id3).parents('.mxd-sidebar-thirdMenu').addClass('active');
        $('#' + id3).addClass('active');
        this.showThirdMenu(id2);
    }


    /*新*/
    goPage(menu) {
        if (menu.path) {
            if (menu.key == 'operateDesc' || menu.target == '_blank') {
                window.open(menu.path, "_blank");
            } else {
                this.props.history.push(menu.path);
            }
        }
    }

    toggleThirdMenu(id) {
        let target = $("#" + id);
        if (target.hasClass('slideDown')) {
            this.hideThirdMenu(id);
        } else {
            this.showThirdMenu(id);
        }
    }

    showThirdMenu(id) {
        // $('.slideDown').each(function () {
        //     if ($(this).attr('id') != id) {
        //         $(this).find('.mxd-sidebar-thirdMenu-body').slideUp();
        //         $(this).removeClass('slideDown');
        //     }
        // });

        let target = $("#" + id);
        target.find('.mxd-sidebar-thirdMenu-body').slideDown();
        target.addClass('slideDown');
    }

    hideThirdMenu(id) {
        let target = $("#" + id);
        target.find('.mxd-sidebar-thirdMenu-body').slideUp();
        target.removeClass('slideDown');
    }

    render() {
        let toSecondPage = this.toSecondPage;

        return (
            <div className="mxd-sidebar theme5">
                <div className="mxd-sidebar-first">
                    <SidebarLogo></SidebarLogo>
                    <div ref={'scrollDiv'} className='scrollDiv'>
                        <ul>
                            {
                                this.state.sidebar.map((side) => {
                                    return (
                                        <div id={side.first.key} key={side.first.key} className="mxd-sidebar-lmenu">
                                            {
                                                side.second && side.second.menulist.length > 0 ?
                                                    <ul>
                                                        {
                                                            side.second.menulist.map((menu) => {
                                                                return (
                                                                    <li key={menu.key}
                                                                        className={`mxd-sidebar-rmenu ${menu.hoverOpen ? 'mxd-sidebar-hover' : ''}`}>
                                                                        <div id={menu.key}
                                                                             className={"mxd-sidebar-rmenu-head" + (menu.submenu && menu.submenu.length > 0 ? "" : " nosubmenu")}>
                                                                            <span>{menu.title}</span>
                                                                        </div>
                                                                        <ul className="mxd-sidebar-rmenu-body">
                                                                            {
                                                                                menu.submenu && menu.submenu.length > 0 ? menu.submenu.map((submenu) => {
                                                                                    return (
                                                                                        <li id={submenu.key}
                                                                                            key={submenu.key}
                                                                                            className={'mxd-sidebar-thirdMenu'}
                                                                                        >
                                                                                                <span
                                                                                                    onClick={
                                                                                                        submenu.submenu && submenu.submenu.length > 0 ?
                                                                                                            this.toggleThirdMenu.bind(this, submenu.key) :
                                                                                                            toSecondPage.bind(this, submenu)
                                                                                                    }>
                                                                                                    {submenu.title}
                                                                                                    <i className={submenu.suffixIcon}></i>
                                                                                                    {
                                                                                                        submenu.submenu && submenu.submenu.length > 0 ?
                                                                                                            <i className={'mxd-sidebar-arrow'}></i> : ''
                                                                                                    }
                                                                                                </span>

                                                                                            {
                                                                                                submenu.submenu && submenu.submenu.length > 0 ?
                                                                                                    <div
                                                                                                        className={'mxd-sidebar-thirdMenu-body'}>
                                                                                                        {
                                                                                                            submenu.submenu.map((third) => (
                                                                                                                <div
                                                                                                                    id={third.key}
                                                                                                                    key={third.key}
                                                                                                                    onClick={this.goPage.bind(this, third)}
                                                                                                                >{third.title}<i
                                                                                                                    className={third.suffixIcon}></i>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div> : ''
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                }) : ''
                                                                            }
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul> : ''
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SideBarNew)
import React from 'react'

let footer = {
    textAlign: 'center',
    color: '#909298',
    fontSize: '10px',
    width: '400px',
    position: 'fixed',
    bottom: '0',
    left: 'calc(50% - 200px)'
};

let footerText = {
    margin: '0 0 5px 0'
};

class Footer extends React.Component {
    render() {
        return (
            <footer style={footer}>
                <p style={footerText}>
                    隐私权政策 京ICP备18008915号-1
                </p>
                <p style={footerText}>
                    Copyright©2018 94mxd.COM All Rights Reserved 媒想到
                </p>
            </footer>
        )
    }
}

export default Footer
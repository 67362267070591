import React from "react";
import { Empty } from 'antd';
import EmptyImg from '../../../images/empty.png'

class PubEmpty extends React.Component{
    constructor(props) {
        super(props)
    }

    render(){
        return(
            <div className={`ant-empty ant-empty-normal ${this.props.className?this.props.className:''}`}>
                <div className="ant-empty-image">
                    <img alt="暂无数据" src={EmptyImg}/>
                </div>
                <p className="ant-empty-description">{this.props.desc?this.props.desc:'暂无数据'}</p>
            </div>
        )
    }

}

export default PubEmpty
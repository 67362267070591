import {Path, PATH, PATH_NAME} from "../cmpts/path";

const SidebarConfNew = [
    {
        hide: true,
        first: {
            title: '首页',
            key: 'home',
            path: PATH.home,
            pathIn: Path.isHome,
            icon: 'sidebar-icon-home',
        }
    },
    {
        first: {
            title: '公众号',
            key: 'subsFission',
            path: PATH.subsAuth,
            pathIn: Path.isExt,
            icon: 'icon-sidebarlib-wx'
        },
        second: {
            title: '公众号涨粉',
            menulist: [
                {
                    title: '任务宝',
                    key: 'taskposter',
                    icon: 'icon-nav-task',
                    path: PATH.tpManage,
                    type: 'nav_subs_taskposter',
                    submenu: [
                        {
                            title: '活动管理',
                            key: 'tpManage',
                            path: PATH.tpManage,
                            pathIn: Path.isTpManage
                        },
                        {
                            title: '推广码',
                            key: 'tpChannel',
                            path: PATH.tpChannel,
                            pathIn: Path.isTpChannel
                        },
                        {
                            title: '数据统计',
                            key: 'tpStas',
                            path: PATH.tpStas,
                            pathIn: Path.isTpStas
                        },
                        {
                            title: '参与粉丝',
                            key: 'tpFans',
                            path: Path.getTaskposterFansPath() + 0,
                            pathIn: Path.isTpFans
                        },
                        {
                            title: '表单管理',
                            key: 'tpFormManage',
                            path: PATH.taskpostFormManage,
                            type: 'nav_tool_form',
                            pathIn: Path.isTaskpostFormManage,
                        },
                        {
                            title: '物流查询',
                            key: 'tpTaskpostLogistics',
                            path: Path.getTaskpostLogisticsList(),
                            pathIn: Path.isTaskpostLogistics
                        },
                        {
                            title: '兑换码',
                            key: 'tpRedeemCodeManage',
                            path: PATH.taskpostRedeemCodeManage,
                            type: 'nav_tool_redeemcode',
                            pathIn: Path.isTaskpostRedeemcodeManage,
                        },
                        {
                            title: '任务商品',
                            key: 'taskGoodsList',
                            type: 'nav_tool_taskgoods',
                            path: PATH.goodsListTaskpost,
                            pathIn: Path.isTaskPostGoodsList,
                            submenu: [
                                {
                                    title: '商品管理',
                                    key: 'goodsListTaskpost',
                                    path: PATH.goodsListTaskpost,
                                    pathIn: Path.isTaskPostGoodsList
                                },
                                {
                                    title: '订单管理',
                                    key: 'orderManageTaskpost',
                                    path: PATH.goodsOrderListTaskpost,
                                    pathIn: Path.isTaskPostGoodsOrderList
                                },
                                {
                                    title: '批量发货',
                                    key: 'orderDeliverTaskpost',
                                    path: PATH.goodsOrderDeliverTaskpost,
                                    pathIn: Path.isTaskPostGoodsOrderDeliver
                                },
                                {
                                    title: '核销员',
                                    key: 'orderCheckerTaskpost',
                                    path: PATH.goodsOrderCheckerTaskpost,
                                    pathIn: Path.isTaskPostGoodsOrderChecker
                                }
                            ]
                        },
                        {
                            title: '黑名单',
                            key: 'tpBlacklist',
                            path: PATH.tpBlacklist,
                            pathIn: Path.isTpBlacklist
                        },
                        {
                            title: '一件代发',
                            key: 'oneforone',
                            target: '_blank',
                            suffixIcon: 'icon-secondsidebar-festival',
                            path: 'http://www.94mxd.com.cn/oneforone.html',
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/55728bc8-aa90-4322-9a34-5df5da13482a'
                        }
                    ]
                },
                {
                    title: '消息宝',
                    key: 'message',
                    icon: 'icon-nav-msg',
                    path: PATH.tmRecord,
                    type: 'nav_subs_taskmsg',
                    submenu: [
                        {
                            title: '推送管理',
                            key: 'tmRecord',
                            path: PATH.tmRecord,
                            pathIn: Path.isTMPushRecord
                        },
                        {
                            title: 'SOP计划',
                            key: 'pushSOP',
                            path: Path.getPushList(),
                            pathIn: Path.isPush
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/a7b6fb22-6c57-4143-afd8-ff768cf9fa0e?#'
                        },
                    ]
                },
                {
                    title: '渠道码',
                    key: 'channel',
                    icon: 'icon-nav-ma',
                    path: PATH.channelQRList,
                    type: 'nav_subs_channelqrcode',
                    submenu: [
                        {
                            title: '码管理',
                            key: 'channelQRList',
                            path: PATH.channelQRList,
                            pathIn: Path.isCodeManage
                        },
                        {
                            title: '数据统计',
                            key: 'channelQRStats',
                            path: PATH.channelQRStats,
                            pathIn: Path.isCodeDetail
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/619268f2-75cd-40d3-852f-e03a2484e406?# '
                        },
                    ]
                },
                {
                    title: '节日签',
                    key: 'festivalSignTitle',
                    path: PATH.festivalSignList,
                    suffixIcon: 'icon-secondsidebar-festival',
                    type: 'nav_subs_festivalsign',
                    submenu: [
                        {
                            title: '活动管理',
                            key: 'festivalSign',
                            path: PATH.festivalSignList,
                            pathIn: Path.isFestivalSignManage,
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/2c881af2-ed0e-49a2-ac9f-b7d86611df5a',
                        }
                    ]
                },
                {
                    title: '分享宝',
                    key: 'share',
                    icon: 'icon-nav-share',
                    path: PATH.stManage,
                    type: 'nav_subs_sharetreasure',
                    submenu: [
                        {
                            title: '分享管理',
                            key: 'stManage',
                            path: PATH.stManage,
                            pathIn: Path.isStManage
                        },
                        {
                            title: '数据统计',
                            key: 'stDetail',
                            path: PATH.stDetail,
                            pathIn: Path.isStDetail
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/a7481aa5-0974-4592-bfa2-a997199241ec?# '
                        },

                    ]
                },
                {
                    title: '公众号菜单栏',
                    key: 'wxmenu',
                    icon: 'icon-nav-menu',
                    path: Path.getWXCreate(),
                    type: 'nav_subs_menu',
                    submenu: [
                        {
                            title: '自定义菜单',
                            key: 'wxMenuCreate',
                            path: Path.getWXCreate(),
                            pathIn: Path.isWxCreate
                        },
                        {
                            title: '个性化菜单',
                            key: 'wxMenuIndividuation',
                            path: Path.getWXIndividuationList(),
                            pathIn: Path.isWXIndCreate
                        },
                        {
                            title: '自动回复',
                            key: 'autoReply',
                            path: Path.getWXAutoReplay(),
                            pathIn: Path.isWXAutoReplay
                        }
                    ]
                },
                {
                    title: '公众号授权',
                    key: 'subsManage',
                    icon: 'icon-nav-public',
                    path: PATH.subsAuth,
                    type: 'nav_subs_authlist',
                    submenu: [
                        {
                            title: '公众号授权',
                            key: 'subsAuth',
                            path: PATH.subsAuth,
                            pathIn: Path.isSubsAuth
                        }
                    ]
                },
                {
                    title: '粉丝管理',
                    key: 'fansManageTitle',
                    path: PATH.fansManage,
                    type: 'nav_subs_fansmanage',
                    pathIn: Path.isFansManage,
                    submenu: [
                        {
                            title: '粉丝管理',
                            key: 'fansManage',
                            path: PATH.fansManage,
                            type: 'nav_subs_fansmanage',
                            pathIn: Path.isFansManage,
                        }
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '企业微信',
            key: 'compWx',
            path: Path.getCorpTaskList(),
            pathIn: Path.isCorpTask,
            icon: 'icon-sidebarlib-wx-com'
        },
        second: {
            title: '企业微信',
            menulist: [
                {
                    title: '客户裂变',
                    key: 'corpTaskTitle',
                    icon: 'icon-nav-task',
                    type: 'nav_corp_customerfission',
                    path: Path.getCorpTaskList(),
                    submenu: [
                        {
                            title: '企业微信',
                            key: 'corpWx',
                            path: Path.getCorpTaskAuth(),
                            pathIn: Path.isCorpMemberWx
                        },
                        {
                            title: '成员管理',
                            key: 'corpMemberList',
                            path: Path.getCorpMemberList(),
                            pathIn: Path.isCorpMemberList
                        },
                        {
                            title: '活动管理',
                            key: 'corpTask',
                            path: Path.getCorpTaskList(),
                            pathIn: Path.isCorpTask
                        },
                        {
                            title: '数据统计',
                            key: 'corpData',
                            path: Path.getCorpData() + 0,
                            pathIn: Path.isCorpData
                        },
                        {
                            title: '参与粉丝',
                            key: 'corpFans',
                            path: Path.getCorpFans() + 0,
                            pathIn: Path.isCorpFans
                        },
                        {
                            title: '表单管理',
                            key: 'corpFormManage',
                            path: Path.getCorpManageFormPath(),
                            type: 'nav_tool_form',
                            pathIn: Path.isCorpFormManage,
                        },
                        {
                            title: '物流查询',
                            key: 'tpCorpLogistics',
                            path: Path.getCorpLogisticsList(),
                            pathIn: Path.isCorpLogistics
                        },
                        {
                            title: '兑换码',
                            key: 'corpRedeemCodeManage',
                            path: Path.getCorpManageRedeemcodePath(),
                            type: 'nav_tool_redeemcode',
                            pathIn: Path.isCorpRedeemcodeManage,
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/6f879dd5-3a18-408b-8937-3754d228c43f'
                        }
                    ]
                },
            ]
        }
    },
    {
        first: {
            title: '营销',
            key: 'distribution',
            path: Path.getDis(),
            pathIn: Path.isDis,
            icon: 'icon-sidebarlib-market'
        },
        second: {
            title: '分销管理',
            menulist: [
                {
                    title: '分销',
                    key: 'retailManage',
                    icon: 'icon-nav-retail',
                    path: Path.getDisRetailList(),
                    type: 'nav_market_distri',
                    submenu: [
                        {
                            title: '分销管理',
                            key: 'retailManange',
                            path: Path.getDisRetailList(),
                            pathIn: Path.isDisRetail,
                            submenu: [
                                {
                                    title: '分销管理',
                                    key: 'retailList',
                                    path: Path.getDisRetailList(),
                                    pathIn: Path.isDisRetail
                                },
                                {
                                    title: '渠道管理',
                                    key: 'retailChannelData',
                                    path: Path.getDisChannelList(),
                                    pathIn: Path.isDisChannel
                                },
                                {
                                    title: '收益报表',
                                    key: 'retailReport',
                                    path: Path.getDisRetailReport() + '/0',
                                    pathIn: Path.isDisRetailReport
                                },
                                {
                                    title: '分销提现',
                                    key: 'retailCashOut',
                                    path: Path.getDisCashList(),
                                    pathIn: Path.isDisCash
                                },
                                {
                                    title: '消息模板',
                                    key: 'retailMsg',
                                    path: Path.getDisMsg(),
                                    pathIn: Path.isDisMsg
                                },
                                {
                                    title: '一件代发',
                                    key: 'oneforone',
                                    target: '_blank',
                                    suffixIcon: 'icon-secondsidebar-festival',
                                    path: 'http://www.94mxd.com.cn/oneforone.html',
                                },
                                {
                                    title: '操作说明',
                                    key: 'operateDesc',
                                    path: 'https://www.yuque.com/docs/share/1944b3a4-83a2-4896-b023-5ce749c77439?#  '
                                },
                            ]
                        },
                        {
                            title: '核销管理',
                            key: 'writeOffManageTitle',
                            icon: 'icon-nav-writeoff',
                            path: Path.getWriteOffList(),
                            pathIn: Path.isWriteOff,
                            submenu: [
                                {
                                    title: '核销员管理',
                                    key: 'writeOffManage',
                                    icon: 'icon-nav-writeoff',
                                    path: Path.getWriteOffList(),
                                    pathIn: Path.isWriteOff,
                                }
                            ]
                        },
                    ]
                },
                {
                    title: '拼团',
                    key: 'groupBuyManage',
                    icon: 'icon-nav-groupbuy',
                    path: Path.getGroupBuyList(),
                    type: 'nav_market_groupbuy',
                    submenu: [
                        {
                            title: '拼团管理',
                            key: 'groupBuyList',
                            path: Path.getGroupBuyList(),
                            pathIn: Path.isGroupBuyList
                        },
                        {
                            title: '订单列表',
                            key: 'groupBuyOrder',
                            path: Path.getGroupBuyOrder(),
                            pathIn: Path.isGroupBuyOrder
                        },
                        {
                            title: '拼团提现',
                            key: 'groupBuyCash',
                            path: Path.getGroupBuyCash(),
                            pathIn: Path.isGroupBuyCash
                        },
                        {
                            title: '消息模板',
                            key: 'groupBuyMsg',
                            path: Path.getGroupBuyMsg(),
                            pathIn: Path.isGroupBuyMsg
                        },
                        {
                            title: '一件代发',
                            key: 'oneforone',
                            target: '_blank',
                            suffixIcon: 'icon-secondsidebar-festival',
                            path: 'http://www.94mxd.com.cn/oneforone.html',
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/1b13109d-c94e-4a0a-acde-7b172c61fc0e?#'
                        },
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '活码',
            key: 'liveCode',
            path: Path.getLiveCodeGroupList(),
            pathIn: Path.isLiveCodeGroup,
            icon: 'icon-sidebarlib-livecode'
        },
        second: {
            title: '活码管理',
            menulist: [
                {
                    title: '客服活码',
                    key: 'livecodeCustomerListTitle',
                    path: Path.getLiveCodeCustomerList(),
                    pathIn: Path.isLiveCodeCustomer,
                    icon: 'icon-nav-livecode',
                    type: 'nav_livecode_kf',
                    submenu: [
                        {
                            title: '客服活码',
                            key: 'livecodeCustomerList',
                            path: Path.getLiveCodeCustomerList(),
                            pathIn: Path.isLiveCodeCustomer
                        },
                        {
                            title: '数据统计',
                            key: 'livecodeDataStat',
                            path: Path.getLiveCustomCodeDataStat(),
                            pathIn: Path.isLiveCodeCustomerStat
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/15c3a86b-81e3-4acf-8c9f-5e4deee0a5d2?# '
                        },
                    ]
                },
                {
                    title: '群活码',
                    key: 'livecodeGroupListTitle',
                    path: Path.getLiveCodeGroupList(),
                    pathIn: Path.isLiveCodeGroup,
                    type: 'nav_livecode_qun',
                    submenu: [
                        {
                            title: '群活码',
                            key: 'livecodeGroupList',
                            path: Path.getLiveCodeGroupList(),
                            pathIn: Path.isLiveCodeGroup
                        },
                        {
                            title: '数据统计',
                            key: 'groupLivecodeDataStat',
                            path: Path.getLiveGroupCodeDataStat(),
                            pathIn: Path.isLiveCodeGroupStat
                        },
                        {
                            title: '操作说明',
                            key: 'operateDesc',
                            path: 'https://www.yuque.com/docs/share/aee3059d-a1e8-4fbe-9f5e-0d371954a8ce?# '
                        },
                    ]
                }
            ]
        }
    },
    {
        first: {
            title: '工具',
            key: 'tool',
            path: Path.getManageFormPath(),
            pathIn: Path.isFormManage,
            icon: 'icon-sidebarlib-tool'
        },
        second: {
            title: '工具',
            menulist: [
                {
                    title: '任务工具',
                    key: 'toolManageTitle',
                    path: PATH.formManage,
                    type: 'nav_task_tool',
                    submenu: [
                        {
                            title: '表单管理',
                            key: 'formManageTitle',
                            path: PATH.formManage,
                            // type: 'nav_tool_form',
                            pathIn: Path.isFormManage,
                        },
                        {
                            title: '物流查询',
                            key: 'tpLogistics',
                            path: Path.getLogisticsList(),
                            pathIn: Path.isLogistics
                        },
                        {
                            title: '兑换码',
                            key: 'redeemCodeManageTitle',
                            path: PATH.redeemCodeManage,
                            // type: 'nav_tool_redeemcode',
                            pathIn: Path.isRedeemcodeManage,
                        },
                        {
                            title: '任务商品',
                            key: 'toolTaskGoodsList',
                            type: 'nav_tool_taskgoods',
                            path: PATH.goodsList,
                            pathIn: Path.isGoodsList,
                            submenu: [
                                {
                                    title: '商品管理',
                                    key: 'goodsList',
                                    path: PATH.goodsList,
                                    pathIn: Path.isGoodsList
                                },
                                {
                                    title: '订单管理',
                                    key: 'orderManage',
                                    path: PATH.goodsOrderList,
                                    pathIn: Path.isGoodsOrderList
                                },
                                {
                                    title: '批量发货',
                                    key: 'orderDeliver',
                                    path: PATH.goodsOrderDeliver,
                                    pathIn: Path.isGoodsOrderDeliver
                                },
                                {
                                    title: '核销员',
                                    key: 'orderChecker',
                                    path: PATH.goodsOrderChecker,
                                    pathIn: Path.isGoodsOrderChecker
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        hide: true,
        first: {
            title: '购买',
            key: 'mine',
            path: Path.getMinePurchase(),
            pathIn: Path.isMinePurchase,
            icon: 'sidebar-icon-mine'
        },
        second: {
            title: '消费记录',
            menulist: [
                {
                    title: '消费管理',
                    key: 'mineOrderManage',
                    icon: 'icon-nav-manage',
                    path: Path.getMineOrderList(),
                    pathIn: Path.isOrderList,
                    submenu: [
                        {
                            title: '购买产品',
                            key: 'orderAdd',
                            path: Path.getMinePurchase(),
                            pathIn: Path.isMinePurchase
                        },
                        {
                            title: '消费记录',
                            key: 'orderList',
                            path: Path.getMineOrderList(),
                            pathIn: Path.isOrderList
                        },
                        {
                            title: '产品时长',
                            key: 'productInfo',
                            path: Path.getMineProductInfo(),
                            pathIn: Path.isMineProductInfo
                        }
                    ]
                }
            ]
        }
    },
]

export default SidebarConfNew
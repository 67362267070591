function reducer(state, action) {
    switch (action.type) {
        case 'SET_USER_INFO':
            return {
                userInfo: action.userInfo // 使用action携带的新name
            };
        case 'SET_SIDEBAR_INFO':
            state.sidebarInfo = action.sidebarInfo;
            return state

        default:
            return state  // 没有匹配的action type，返回原来的state
    }
}

export default reducer
import React from 'react'
import {FetchUtil} from '../fetch_util'
import {URL_PROMPTION_LOGIN_STATUS} from "../variables";
import FxHome from "./fx_home";
import FxSignIn from "./fx_signin";
import styles from './promotion.module.css'

let fetchUtil = new FetchUtil();

class Fx extends React.Component {

    constructor(props) {
        super(props);
        this.checkIn = this.checkIn.bind(this);
        this.state = {}
    }

    componentDidMount() {
        fetchUtil.init()
            .setMethod('GET')
            .setUrl(URL_PROMPTION_LOGIN_STATUS)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    var respMsg = msg.respMsg;
                    if (respMsg.status) {
                        this.setState({
                            loginStatus: respMsg.status
                        })
                    }
                }
            })
            .catch(() => {

            })
    }

    checkIn() {
        this.setState({
            loginStatus: 'ok'
        })
    }

    render() {
        return (
            <div className={styles.fx}>
                {
                    this.state.loginStatus == 'ok' ? <FxHome/> :
                        (
                            this.state.loginStatus == 'timeout' ? <FxSignIn checkIn={this.checkIn}/> : ''
                        )
                }
            </div>
        )
    }
}

export default Fx
import React from 'react'
// import './customer.less'
// import "../layout/welcome.less"
import ImgWXqrcode from '../../images/welcome/kefu.png'
import AlertImg from '../../images/alert/xgn.png'
import {Icon} from 'antd'
import Store from "../store";

class Customer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBigAlert: true,
            showSmallAlert: true,
            startData: '2019-12-25 00:00:00',
            endData: '2021-12-28 23:59:59',
            newsUrl: {
                url: 'https://www.yuque.com/docs/share/b35d3104-9394-4d8e-bf86-788d48472d31',
                expiredAt: '2021-12-28 23:59:59'
            }
        }
    }

    componentDidMount() {
        let alertStatus = Store.getAlertData();
        if (alertStatus) {
            if (alertStatus == 1) {
                this.setState({
                    showBigAlert: true,
                    showSmallAlert: true
                });
                Store.setAlertData(2);
            }
            if (alertStatus == 2) {
                this.setState({
                    showBigAlert: false,
                    showSmallAlert: true
                });
            }
        }

        if (!this.isShowAlert()) {
            this.setState({
                showBigAlert: false,
                showSmallAlert: false
            });
        }
    }

    hideContact() {
        $('.contact').hide();
    }

    showContact() {
        $('.contact').show();
    }

    hideAlert(key) {
        this.setState({
            [key]: false
        })
    }

    showAlert(key) {
        this.setState({
            [key]: true
        })
    }

    hideAlertSmall(key) {
        Store.deleteAlertData();
        this.hideAlert(key);
    }

    isShowAlert() {
        let now = new Date().getTime();
        let startData = new Date(this.state.startData).getTime();
        let endTime = new Date(this.state.endData).getTime();
        if (endTime > now && startData <= now) {
            return true;
        }
        return false;
    }

    gotoDetail() {
        let newsUrl = this.state.newsUrl;
        let url = newsUrl.url;
        let expiredAt = newsUrl.expiredAt;
        let now = new Date().getTime();
        let endTime = new Date(expiredAt).getTime();
        if (endTime > now) {
            window.open(url);
        }
    }

    render() {
        return (
            <div className="kfcontainer">
                <div>
                    {
                        this.state.showBigAlert ?
                            <div className={'alertBig'}>
                                <div>
                                    <img src={AlertImg} width="640" onClick={this.gotoDetail.bind(this)}/>
                                    <span className={'alert-close'}
                                          onClick={this.hideAlert.bind(this, 'showBigAlert')}><Icon
                                        type="close"/></span>
                                </div>
                            </div> : ''
                    }
                    {
                        this.state.showSmallAlert ?
                            <div className={'alertSmall'}>
                                <span className={'alert-close'}
                                      onClick={this.hideAlertSmall.bind(this, 'showSmallAlert')}><Icon
                                    type="close"/></span>
                                <img src={AlertImg} onClick={this.showAlert.bind(this, 'showBigAlert')}/>
                            </div> : ''
                    }
                </div>
                <div onMouseEnter={this.showContact} onMouseLeave={this.hideContact}>
                    <div className="kfimgCon">
                        <i className="icon-kfimg kfimgIcon"></i>
                        <span>客服帮助</span>
                    </div>
                    <div className="contact">
                        <div>
                            <div className="kfcon">
                                <span className="kfconTitle">服务热线</span>
                                <p className="kfconBorder"></p>
                                <i className="icon-phone kfconIcon"></i>
                                <span className="kfconPhone">4000832158</span>
                            </div>
                            <div className="kfconBottom">
                                <span className="kfconTitle">客服微信</span>
                                <p className="kfconBorder"></p>
                                <img className="kfconImg" src={ImgWXqrcode}/>
                                <div>
                                    <span className="kfconTime">客服咨询时间</span>
                                    <span className="kfconTime mxd-display-block"><span
                                        className="kfDataName">工作日 </span>9:00—19:00</span>
                                </div>
                            </div>


                        </div>
                        {/*<ul>*/}
                        {/*<li><p className="text-center text-gray">客服微信</p></li>*/}
                        {/*<li className="text-center"><img width="150px" height="150px" src={ImgWXqrcode}/></li>*/}
                        {/*</ul>*/}
                        <div className="triangleRight"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Customer
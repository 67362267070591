import {
    URL_GET_MSG_TEMPLATE_SELECT_LIST,
    URL_GET_MSG_TEMPLATE_TASK_ACTIVE_CORPLIST,
    URL_GET_MSG_TEMPLATE_TASK_ACTIVE_LIST,
    URL_GET_NOBIND_TASKGOODS,
    URL_GET_TEMPLATE_MSG_LIST,
    URL_GET_TEMPLATE_MSG_SELECT_LIST,
    URL_SYNC_MY_TEMPLATE_LIST,
    URL_GET_TASKPOSTER_LINKINFO,
    URL_QUERY_FESTIVAL_ACTIVITYLIST,
    URL_DELETE_FESTIVAL_ACTIVITYLIST,
    URL_CREATE_FESTIVAL_ACTIVITY,
    URL_MODIFY_FESTIVAL_ACTIVITY_STATUS,
    URL_GET_FESTIVAL_ACTIVITY_DETAIL,
    URL_EDIT_FESTIVAL_ACTIVITY,
    URL_GET_TASKPOSTER_ACTIVEFANS_FORMDATA,
    URL_PUSH_TSAK_PREVIEW,
    URL_GET_GOODSORDER_CHECKER_LIST,
    URL_ADD_GOODSORDER_CHECKER,
    URL_DEL_GOODSORDER_CHECKER,
    URL_QUERY_GOODSCHECKER_ORDER,
    URL_GOODSORDER_GOODNAME_LIST,
    URL_GET_GOODSCHECKER_NAMELIST,
    URL_GET_GOODSORDER_DELIVER_RECORDLIST,
    URL_DEL_GOODSORDER_DELIVER_RECORD,
    URL_QUERY_GOODSORDER_LIST,
    URL_GET_GOODSORDER_DATASTATS,
    URL_GET_GOODSORDER_BUYERINFO,
    URL_DELIVER_GOODSORDER,
    URL_EDIT_GOODSORDER_DELIVERINFO,
    URL_UPLOAD_GOODSORDER_BULKDELIVER_DATA,
    URL_GET_SUBSTAG_LIST,
    URL_GET_SYSTEM_UPGRADE_NEWCOUNT,
    URL_VIEWCILCK_SYSTEM_UPGRADE, URL_MANUALPASS_SHARETREASURE, URL_GET_MSG_TEMPLATE_MSG_SYNC
} from "./variables";
import {FetchUtil} from "./fetch_util";

let fetchUtil = new FetchUtil();

class Api {

    static doGet(url, success, error) {

        fetchUtil.init()
            .setUrl(url)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    if (success) {
                        success(msg.respMsg)
                    }
                } else {
                    if (error) {
                        error(msg)
                    }
                }
            })
            .catch((msg) => {
                if (error) {
                    error(msg)
                }
            })
    }

    static doPost(url, bodyType, bodyData, success, error) {
        fetchUtil.init()
            .setUrl(url)
            .setMethod("POST")
            .setCookieCors()
            .setBodyType(bodyType)
            .setBody(bodyData)
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    if (success) {
                        success(msg.respMsg)
                    }
                } else {
                    if (error) {
                        error(msg)
                    }
                }
            })
            .catch((msg) => {
                if (error) {
                    error(msg)
                }
            })
    }

    static syncMsgTemplateList(appId, success, error) {
        fetchUtil.init()
            .setUrl(URL_SYNC_MY_TEMPLATE_LIST + "?appId=" + appId)
            .setMethod("POST")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((data) => {
                if (success) {
                    success(data)
                }
            })
            .catch((err) => {
                if (error) {
                    error(err);
                }
            })
    }

    // static getMsgTemplateList(success, error) {
    //     fetchUtil.init()
    //         .setUrl(URL_GET_MSG_TEMPLATE_SELECT_LIST)
    //         .setMethod('GET')
    //         .setCookieCors()
    //         .dofetch()
    //         .then((data) => {
    //             if (data.respCode == 0) {
    //                 if (success) {
    //                     success(data.respMsg);
    //                 } else if (error) {
    //                     error();
    //                 }
    //             }
    //         })
    //         .catch(() => {
    //             if (error) {
    //                 error();
    //             }
    //         });
    // }

    static getTemplateMsgSelectList(pageNum, success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_TEMPLATE_MSG_SELECT_LIST + '?pageNum=' + pageNum)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else if (error) {
                    error(data)
                }
            })
            .catch(() => {
                if (error) {
                    error()
                }
            })
    }

    static getTemplateMsgList(pageNum, success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_TEMPLATE_MSG_LIST + '?pageNum=' + pageNum)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else if (error) {
                    error(data)
                }
            })
            .catch(() => {
                if (error) {
                    error()
                }
            })
    }

    static getSubsList(success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_MSG_TEMPLATE_TASK_ACTIVE_LIST)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else if (error) {
                    error(data)
                }
            })
            .catch(() => {
                if (error) {
                    error()
                }
            })
    }

    static getCorpSubsList(success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_MSG_TEMPLATE_TASK_ACTIVE_CORPLIST)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (data.respCode == 0) {
                    if (success) {
                        success(data.respMsg)
                    }
                } else if (error) {
                    error(data)
                }
            })
            .catch(() => {
                if (error) {
                    error()
                }
            })
    }

    /**
     * 获取没有绑定任务的商品
     * @param success
     * @param error
     */
    static getNoBindTaskGoodsList(success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_NOBIND_TASKGOODS)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (success) {
                    success(data)
                }
            })
            .catch((err) => {
                if (error) {
                    error(err)
                }
            })
    }

    static getTpLinkInfo(taskposterId, fansId, success, error) {
        fetchUtil.init()
            .setUrl(URL_GET_TASKPOSTER_LINKINFO + "?taskposterId=" + taskposterId + "&fansId=" + fansId)
            .setMethod('GET')
            .setCookieCors()
            .dofetch()
            .then((data) => {
                if (success) {
                    success(data)
                }
            })
            .catch((err) => {
                if (error) {
                    error(err)
                }
            })
    }

    /**
     * 获取节日签活动列表
     * @param params
     * @param success
     * @param error
     */
    static getfestivalSignActivityList(params, success, error) {
        let bodyData = params.bodyData;
        let pageNum = params.pageNum;
        let pageSize = params.pageSize;
        this.doPost(URL_QUERY_FESTIVAL_ACTIVITYLIST + '?pageNum=' + pageNum + '&pageSize=' + pageSize, 'json', bodyData, success, error);
    }

    /**
     * 删除节日签活动
     * @param activityId
     * @param success
     * @param error
     */
    static deleteFestivalSignActivity(activityId, success, error) {
        this.doPost(URL_DELETE_FESTIVAL_ACTIVITYLIST + '?activityId=' + activityId, 'json', {}, success, error)
    }

    /**
     * 创建节日签活动
     * @param data
     * @param success
     * @param error
     */
    static createFestivalSignActivity(data, success, error) {
        this.doPost(URL_CREATE_FESTIVAL_ACTIVITY, 'json', data, success, error)
    }


    /**
     * 创建节日签活动
     * @param data
     * @param success
     * @param error
     */
    static editFestivalSignActivity(data, success, error) {
        this.doPost(URL_EDIT_FESTIVAL_ACTIVITY, 'json', data, success, error)
    }

    /**
     * 修改节日签过段状态
     * @param activityId
     * @param status
     * @param success
     * @param error
     */
    static modifyFestivalSignActivityStatus(activityId, status, success, error) {
        this.doPost(URL_MODIFY_FESTIVAL_ACTIVITY_STATUS + '?activityId=' + activityId + '&status=' + status, 'json', {}, success, error)
    }

    /**
     * 获取节日签活动详情
     * @param activityId
     * @param success
     * @param error
     */
    static getFestivalSignActivityDetail(activityId, success, error) {
        this.doGet(URL_GET_FESTIVAL_ACTIVITY_DETAIL + '?activityId=' + activityId, success, error)
    }

    /**
     * 获取任务宝粉丝表单数据
     * @param taskposterId
     * @param fansId
     * @param success
     * @param error
     */
    static getTpActiveFanFormData(taskposterId, fansId, success, error) {
        this.doGet(URL_GET_TASKPOSTER_ACTIVEFANS_FORMDATA + '?taskposterId=' + taskposterId + '&fansId=' + fansId, success, error)
    }

    /**
     * 获取SOP计划预览
     * @param taskId
     * @param success
     * @param error
     */
    static getSopPreviewCon(taskId, success, error) {
        this.doGet(URL_PUSH_TSAK_PREVIEW + '?taskId=' + taskId, success, error)
    }

    /**
     * 获取核销员列表
     * @param pageNum
     * @param pageSize
     * @param success
     * @param error
     */
    static getGoodsOrderCheckerList(pageNum, pageSize, success, error) {
        this.doGet(URL_GET_GOODSORDER_CHECKER_LIST + '?pageNum=' + pageNum + '&pageSize=' + pageSize, success, error)
    }

    /**
     * 添加任务商品核销员
     * @param submitData
     * @param success
     * @param error
     */
    static addGoodsOrderChecker(submitData, success, error) {
        this.doPost(URL_ADD_GOODSORDER_CHECKER, 'json', submitData, success, error);
    }

    /**
     * 删除核销员
     * @param checkerId
     * @param success
     * @param error
     */
    static delGoodsOrderChecker(checkerId, success, error) {
        this.doPost(URL_DEL_GOODSORDER_CHECKER, 'json', {checkerId: checkerId}, success, error);
    }

    /**
     * 查询核销员订单
     * @param queryParams
     * @param success
     * @param error
     */
    static queryGoodsCheckerOrderList(queryParams, pageNum, pageSize, success, error) {
        this.doPost(URL_QUERY_GOODSCHECKER_ORDER + '?pageNum=' + pageNum + '&pageSize=' + pageSize, 'json', queryParams, success, error);
    }

    /**
     * 获取任务商品名称列表
     * @param success
     * @param error
     */
    static getGoodsNameList(success, error) {
        this.doGet(URL_GOODSORDER_GOODNAME_LIST, success, error);
    }

    /**
     * 获取任务商品核销员名称列表
     * @param success
     * @param error
     */
    static getGoodsOrderCheckerNameList(success, error) {
        this.doGet(URL_GET_GOODSCHECKER_NAMELIST, success, error);
    }

    /**
     * 获取任务商品批量发货记录
     * @param pageNum
     * @param pageSize
     * @param success
     * @param error
     */
    static getGoodsOrderDeliverRecordList(pageNum, pageSize, success, error) {
        this.doGet(URL_GET_GOODSORDER_DELIVER_RECORDLIST + '?pageNum=' + pageNum + '&pageSize=' + pageSize, success, error);
    }

    /**
     * 删除任务商品批量发货记录
     * @param id
     * @param success
     * @param error
     */
    static delGoodsOrderDeliverRecord(id, success, error) {
        this.doPost(URL_DEL_GOODSORDER_DELIVER_RECORD, 'json', {id: id}, success, error);
    }

    /**
     * 获取商品订单
     * @param queryParams
     * @param pageNum
     * @param pageSize
     * @param success
     * @param error
     */
    static getGoodsOrderList(queryParams, pageNum, pageSize, success, error) {
        this.doPost(URL_QUERY_GOODSORDER_LIST + '?pageNum=' + pageNum + '&pageSize=' + pageSize, 'json', queryParams, success, error);
    }

    /**
     * 获取订单数据统计
     * @param success
     * @param error
     */
    static getGoodsOrderDataStats(success, error) {
        this.doGet(URL_GET_GOODSORDER_DATASTATS, success, error);
    }

    /**
     * 获取订单用户信息
     * @param orderId
     * @param success
     * @param error
     */
    static getGoodsOrderBuyerFillInfo(orderId, success, error) {
        this.doGet(URL_GET_GOODSORDER_BUYERINFO + '?orderId=' + orderId, success, error);
    }

    /**
     * 任务商品订单发货
     * @param param
     * @param success
     * @param error
     */
    static deliverGoodsOrder(param, success, error) {
        Api.doPost(URL_DELIVER_GOODSORDER, 'json', param, success, error);
    }

    /**
     * 编辑任务商品发货信息
     * @param editInfo
     * @param success
     * @param error
     */
    static editGoodsOrderDeliverInfo(editInfo, success, error) {
        Api.doPost(URL_EDIT_GOODSORDER_DELIVERINFO, 'json', editInfo, success, error);
    }

    /**
     * 上传任务商品订单批量发货数据
     * @param param
     * @param success
     * @param error
     */
    static uploadGoodsOrderDeliverData(param, success, error) {
        Api.doPost(URL_UPLOAD_GOODSORDER_BULKDELIVER_DATA, 'file', param, success, error);
    }

    /**
     * 获取标签列表
     * @param appId
     * @param success
     * @param error
     */
    static getSubsFansTag(appId, success, error) {
        Api.doGet(URL_GET_SUBSTAG_LIST + "?appId=" + appId, success, error);
    }

    /**
     * 获取系统更新数量
     * @param success
     * @param error
     */
    static getUpgradeNewCount(success, error) {
        Api.doGet(URL_GET_SYSTEM_UPGRADE_NEWCOUNT, success, error);
    }

    /**
     * 更新日志点击
     * @param success
     * @param error
     */
    static upgradeViewClick(success, error) {
        Api.doPost(URL_VIEWCILCK_SYSTEM_UPGRADE, 'json', {}, success, error);
    }

    /**
     * 分享宝手动审核
     * @param stId
     * @param fansId
     * @param success
     * @param error
     */
    static shareTreasurePass(stId, fansId, success, error) {
        Api.doPost(URL_MANUALPASS_SHARETREASURE + '?shareTreasureId=' + stId + '&fansId=' + fansId, 'json', {}, success, error);
    }

    /**
     * 获取模板消息列表
     * @param appId
     * @param success
     * @param error
     */
    static getMsgTemplateList(appId, success, error) {
        Api.doPost(URL_GET_MSG_TEMPLATE_MSG_SYNC + '?appId=' + appId, 'json', {}, success, error);
    }
}

export default Api
import React from 'react'
import {URL_LOGIN_STATUS} from "../variables";
import {FetchUtil} from "../fetch_util";
import {toIndex} from '../../cmpts/path'
import Util, {getUrlArg} from "../utils";
import {RedirectKey, RedirectPath} from "../path";

let fetchUtil = new FetchUtil();

class SignInCheck extends React.Component {

    constructor(props) {
        super(props);
        this.toSignIn = this.toSignIn.bind(this);
        this.getParams = this.getParams.bind(this);
    }

    toSignIn(rc, path_url) {
        window.location.replace("/signin" + this.getParams());
    }

    componentDidMount() {
        let rc = this.getRc();
        let path_url = this.getPath();
        let toSignIn = this.toSignIn;
        fetchUtil.init()
            .setUrl(URL_LOGIN_STATUS)
            .setMethod('GET')
            .setOvertime(5000)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                var respMsg = msg.respMsg;
                if (msg.respCode == 0 && respMsg.loginStatus == 'ok') {
                    toIndex(rc);
                } else {
                    toSignIn(rc, path_url);
                }
            })
            .catch(() => {
                toSignIn(rc, path_url);
            });
    }

    getParams() {

        let rc = this.getRc();
        let fid = this.getFid();
        let pathUrl = this.getPath();

        let arr = [];
        if (!Util.isNull(rc)) {
            arr.push('r_c=' + rc)
        }

        if (!Util.isNull(fid)) {
            arr.push('fid=' + fid)
        }

        if (!Util.isNull(pathUrl)) {
            arr.push('path_url=' + pathUrl)
        }

        let str = arr.join('&');

        if (arr.length > 0) {
            str = '?' + str;
        }

        return str;

    }

    getPath() {
        let path_url = getUrlArg(RedirectKey);
        if (Util.isNull(path_url)) {
            return null;
        }
        return path_url;
    }

    getRc() {
        let rc = getUrlArg("r_c");
        if (Util.isNull(rc)) {
            return null;
        }
        return rc;
    }

    getFid() {
        let fid = getUrlArg("fid");
        if (Util.isNull(fid)) {
            return null;
        }
        return fid;
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default SignInCheck
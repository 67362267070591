import React from 'react';
import {Redirect, Route} from "react-router-dom";
import Purchase from './purchase/purchase'

import loadable from "../../loadable";

const TaskLIst = loadable(() => import('./task/task_list.bundle'));
const BlackList = loadable(() => import('./black/black_list.bundle'));
const TaskCreate = loadable(() => import('./task/task_create.bundle'));
const TaskGroup = loadable(() => import('./task/task_group_list.bundle'));
const Members = loadable(() => import("./members/members_list.bundle"));
const Count = loadable(() => import("./dataCount/data_count.bundle"));
const Robot = loadable(() => import("./robot/robot_list.bundle"));
const GroupSend = loadable(() => import("./groupSend/group_send.bundle"));
const CreateGroupSend = loadable(() => import("./groupSend/creat_group_send.bundle"));

/**
 * 店铺
 */
class Group extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/task/list"}/>
                )}/>
                <Route path={this.props.match.url + "/task/list"} component={TaskLIst}/>
                <Route path={this.props.match.url + "/black/list"} component={BlackList}/>
                <Route path={this.props.match.url + "/task/create"} render={(props) => {
                    props['type'] = 'create';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/edit/:id"} render={(props) => {
                    props['type'] = 'edit';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/copy/:id"} render={(props) => {
                    props['type'] = 'copy';
                    return <TaskCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/task/group/:id"} component={TaskGroup}/>
                <Route path={this.props.match.url + "/task/members/list"} component={Members}/>
                <Route path={this.props.match.url + "/task/count"} component={Count}/>
                <Route path={this.props.match.url + "/task/robot/list"} component={Robot}/>
                <Route path={this.props.match.url + "/task/send/list"} component={GroupSend}/>
                <Route path={this.props.match.url + "/task/send/create"} component={CreateGroupSend}/>
                <Route path={this.props.match.url + '/purchase'} component={Purchase}/>

            </div>
        );
    }
}

export default Group
import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import "./css/base.css"
import "./css/components.css"
import "./css/icons.css"
import './style/index.less'
import "babel-polyfill";
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import reducer from './cmpts/reducers'
import Promotion from './cmpts/promotion/promotion'
import Fx from './cmpts/promotion/fx'
import Index from './cmpts/layout/index'
import SignUp from './cmpts/signflow/signup'
import SubsAuth from './cmpts/subs_auth/subs_auth'
import FindPwd from './cmpts/signflow/find_pwd'
import ResetPwd from './cmpts/signflow/reset_pwd'
import VipPurchase from './cmpts/vip/vip_pur'
import SignInCheck from "./cmpts/signflow/signinc";
import {FetchUtil} from "./cmpts/fetch_util";
import {URL_LOGIN_STATUS, URL_UEDITOEUPLOAD} from "./cmpts/variables";
import Util from "./cmpts/utils";
import {DragDropContext} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import loadable from "./loadable";

const SignIn = loadable(() => import('./cmpts/signflow/signin.bundle'));
const SignUpAgree = loadable(() => import('./cmpts/signflow/signup_agree.bundle'));
const HelpShow = loadable(() => import('./cmpts/help/help_show.bundle'));
let initState = {};
const store = createStore(reducer, initState);


class App extends React.Component {

    constructor(props) {
        super(props);
        this.setLogin = this.setLogin.bind(this);
        this.state = {
            login: false
        }
    }

    componentDidMount() {
        let url = window.location.href;
        if (url.indexOf("/index") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/tg") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/fx") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/vip") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/signin") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/signinc") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/signup") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/subs_auth") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/findpwd") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/agreement") > 0) {
            this.setLogin(true);
        } else if (url.indexOf("/help") > 0) {
            this.setLogin(true);
        } else {
            this.checkLoginStatus();
        }

        let UE = window.UE;
        UE.Editor.prototype._bkGetActionUrl = UE.Editor.prototype.getActionUrl;
        UE.Editor.prototype.getActionUrl = function (action) {
            if (action == 'uploadimage' || action == 'uploadscrawl' || action == 'uploadimage') {
                return URL_UEDITOEUPLOAD;//此处写自定义的图片上传路径
            } else {
                return this._bkGetActionUrl.call(this, action);
            }
        };

        $('body').on('mouseenter', '.ant-select-dropdown,.ant-cascader-menus', function () {
            Util.disableScroll();
        });
        $('body').on('mousemove', '.ant-select-dropdown,.ant-cascader-menus', function () {
            Util.disableScroll();
        });
        $('body').on('mouseleave', '.ant-select-dropdown,.ant-cascader-menus', function () {
            Util.enableScroll();
        })

    }

    setLogin(login) {
        this.setState({
            login: login
        })
    }

    toHome() {
        window.location.replace('home.html');
    }

    checkLoginStatus() {
        let fetchUtil = new FetchUtil();
        let setLogin = this.setLogin;
        let pathToHome = this.toHome;
        fetchUtil.init()
            .setUrl(URL_LOGIN_STATUS)
            .setMethod('GET')
            .setOvertime(5000)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                var respMsg = msg.respMsg;
                if (msg.respCode == 0 && respMsg.loginStatus == 'ok') {
                    //登陆成功
                    setLogin(true);
                } else {
                    pathToHome();
                }
            })
            .catch(() => {
                pathToHome();
            });
    }

    render() {
        return (
            this.state.login ? <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/tg/:key" component={Promotion}/>
                        <Route exact path="/fx" component={Fx}/>
                        <Route path="/index" component={Index}/>
                        <Route path="/vip" component={VipPurchase}/>
                        <Route path="/signin" component={SignIn}/>
                        <Route path="/signinc" component={SignInCheck}/>
                        <Route path="/signup" component={SignUp}/>
                        <Route path="/agreement" component={SignUpAgree}/>
                        <Route path="/help" component={HelpShow}/>
                        <Route path="/subs_auth/:result?" component={SubsAuth}/>
                        <Route exact path="/findpwd" component={FindPwd}/>
                        <Route exact path="/findpwd/setpwdfromemail" component={ResetPwd}/>
                        <Route exact path="/" render={() => (<Redirect to="/index"/>)}></Route>
                    </Switch>
                </BrowserRouter>
            </Provider> : ""
        );
    }
}

export default DragDropContext(HTML5Backend)(App)
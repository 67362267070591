import React from 'react'

class Pagination extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        var toPage = this.props.toPage;
        var pagInfo = this.props.pagInfo;
        if (pagInfo && pagInfo.pages > 1) {
            $("#paginationWrapper" + this.props.id).show();
            $("#" + this.props.id).bootstrapPaginator({
                bootstrapMajorVersion: 3,
                currentPage: pagInfo.pageNum,
                numberOfPages: 10,
                totalPages: pagInfo.pages,
                onPageChanged: function (event, originalEvent, typePage, currentPage) {
                    if (toPage) {
                        toPage(typePage);
                    }
                }
            })
        } else {
            $("#paginationWrapper" + this.props.id).hide();
        }
    }

    componentWillReceiveProps(nextProps) {
        var toPage = nextProps.toPage;
        var pagInfo = nextProps.pagInfo;
        if (pagInfo && pagInfo.pages > 1) {
            $("#paginationWrapper" + this.props.id).show();
            $("#" + nextProps.id).bootstrapPaginator({
                bootstrapMajorVersion: 3,
                currentPage: pagInfo.pageNum,
                numberOfPages: 10,
                totalPages: pagInfo.pages,
                onPageChanged: function (event, originalEvent, typePage, currentPage) {
                    if (toPage) {
                        toPage(typePage);
                    }
                }
            })
        } else {
            $("#paginationWrapper" + this.props.id).hide();
        }
    }

    render() {

        let wrapper = {
            marginTop: '10px'
        }
        return (
            <div id={"paginationWrapper" + this.props.id} style={wrapper}>
                <span className="pull-right">
                     <ul className="pagination" id={this.props.id}></ul>
                </span>
                <div className="clearfix"></div>
            </div>
        )
    }
}

export default Pagination
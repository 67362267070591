import Util from "./utils";

const DOMAIN = process.env.REACT_APP_DOMAIN;
export function toSignIn(props, rc) {
    props.history.push("/signin" + (Util.isNull(rc) ? '' : ('?r_c=' + rc)));
}

export function toIndex(props) {
    props.history.push("/index");
}

export function toSubsAuth(props, data) {
    var path = "/subs_auth?i=" + encodeURIComponent(JSON.stringify(data));
    props.history.push(path);
}

export let PATH_NAME = {
    subsFission: 'subsFission',
    qunFission: 'qunFission',
    taskposter: 'taskposter',
    message: 'message',
    channel: "channel",
    share: "share",
    home: 'home',
    subsAuth: 'subsAuth',
    tpManage: 'tpManage',
    tpCreate: 'tpCreate',
    tpBlacklist: 'tpBlacklist',
    tmRecord: 'tmRecord',
    tmCreate: 'tmCreate',
    authentication: "Authentication",
    mine: 'mine',
    orderAdd: 'orderAdd',
    orderList: 'orderList',
    myShop: 'myShop',
    goodsList: 'goodsList',
    goodsCreate: 'goodsCreate',
    goodsOrderList: 'goodsOrderList',
    goodsEdit: 'goodsEdit',
    goodsOrderDetail: 'goodsOrderDetail',
};

export let PATH = {
    home: '/index/dashboard',
    subsFission: '/index/ext/subs',
    qunFission: '/index/group/task/list',
    subsAuth: '/index/ext/subs',
    tpManage: '/index/ext/taskpost/record',
    tpCreate: '/index/ext/taskpost/create',
    tpBlacklist: '/index/ext/taskpost/blacklist',
    tpChannel: '/index/ext/taskpost/channel',
    tpChannelCreate: '/index/ext/taskpost/channel_create',
    tpChannelEdit: '/index/ext/taskpost/channel_edit',
    tpChannelDetail: '/index/ext/taskpost/channel_stats/',
    tpStas: '/index/ext/taskpost/stas/0',
    tpEdit: '/index/ext/taskpost/edit',
    tpCopy: '/index/ext/taskpost/copy',
    tpDetail: '/index/ext/taskpost/detail/',
    tmCreate: '/index/ext/msg_template/task/create',
    tmRecord: '/index/ext/msg_template/task/record',
    channelQRList: '/index/ext/channelqrcode/manage/list',
    channelQREdit: '/index/ext/channelqrcode/edit/',
    channelQRStats: '/index/ext/channelqrcode/stats/0',
    channelQRCreate: '/index/ext/channelqrcode/create',
    stManage: '/index/ext/st/record',
    stEdit: '/index/ext/st/edit/',
    stCreate: '/index/ext/st/create',
    stDetail: '/index/ext/st/detail/0',
    fansManage: '/index/ext/fans/manage',
    // formCreate: '/index/ext/mform/create',
    // formEdit: '/index/ext/mform/edit/',
    // formData: '/index/ext/mform/data/',
    formManage: '/index/ext/mform/manage',
    taskpostFormManage: '/index/ext/taskpost/mform/manage',
    redeemCodeManage: '/index/ext/redeemcode/manage',
    taskpostRedeemCodeManage: '/index/ext/taskpost/redeemcode/manage',
    redeemCodeCreate: '/index/ext/redeemcode/create',
    redeemCodeDate: '/index/ext/redeemcode/data/',
    Authentication: '/index/ext/authentication',//认证
    festivalSignList: '/index/ext/festivalsign/list',//节日签
    festivalSignCreate: '/index/ext/festivalsign/create',
    festivalSignEdit: '/index/ext/festivalsign/edit/',
    festivalSignCopy: '/index/ext/festivalsign/copy/',

    // 订单管理
    mine: '/index/mine',
    orderAdd: '/index/mine/order/add',
    orderList: '/index/mine/order/list',
    orderConfirm: '/index/mine/order/confirm',
    orderPay: '/index/mine/order/pay',
    orderDetail: '/index/mine/order/detail',

    //商铺
    myShop: '/index/myshop',
    goodsList: '/index/myshop/goods/list',
    goodsCreate: '/index/myshop/goods/create',
    goodsOrderList: '/index/myshop/order/list',
    goodsOrderChecker: '/index/myshop/order/checker/list',
    goodsOrderCheckerCreate: '/index/myshop/order/checker/create',
    goodsOrderCheckerData: '/index/myshop/order/checker/data',
    goodsOrderDeliver: '/index/myshop/order/deliver',
    goodsEdit: '/index/myshop/goods/edit/',
    goodsOrderDetail: '/index/myshop/order/detail/',


    goodsListTaskpost: '/index/myshop/taskpost/goods/list',
    goodsCreateTaskpost: '/index/myshop/taskpost/goods/create',
    goodsOrderListTaskpost: '/index/myshop/taskpost/order/list',
    goodsOrderDeliverTaskpost: '/index/myshop/taskpost/order/deliver',
    goodsOrderCheckerTaskpost: '/index/myshop/taskpost/order/checker/list',
    goodsEditTaskpost: '/index/myshop/taskpost/goods/edit/',
    goodsOrderDetailTaskpost: '/index/myshop/taskpost/order/detail/',
};
//保存一级菜单包含的页面
let PATH_MAPPER = {
    subsFission: [
        PATH.subsManage,
        PATH.tpManage,
        PATH.tpEdit,
        PATH.tpCreate,
        PATH.tpBlacklist,
        PATH.tpChannel,
        PATH.tpStas,
        PATH.tpCopy,
        PATH.tpDetail,
        PATH.tmCreate,
        PATH.tmRecord,
        PATH.channelQRList,
        PATH.channelQREdit,
        PATH.channelQRStats,
        PATH.channelQRCreate,
        PATH.stManage,
        PATH.stEdit,
        PATH.stCreate,
        PATH.stDetail,
        PATH.fansManage,
        PATH.formCreate,
        PATH.formEdit,
        PATH.formData,
        PATH.formManage,
        PATH.redeemCodeManage,
        PATH.redeemCodeCreate,
        PATH.redeemCodeDate,
        PATH.Authentication
    ],
    qunFission: [],
    mine: [
        PATH.orderList,
        PATH.orderAdd,
        PATH.orderConfirm,
        PATH.orderPay,
        PATH.orderDetail
    ]
};

class Path {

    static isSubsFissionPath(url) {
        if (Util.isNull(url)) {
            url = window.location.href;
        }
        let subsFission = PATH_MAPPER.subsFission;
        for (let i = 0; i < subsFission.length; i++) {
            if (url.endsWith(subsFission[i])) {
                return true;
            }
        }
        return false;
    }

    static isSubsAuth() {
        let url = Path.getUrl();
        if (url.indexOf(Path.getSubsManagePath()) > 0) {
            return true;
        }
        return false;
    }

    static getAgreement() {
        return '/agreement';
    }

    static getHttpHomePath() {
        return DOMAIN + '/home.html';
    }

    static getChannelQRCodeListPath() {
        return '/index/ext/channelqrcode/manage/list';
    }

    static getChannelQRCodeEditPath() {
        return '/index/ext/channelqrcode/edit/';
    }

    static getChannelQRCodeStatsPath() {
        return '/index/ext/channelqrcode/stats/';
    }


    static getCreateChannelQRCodePath() {
        return '/index/ext/channelqrcode/create';
    }

    static getShareTreasureRecordPath() {
        return "/index/ext/st/record"
    }

    static getShareTreasureCreatePath() {
        return "/index/ext/st/create"
    }

    static getShareTreasureEditPath() {
        return "/index/ext/st/edit/"
    }

    static getShareTreasureDetailPath() {
        return "/index/ext/st/detail/"
    }

    static getCreateTaskposterPath() {
        return "/index/ext/taskpost/create"
    }

    static getBlacklistTaskposterPath() {
        return "/index/ext/taskpost/blacklist"
    }

    static getChannelTaskposterPath() {
        return "/index/ext/taskpost/channel"
    }

    static getCopyTaskposterPath() {
        return "/index/ext/taskpost/copy"
    }

    static getEditTaskposterPath() {
        return "/index/ext/taskpost/edit"
    }

    static getTaskposterDetailPath() {
        return "/index/ext/taskpost/detail/"
    }

    static getTaskposterStasPath() {
        return "/index/ext/taskpost/stas/"
    }

    static getTaskposterFansPath() {
        return "/index/ext/taskpost/fans/"
    }

    static getTaskposterChannelPath() {
        return "/index/ext/taskpost/channel/"
    }

    static getTaskposterChannelCreatePath() {
        return "/index/ext/taskpost/channel_create"
    }

    static getTaskposterChannelEditPath() {
        return '/index/ext/taskpost/channel_edit'
    }

    static getCreatePushMsgTaskPath() {
        return "/index/ext/msg_template/task/create/"
    }

    static getTemplateMsgManagePath() {
        return "/index/ext/msg_template/manage"
    }

    static getCreateMsgPath() {
        return "/index/ext/msg_template/create"
    }

    static getEditMsgPath() {
        return "/index/ext/msg_template/edit"
    }

    static getMsgTemplatePath() {
        return "/index/ext/msg_template/manage/my_template"
    }

    static getMsgTaskRecordPath() {
        return "/index/ext/msg_template/task/record"
    }

    static getTaskPosterRecordPath() {
        return "/index/ext/taskpost/record"
    }

    static getSigninHttpPath() {
        return DOMAIN + "/signin";
        // return "http://localhost/signin";
    }

    static getHomeHttpPath() {
        return DOMAIN + "/signin";
        // return "http://localhost/signin";
    }

    static getSigninPath() {
        return "/signin";
    }

    static getSignUpPath() {
        return "/signup";
    }

    static getExtPath() {
        return '/index/ext';
    }

    static getHomePath() {
        return PATH.home;
    }

    static getFansPath() {
        return "/index/ext/fans/manage"
    }

    static getVipPurPath() {
        return "/vip"
    }

    static getCreateFormPath() {
        return "/index/ext/mform/create"
    }

    static getEditFormPath() {
        return "/index/ext/mform/edit/"
    }

    static getCopyFormPath() {
        return "/index/ext/mform/copy/"
    }

    static getFormDataPath() {
        return "/index/ext/mform/data/"
    }

    static getManageFormPath() {
        return "/index/ext/mform/manage"
    }

    static getFormLookPath() {
        return "/index/ext/mform/look/"
    }

    static getTaskpostCreateFormPath() {
        return "/index/ext/taskpost/mform/create"
    }

    static getTaskpostEditFormPath() {
        return "/index/ext/taskpost/mform/edit/"
    }

    static getTaskpostFormDataPath() {
        return "/index/ext/taskpost/mform/data/"
    }

    static getTaskpostManageFormPath() {
        return "/index/ext/taskpost/mform/manage"
    }

    static getTaskpostFormLookPath() {
        return "/index/ext/taskpost/mform/look/"
    }


    static getCorpCreateFormPath() {
        return "/index/corp/mform/create"
    }

    static getCorpEditFormPath() {
        return "/index/corp/mform/edit/"
    }

    static getCorpFormDataPath() {
        return "/index/corp/mform/data/"
    }

    static getCorpManageFormPath() {
        return "/index/corp/mform/manage"
    }

    static getCorpFormLookPath() {
        return "/index/corp/mform/look/"
    }


    static getManageRedeemcodePath() {
        return "/index/ext/redeemcode/manage"
    }

    static getCreateRedeemcodePath() {
        return "/index/ext/redeemcode/create"
    }

    static getRedeemcodeDataPath() {
        return "/index/ext/redeemcode/data/"
    }

    static getRedeemcodeCreatePath() {
        return "/index/ext/redeemcode/create"
    }


    static getTaskpostManageRedeemcodePath() {
        return "/index/ext/taskpost/redeemcode/manage"
    }

    static getTaskpostCreateRedeemcodePath() {
        return "/index/ext/taskpost/redeemcode/create"
    }

    static getTaskpostRedeemcodeDataPath() {
        return "/index/ext/taskpost/redeemcode/data/"
    }

    static getTaskpostRedeemcodeCreatePath() {
        return "/index/ext/taskpost/redeemcode/create"
    }


    static getCorpManageRedeemcodePath() {
        return "/index/corp/redeemcode/manage"
    }

    static getCorpCreateRedeemcodePath() {
        return "/index/corp/redeemcode/create"
    }

    static getCorpRedeemcodeDataPath() {
        return "/index/corp/redeemcode/data/"
    }

    static getCorpRedeemcodeCreatePath() {
        return "/index/corp/redeemcode/create"
    }


    static getSubsManagePath() {
        return "/index/ext/subs"
    }

    static getAuthenticationPath() {
        return "/index/ext/authentication"
    }

    static getMine() {
        return "/index/mine"
    }

    static getOrderList() {
        return "/index/mine/order/list"
    }

    static getOrderAdd() {
        // return "/index/mine/order/add"
        return '/index/mine/purchase/recharge'
    }

    static getOrderRenew() {
        return "/index/mine/order/renew"
    }

    static getOrderConfirm() {
        return "/index/mine/order/confirm"
    }

    static getOrderPay() {
        return "/index/mine/order/pay"
    }

    static getOrderDetail() {
        return "/index/mine/order/detail"
    }

    static getGoodsCreate() {
        return "/index/myshop/goods/create"
    }

    static getGoodsList() {
        return "/index/myshop/goods/list"
    }

    static getGoodsEdit() {
        return "/index/myshop/goods/edit/"
    }

    static getGoodsOrderList() {
        return "/index/myshop/order/list"
    }


    static getGoodsOrderDetail() {
        return "/index/myshop/order/detail/"
    }


    static getNoticeList() {
        return "/index/notice"
    }

    static getPurchase() {
        return "/index/purchase"
    }

    static getGroupTaskList() {
        return '/index/group/task/list'
    }

    static getTaskGroupList() {
        return '/index/group/task/group/'
    }

    static getGroupTaskCreate() {
        return '/index/group/task/create'
    }

    static getGroupTaskEdit() {
        return '/index/group/task/edit/'
    }

    static getGroupTaskCopy() {
        return '/index/group/task/copy/'
    }

    static getGroupPurchase() {
        return '/index/group/purchase/recharge'
    }

    static getGroupPurchasePay() {
        return '/index/group/purchase/pay'
    }

    static getGroupPurchasePayResult() {
        return '/index/group/purchase/result'
    }

    static getGroupPurchaseRecord() {
        return '/index/group/purchase/record'
    }

    static getGroupPurchaseRecordDe() {
        return '/index/group/purchase/detail'
    }

    static getGroupPurchaseStream() {
        return '/index/group/purchase/stream'
    }

    static getGroupPurchaseAccount() {
        return '/index/group/purchase/account'
    }


    static getGroupBlackList() {
        return '/index/group/black/list'
    }

    static getGroupMembers() {
        return '/index/group/task/members/list'
    }

    static getGroupDataCount() {
        return '/index/group/task/count'
    }

    static getGroupRobotList() {
        return '/index/group/task/robot/list'
    }

    static getGroupSendList() {
        return '/index/group/task/send/list'
    }

    static getGroupCreateSend() {
        return '/index/group/task/send/create'
    }

    static getDis() {
        return '/index/distribution'
    }

    static getDisRetailList() {
        return '/index/distribution/retail/list'
    }

    static getDisRetailCreate() {
        return '/index/distribution/retail/create'
    }

    static getDisRetailEdit() {
        return '/index/distribution/retail/edit'
    }

    static getDisRetailCopy() {
        return '/index/distribution/retail/copy'
    }

    static getDisRetailData() {
        return '/index/distribution/retail/data'
    }

    static getDisRetailRank() {
        return '/index/distribution/retail/rank'
    }

    static getDisRetailReport() {
        return '/index/distribution/retail/report'
    }

    static getDisRetailCode() {
        return '/index/distribution/retail/code'
    }

    static getDisCashList() {
        return '/index/distribution/cash/record'
    }

    static getDisCashOut() {
        return '/index/distribution/cash/out'
    }

    static getDisPushase() {
        return '/index/mine/purchase/recharge'
    }

    static getWriteOffList() {
        return '/index/distribution/writeoff/list'
    }

    static getWriteOffCreate() {
        return '/index/distribution/writeoff/create'
    }

    static getWriteOffData() {
        return '/index/distribution/writeoff/data'
    }

    static getDisChannelList() {
        return '/index/distribution/channel/list'
    }

    static getDisMsg() {
        return '/index/distribution/msg/list'
    }

    static getDisMsgAdd() {
        return '/index/distribution/msg/create'
    }

    static getDisMsgEdit() {
        return '/index/distribution/msg/edit'
    }

    static getDisChannelCreate() {
        return '/index/distribution/channel/create'
    }

    static getDisChannelEdit() {
        return '/index/distribution/channel/edit'
    }

    static getDisRetailShop() {
        return '/index/distribution/retail/shop'
    }

    static getDisRetailShopEdit() {
        return '/index/distribution/retail/shop/edit'
    }

    static getPushList() {
        return '/index/ext/msg/push/list'
    }

    static getPushCreate() {
        return '/index/ext/msg/push/create'
    }

    static getPushEdit() {
        return '/index/ext/msg/push/edit'
    }

    static getPushCopy() {
        return '/index/ext/msg/push/copy'
    }

    static getGroupBuy() {
        return '/index/groupbuy'
    }

    static getGroupBuyList() {
        return '/index/groupbuy/list'
    }

    static getGroupBuyMsg() {
        return '/index/groupbuy/msg'
    }

    static getGroupBuyMsgAdd() {
        return '/index/groupbuy/msg/create'
    }

    static getGroupBuyMsgEdit() {
        return '/index/groupbuy/msg/edit'
    }

    static getGroupBuyCreat() {
        return '/index/groupbuy/create'
    }

    static getGroupBuyEdit() {
        return '/index/groupbuy/edit'
    }

    static getGroupBuyCopy() {
        return '/index/groupbuy/copy'
    }

    static getGroupBuyCash() {
        return '/index/groupbuy/cash/list'
    }

    static getGroupBuyCashOut() {
        return '/index/groupbuy/cash/out'
    }

    static getGroupBuyOrder() {
        return '/index/groupbuy/order/list'
    }

    static getGroupBuyOrderDetail() {
        return '/index/groupbuy/order/detail'
    }

    static getGroupBuyRedeemCode() {
        return '/index/groupbuy/code'
    }

    static getLiveCodeGroupList() {
        return '/index/livecode/group/list'
    }

    static getLiveCodeGroupCreate() {
        return '/index/livecode/group/create'
    }

    static getLiveCodeGroupEdit() {
        return '/index/livecode/group/edit'
    }

    static getLiveCodeGroupCopy() {
        return '/index/livecode/group/copy'
    }

    static getLiveCodeGroupQrcode() {
        return '/index/livecode/group/qrcode'
    }

    static getLiveCodeCustomerList() {
        return '/index/livecode/customer/list'
    }

    static getLiveCodePurchase() {
        return '/index/livecode/purchase'
    }

    static getLiveCodePurchaseConfirm() {
        return '/index/livecode/purchase/confirm'
    }

    static getLiveCodePay() {
        return '/index/livecode/purchase/pay'
    }

    static getLiveCodePayConfirm() {
        return '/index/livecode/purchase/confirm'
    }

    static getLiveCodePayResult() {
        return '/index/livecode/purchase/result'
    }

    static getLiveCodeCustomerCreate() {
        return '/index/livecode/customer/create'
    }

    static getLiveCodeCustomerEdit() {
        return '/index/livecode/customer/edit'
    }

    static getLiveCodeCustomerCopy() {
        return '/index/livecode/customer/copy'
    }

    static getLiveCodeCustomerQrcode() {
        return '/index/livecode/customer/qrcode'
    }

    static getLiveCustomCodeDataStat() {
        return '/index/livecode/customer/stat'
    }

    static getLiveGroupCodeDataStat() {
        return '/index/livecode/group/stat'
    }

    static getWXCreate() {
        return '/index/ext/wxmenu/create'
    }

    static getWXIndividuationList() {
        return '/index/ext/wxmenu/individuation/list'
    }

    static getWXIndividuationCreate() {
        return '/index/ext/wxmenu/individuation/create'
    }


    static getWXAutoReplay() {
        return '/index/ext/wxmenu/auto'
    }


    static getTpTags() {
        return '/index/ext/taskpost/tag/list'
    }

    static getTpTagsCreate() {
        return '/index/ext/taskpost/tag/create'
    }

    static getTpTagsEdit() {
        return '/index/ext/taskpost/tag/edit'
    }

    static getTpSop() {
        return '/index/ext/taskpost/sop/list'
    }

    static getTpSopCreate() {
        return '/index/ext/taskpost/sop/create'
    }

    static getTpSopEdit() {
        return '/index/ext/taskpost/sop/edit'
    }

    static getMinePurchase() {
        return '/index/mine/purchase/recharge'
    }

    static getMineProductInfo() {
        return '/index/mine/purchase/productinfo'
    }

    static getMinePay() {
        return '/index/mine/purchase/pay'
    }

    static getMineConfirm() {
        return '/index/mine/purchase/confirm'
    }

    static getMineResult() {
        return '/index/mine/purchase/result'
    }

    static getMineOrderList() {
        return '/index/mine/purchase/record'
    }

    static getMineOrderDetail() {
        return '/index/mine/purchase/detail'
    }


    // 企业微信
    static getCorpTaskList() {
        return '/index/corp/task/list'
    }

    static getCorpTaskAuth() {
        return '/index/corp/auth/list'
    }

    static getCorpTaskEWM() {
        return '/index/corp/auth/ewm'
    }

    static getCorpMemberList() {
        return '/index/corp/member/list'
    }

    static getCorpTaskFans() {
        return '/index/corp/task/fans/'
    }

    static getCorpTaskCreate() {
        return '/index/corp/task/create'
    }

    static getCorpTaskCopy() {
        return '/index/corp/task/copy/'
    }

    static getCorpTaskEdit() {
        return '/index/corp/task/edit/'
    }

    static getCorpData() {
        return '/index/corp/data/'
    }


    static getCorpFans() {
        return '/index/corp/task/fans/'
    }

    /*物流查询*/
    static getLogisticsList() {
        return '/index/logistics/list'
    }

    static getLogisticsCreate() {
        return '/index/logistics/create'
    }

    static getLogisticsEdit() {
        return '/index/logistics/edit/'
    }

    static getLogisticsLook() {
        return '/index/logistics/look/'
    }

    /*客户裂变物流查询*/
    static getCorpLogisticsList() {
        return '/index/corp/logistics/list'
    }

    /*任务宝物流查询*/
    static getTaskpostLogisticsList() {
        return '/index/ext/logistics/list'
    }

    static isTaskpostLogistics() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTaskpostLogisticsList()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpLogistics() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpLogisticsList()) >= 0) {
            return true;
        }
        return false;
    }

    static isLogistics() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLogisticsList()) >= 0) {
            return true;
        } else if (url.indexOf(Path.getLogisticsCreate()) >= 0) {
            return true;
        } else if (url.indexOf(Path.getLogisticsEdit()) >= 0) {
            return true;
        } else if (url.indexOf(Path.getLogisticsLook()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpData() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpData()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpFans() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpFans()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpMemberList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpMemberList()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpMemberWx() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpTaskAuth()) >= 0) {
            return true;
        }
        return false;
    }

    static isCorpTask() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getCorpTaskList()) >= 0) {
            return true;
        } else if (url.indexOf(Path.getCorpTaskEWM()) >= 0) {
            return true
        } else if (url.indexOf(Path.getCorpTaskFans()) >= 0) {
            return true
        } else if (url.indexOf(Path.getCorpTaskCreate()) >= 0) {
            return true
        } else if (url.indexOf(Path.getCorpTaskEdit()) >= 0) {
            return true
        } else if (url.indexOf(Path.getCorpTaskCopy()) >= 0) {
            return true
        }
        return false;
    }

    static isMinePurchase() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getMinePurchase()) > 0) {
            return true;
        } else if (url.indexOf(Path.getMinePay()) > 0) {
            return true;
        } else if (url.indexOf(Path.getMineConfirm()) > 0) {
            return true;
        } else if (url.indexOf(Path.getMineResult()) > 0) {
            return true;
        }
        return false;
    }

    static isMineProductInfo() {
        let url = Path.getUrl();
        if (url.indexOf(Path.getMineProductInfo()) > 0) {
            return true;
        }
        return false;
    }

    static getHelpShow() {
        return '/help'
    }

    static isTpTags() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTpTags()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTpTagsCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTpTagsEdit()) > 0) {
            return true;
        }
        return false;
    }

    static isTpSop() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTpSop()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTpSopCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTpSopEdit()) > 0) {
            return true;
        }
        return false;
    }


    static isWxCreate() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getWXCreate()) > 0) {
            return true;
        }
        return false;
    }

    static isWXIndCreate() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getWXIndividuationCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getWXIndividuationList()) > 0) {
            return true;
        }
        return false;
    }

    static isWXAutoReplay() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getWXAutoReplay()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodeCustomerStat() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveCustomCodeDataStat()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodeGroupStat() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveGroupCodeDataStat()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodeCustomer() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveCodeCustomerList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeCustomerCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeCustomerEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeCustomerCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeCustomerQrcode()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodePurchase() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveCodePurchase()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodePurchaseConfirm()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodePay()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodePayConfirm()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodePayResult()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodeGroup() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveCodeGroupList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupQrcode()) > 0) {
            return true;
        }
        return false;
    }

    static isLiveCodeGroupAdd() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getLiveCodeGroupCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getLiveCodeGroupCopy()) > 0) {
            return true;
        }
        return false;
    }


    static isGroupBuy() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBuy()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupBuyList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBuyList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyCreat()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyRedeemCode()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupBuyCash() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBuyCash()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyCashOut()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupBuyOrder() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBuyOrder()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyOrderDetail()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupBuyMsg() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBuyMsg()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyMsgAdd()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupBuyMsgEdit()) > 0) {
            return true;
        }
        return false;
    }

    static isPush() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getPushList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getPushCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getPushCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getPushEdit()) > 0) {
            return true;
        }
        return false;
    }

    static isDis() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDis()) > 0) {
            return true;
        }

        return false;
    }

    static isDisRetail() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisRetailList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailData()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailRank()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailCode()) > 0) {
            return true;
        }
        return false;
    }

    static isDisCash() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisCashList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisCashOut()) > 0) {
            return true;
        }

        return false;
    }

    static isDisPurchase() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisPushase()) > 0) {
            return true;
        }

        return false;
    }

    static isWriteOff() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getWriteOffList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getWriteOffCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getWriteOffData()) > 0) {
            return true;
        }

        return false;
    }

    static isWriteOffData() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getWriteOffData()) > 0) {
            return true;
        }

        return false;
    }

    static isDisChannel() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisChannelList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisChannelCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisChannelEdit()) > 0) {
            return true;
        }

        return false;
    }

    static isDisMsg() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisMsg()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisMsgAdd()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisMsgEdit()) > 0) {
            return true;
        }

        return false;
    }


    static isDisRetailReport() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisRetailReport()) > 0) {
            return true;
        }
        return false;
    }

    static isDisRetailShop() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getDisRetailShop()) > 0) {
            return true;
        } else if (url.indexOf(Path.getDisRetailShopEdit()) > 0) {
            return true;
        }
        return false;
    }


    static isInTemplateMsg(url) {
        if (url) {
            if (url.indexOf('/ext/msg_template/') != -1) {
                return true;
            }
        } else if (window.location.href.indexOf('/ext/msg_template/') != -1) {
            return true;
        }
        return false;
    }

    static isInTaskpost(url) {
        if (url) {
            if (url.indexOf('/ext/taskpost/') != -1) {
                return true;
            }
        } else if (window.location.href.indexOf('/ext/taskpost/') != -1) {
            return true;
        }
        return false;
    }

    static isInTaskpostCreate() {
        var url = Path.getUrl();
        if (url.indexOf(Path.isTpManage()) > 0) {
            return true;
        }
        return false;
    }

    static isInShareTreasure(url) {
        if (url) {
            if (url.indexOf('/ext/st/') != -1) {
                return true;
            }
        } else if (window.location.href.indexOf('/ext/st/') != -1) {
            return true;
        }
        return false;
    }

    static isInChannelQRCode(url) {
        if (url) {
            if (url.indexOf('/ext/channelqrcode/') != -1) {
                return true;
            }
        } else if (window.location.href.indexOf('/ext/channelqrcode/') != -1) {
            return true;
        }
        return false;
    }

    static getUrl() {
        var url = window.location.href;
        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }
        return url;
    }

    static isHome() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getHomePath()) > 0) {
            return true;
        }
        return false;
    }

    //是否是公众号涨粉
    static isExt() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getExtPath()) > 0) {
            return true;
        }
        return false;
    }

    static isMyShop() {
        var url = Path.getUrl();
        if (url.indexOf(PATH.myShop) > 0) {
            return true;
        }
        return false;
    }

    static isGoodsList() {
        var url = Path.getUrl();
        if (url.indexOf(PATH.goodsList) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsCreate) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsEdit) > 0) {
            return true;
        }

        return false;
    }


    static isTaskPostGoodsList() {
        var url = Path.getUrl();
        if (url.indexOf(PATH.goodsListTaskpost) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsCreateTaskpost) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsEditTaskpost) > 0) {
            return true;
        }

        return false;
    }

    static isGoodsOrderList() {
        var url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderList) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsOrderDetail) > 0) {
            return true
        }
        return false;
    }

    static isGoodsOrderChecker() {
        let url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderChecker) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsOrderCheckerCreate) > 0) {
            return true
        } else if (url.indexOf(PATH.goodsOrderCheckerData) > 0) {
            return true
        }
        return false;
    }

    static isGoodsOrderDeliver() {
        let url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderDeliver) > 0) {
            return true;
        }
        return false;
    }

    static isTaskPostGoodsOrderList() {
        var url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderListTaskpost) > 0) {
            return true;
        } else if (url.indexOf(PATH.goodsOrderDetailTaskpost) > 0) {
            return true
        }
        return false;
    }

    static isTaskPostGoodsOrderDeliver() {
        let url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderDeliverTaskpost) > 0) {
            return true;
        }
        return false;
    }

    static isTaskPostGoodsOrderChecker() {
        let url = Path.getUrl();
        if (url.indexOf(PATH.goodsOrderCheckerTaskpost) > 0) {
            return true;
        }
        return false;
    }

    static isTMCreatePush() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCreatePushMsgTaskPath())) {
            return true;
        }
        return false;
    }

    static isTMPushRecord() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getMsgTaskRecordPath())) {
            return true;
        } else if (url.indexOf(PATH.tmCreate) > 0) {
            return true
        }
        return false;
    }

    static isTMManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getTemplateMsgManagePath())) {
            return true;
        } else if (url.indexOf(Path.getCreateMsgPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getEditMsgPath()) != -1) {
            return true;
        }
        return false;
    }

    static isTpManage() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTaskPosterRecordPath()) > 0) {
            return true;
        } else if (url.indexOf(Path.getEditTaskposterPath()) > 0) {
            return true;
        } else if (url.indexOf(Path.getCopyTaskposterPath()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTaskposterDetailPath()) > 0) {
            return true;
        } else if (url.indexOf(Path.getCreateTaskposterPath()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTaskposterFansPath()) != -1) {
            return true;
        }

        return false;
    }

    static isFestivalSignManage() {
        let url = Path.getUrl();
        if (url.indexOf(Path.getFestivalSignList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getFestivalSignCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getFestivalSignEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getFestivalSignCopy()) > 0) {
            return true;
        }
        return false;
    }

    static isTpCreate() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCreateTaskposterPath())) {
            return true;
        }
        return false;
    }


    static isCodeManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getChannelQRCodeListPath())) {
            return true;
        } else if (url.indexOf(Path.getChannelQRCodeEditPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getChannelQRCodeStatsPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCreateChannelQRCodePath()) != -1) {
            return true;
        }
        return false;
    }

    static isCodeDetail() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getChannelQRCodeStatsPath()) != -1) {
            return true;
        }
        return false;
    }


    static isCodeCreate() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCreateChannelQRCodePath())) {
            return true;
        }
        return false;
    }

    static isStManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getShareTreasureRecordPath())) {
            return true;
        } else if (url.indexOf(Path.getShareTreasureEditPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getShareTreasureCreatePath()) != -1) {
            return true;
        }
        return false;
    }

    static isStDetail() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getShareTreasureDetailPath()) != -1) {
            return true;
        }
        return false;
    }

    static isStCreate() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getShareTreasureCreatePath())) {
            return true;
        }
        return false;
    }

    static isFansManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getFansPath())) {
            return true;
        }
        return false;
    }

    static isFormCreate() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCreateFormPath())) {
            return true;
        }
        return false;
    }

    static isTaskpostFormManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getTaskpostManageFormPath())) {
            return true;
        } else if (url.indexOf(Path.getTaskpostFormDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getTaskpostCreateFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getTaskpostEditFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getTaskpostFormLookPath()) != -1) {
            return true;
        }
        return false;
    }

    static isCorpFormManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCorpManageFormPath())) {
            return true;
        } else if (url.indexOf(Path.getCorpFormDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCorpCreateFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCorpEditFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCorpFormLookPath()) != -1) {
            return true;
        }
        return false;
    }


    static isFormManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getManageFormPath())) {
            return true;
        } else if (url.indexOf(Path.getFormDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCreateFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getEditFormPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getFormLookPath()) != -1) {
            return true;
        }
        return false;
    }

    static isTpBlacklist() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getBlacklistTaskposterPath())) {
            return true;
        }
        return false;
    }

    static isTpChannel() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getChannelTaskposterPath())) {
            return true;
        } else if (url.indexOf(Path.getTaskposterChannelCreatePath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getTaskposterChannelEditPath()) != -1) {
            return true;
        }
        return false;
    }

    static isTpStas() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTaskposterStasPath()) != -1) {
            return true;
        }
        return false;
    }

    static isTpFans() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getTaskposterFansPath()) != -1) {
            return true;
        }
        return false;
    }

    static isTaskpostRedeemcodeManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getTaskpostManageRedeemcodePath())) {
            return true;
        } else if (url.indexOf(Path.getTaskpostRedeemcodeDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getTaskpostRedeemcodeCreatePath()) != -1) {
            return true;
        }
        return false;
    }

    static isCorpRedeemcodeManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCorpManageRedeemcodePath())) {
            return true;
        } else if (url.indexOf(Path.getCorpRedeemcodeDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getCorpRedeemcodeCreatePath()) != -1) {
            return true;
        }
        return false;
    }


    static isRedeemcodeManage() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getManageRedeemcodePath())) {
            return true;
        } else if (url.indexOf(Path.getRedeemcodeDataPath()) != -1) {
            return true;
        } else if (url.indexOf(Path.getRedeemcodeCreatePath()) != -1) {
            return true;
        }
        return false;
    }

    static isRedeemcodeCreate() {
        var url = Path.getUrl();
        if (url.endsWith(Path.getCreateRedeemcodePath())) {
            return true;
        }
        return false;
    }

    // 是否是我的
    static isMine() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getMine()) > 0) {
            return true;
        }
        return false;
    }

    static isOrderList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getOrderList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getOrderDetail()) > 0) {
            return true;
        } else if (url.indexOf(Path.getMineOrderList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getMineOrderDetail()) > 0) {
            return true;
        }
        return false;
    }

    static isOrderAdd() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getOrderAdd()) > 0) {
            return true;
        } else if (url.indexOf(Path.getOrderConfirm()) > 0) {
            return true;
        } else if (url.indexOf(Path.getOrderPay()) > 0) {
            return true;
        } else if (url.indexOf(Path.getOrderRenew()) > 0) {
            return true;
        }
        return false;
    }

    // 建群
    static isGroupTaskList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupTaskList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupTaskCreate()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupTaskEdit()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupTaskCopy()) > 0) {
            return true;
        } else if (url.indexOf(Path.getTaskGroupList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupMembers()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupPurchase() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupPurchase()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchasePay()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchaseStream()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchaseAccount()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchasePayResult()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchaseRecord()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupPurchaseRecordDe()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupTaskBlackList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupBlackList()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupDataCount() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupDataCount()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupRobotList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupRobotList()) > 0) {
            return true;
        }
        return false;
    }

    static isGroupSendList() {
        var url = Path.getUrl();
        if (url.indexOf(Path.getGroupSendList()) > 0) {
            return true;
        } else if (url.indexOf(Path.getGroupCreateSend()) > 0) {
            return true
        }
        return false;
    }

    static getFestivalSignList() {
        return PATH.festivalSignList;
    }

    static getFestivalSignCreate() {
        return PATH.festivalSignCreate;
    }

    static getFestivalSignEdit() {
        return PATH.festivalSignEdit;
    }

    static getFestivalSignCopy() {
        return PATH.festivalSignCopy;
    }
}

export {Path}


// 根据路由直接跳转页面
export let RedirectKey = 'path_url';
export let RedirectPath = {
    'distri': Path.getDisRetailList(),
    'groupbuy': Path.getGroupBuyList(),
    'livecode': Path.getLiveCodeGroupList(),
    'livecode_kf': Path.getLiveCodeCustomerList(),
    'taskposter': Path.getTaskPosterRecordPath(),
    'msg': Path.getMsgTaskRecordPath()
};
import React from 'react';
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import Push from './msg/push/push'

import loadable from "../../loadable";

const ChannelQRCodeCreate = loadable(() => import("./channel_qrcdoe/qrcode_create.bundle"));
const ChannelQRCodeEdit = loadable(() => import("./channel_qrcdoe/qrcode_edit.bundle"));
const ChannelQRCodeMange = loadable(() => import("./channel_qrcdoe/qrcode_manage.bundle"));
const ChannelQRCodeStats = loadable(() => import("./channel_qrcdoe/qrcode_stats.bundle"));
const ShareTreasureCreate = loadable(() => import("./st/st_create.bundle"));
const ShareTreasureRecord = loadable(() => import("./st/st_record.bundle"));
const ShareTreasureEdit = loadable(() => import("./st/st_edit.bundle"));
const ShareTreasureDetail = loadable(() => import("./st/st_detail.bundle"));
const MsgManage = loadable(() => import("./msg/old/msg_manage.bundle"));
const MsgCreate = loadable(() => import("./msg/old/msg_create.bundle"));
const MsgEdit = loadable(() => import("./msg/msg_edit.bundle"));
const MsgTaskCreate = loadable(() => import("./msg/msg_task_create.bundle"));
const MsgTaskRecord = loadable(() => import("./msg/msg_task_record.bundle"));
const FansManage = loadable(() => import("./fans/fans_manage.bundle"));
const TaskpostRecord = loadable(() => import("./taskpost/taskpost_record.bundle"));
const TaskpostRecord2 = loadable(() => import("./taskpost/taskpost_record_new.bundle"));
const Taskposter = loadable(() => import("./taskpost/matrix/taskposter.bundle"));
const TaskpostDetail = loadable(() => import("./taskpost/taskpost_detail.bundle"));
const FormCreate = loadable(() => import("./form/form_create_new.bundle"));
const FormManage = loadable(() => import("./form/form_manage.bundle"));
const FormEdit = loadable(() => import("./form/form_create_new.bundle"));
const FormData = loadable(() => import('./form/form_data.bundle'));
const FormLook = loadable(() => import('./form/look_list.bundle'));
const RedeemcodeManage = loadable(() => import('./redeemcode/redeemcode_manage.bundle'));
const RedeemcodeCreate = loadable(() => import('./redeemcode/redeemcode_create.bundle'));
const RedeemcodeData = loadable(() => import('./redeemcode/redeemcode_data.bundle'));
const Subs = loadable(() => import('./subs.bundle'));
const BlackList = loadable(() => import('./taskpost/blacklist.bundle'));
const TaskpostChannel = loadable(() => import('./taskpost/taskpost_channel.bundle'));
const TaskpostChannelCreate = loadable(() => import('./taskpost/taskpost_channel_create.bundle'));
const TaskpostChannelEdit = loadable(() => import('./taskpost/taskpost_channel_edit.bundle'));
const TaskpostStas = loadable(() => import('./taskpost/taskpost_stas.bundle'));
const TaskpostFans = loadable(() => import('./taskpost/activefan.bundle'));
const wxCreate = loadable(() => import('./wxmenu/wxCreate/wxCreate.bundle'));
const WXIndividuationCreate = loadable(() => import('./wxmenu/Individuation/create.bundle'));
const WXIndividuationList = loadable(() => import('./wxmenu/Individuation/list.bundle'));
const WXAutoCreate = loadable(() => import('./wxmenu/autoreply/autoreplay.bundle'));
const TaskpostTag = loadable(() => import('./taskpost/tags/tag_list.bundle'));
const TaskpostTagCreate = loadable(() => import('./taskpost/tags/tag_creat.bundle'));
const TaskpostSop = loadable(() => import('./taskpost/sop/sop_list.bundle'));
const TaskpostSopCreate = loadable(() => import('./taskpost/sop/sop_create.bundle'));
const LogisticsList = loadable(() => import("../logistics/list/list.bundle"));
const FestivalSignActivityList = loadable(() => import("./festival/activity_list.bundle"));
const FestivalSignActivityCreate = loadable(() => import("./festival/activity_create.bundle"));

class Ext extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route path={this.props.match.url + "/subs"} component={Subs}/>
                <Route path={this.props.match.url + "/channelqrcode/create"} component={ChannelQRCodeCreate}/>
                <Route path={this.props.match.url + "/channelqrcode/edit/:id"} component={ChannelQRCodeEdit}/>
                <Route path={this.props.match.url + "/channelqrcode/stats/:id"} component={ChannelQRCodeStats}/>
                <Route path={this.props.match.url + "/channelqrcode/manage"} component={ChannelQRCodeMange}/>
                <Route path={this.props.match.url + "/st/create"} component={ShareTreasureCreate}/>
                <Route path={this.props.match.url + "/st/record"} component={ShareTreasureRecord}/>
                <Route path={this.props.match.url + "/st/edit/:id"} component={ShareTreasureEdit}/>
                <Route path={this.props.match.url + "/st/detail/:id"} component={ShareTreasureDetail}/>
                <Route path={this.props.match.url + "/msg_template/manage"} component={MsgManage}/>
                <Route path={this.props.match.url + "/msg_template/create"} component={MsgCreate}/>
                <Route path={this.props.match.url + "/msg_template/edit"} component={MsgEdit}/>
                <Route path={this.props.match.url + "/msg_template/task/create/:id"} component={MsgTaskCreate}/>
                <Route path={this.props.match.url + "/msg_template/task/record"} component={MsgTaskRecord}/>
                <Route path={this.props.match.url + "/fans/manage"} component={FansManage}/>
                <Route path={this.props.match.url + "/taskpost/record"} component={TaskpostRecord}/>
                <Route path={this.props.match.url + "/taskpost/record2"} component={TaskpostRecord2}/>
                <Route path={this.props.match.url + "/taskpost/create"}
                       render={(props) => {
                           props['editType'] = 'create';
                           return <Taskposter {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/edit/:id"}
                       render={(props) => {
                           props['editType'] = 'edit';
                           return <Taskposter {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/copy/:id"}
                       render={(props) => {
                           props['editType'] = 'copy';
                           return <Taskposter {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/detail/:id"} component={TaskpostDetail}/>
                <Route path={this.props.match.url + "/mform/create"} render={(props) => {
                    props.type = 'create';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/mform/manage"} component={FormManage}/>
                <Route path={this.props.match.url + "/mform/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/mform/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/mform/data/:id"} component={FormData}/>
                <Route path={this.props.match.url + "/mform/look/:id"} component={FormLook}/>


                <Route path={this.props.match.url + "/taskpost/mform/create"} render={(props) => {
                    props.type = 'create';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/taskpost/mform/manage"} component={FormManage}/>
                <Route path={this.props.match.url + "/taskpost/mform/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/taskpost/mform/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <FormCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/taskpost/mform/data/:id"} component={FormData}/>
                <Route path={this.props.match.url + "/taskpost/mform/look/:id"} component={FormLook}/>


                <Route path={this.props.match.url + "/redeemcode/manage"} component={RedeemcodeManage}/>
                <Route path={this.props.match.url + "/redeemcode/create"} component={RedeemcodeCreate}/>
                <Route path={this.props.match.url + "/redeemcode/data/:id"} component={RedeemcodeData}/>


                <Route path={this.props.match.url + "/taskpost/redeemcode/manage"} component={RedeemcodeManage}/>
                <Route path={this.props.match.url + "/taskpost/redeemcode/create"} component={RedeemcodeCreate}/>
                <Route path={this.props.match.url + "/taskpost/redeemcode/data/:id"} component={RedeemcodeData}/>


                <Route path={this.props.match.url + "/taskpost/blacklist"} component={BlackList}/>
                <Route path={this.props.match.url + "/taskpost/channel"} component={TaskpostChannel}/>
                <Route path={this.props.match.url + "/taskpost/channel_create"} component={TaskpostChannelCreate}/>
                <Route path={this.props.match.url + "/taskpost/channel_edit"} component={TaskpostChannelEdit}/>

                <Route path={this.props.match.url + "/taskpost/stas/:id"} component={TaskpostStas}/>
                <Route path={this.props.match.url + "/taskpost/fans/:id"} component={TaskpostFans}/>

                <Route path={this.props.match.url + "/msg/push"} component={Push}/>

                <Route path={this.props.match.url + "/wxmenu/create"} component={wxCreate}/>
                <Route path={this.props.match.url + "/wxmenu/individuation/list"} component={WXIndividuationList}/>
                <Route path={this.props.match.url + "/wxmenu/individuation/create"} component={WXIndividuationCreate}/>

                <Route path={this.props.match.url + "/wxmenu/auto"} component={WXAutoCreate}/>
                <Route path={this.props.match.url + "/taskpost/tag/list"} component={TaskpostTag}/>
                <Route path={this.props.match.url + "/taskpost/tag/create"}
                       render={(props) => {
                           props.type = 'create';
                           return <TaskpostTagCreate {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/tag/edit"}
                       render={(props) => {
                           props.type = 'edit';
                           return <TaskpostTagCreate {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/sop/list"} component={TaskpostSop}/>
                <Route path={this.props.match.url + "/taskpost/sop/create"}
                       render={(props) => {
                           props.type = 'create';
                           return <TaskpostSopCreate {...props}/>
                       }}/>
                <Route path={this.props.match.url + "/taskpost/sop/edit"}
                       render={(props) => {
                           props.type = 'edit';
                           return <TaskpostSopCreate {...props}/>
                       }}/>


                {/*物流查询*/}
                <Route path={this.props.match.url + "/logistics/list"} component={LogisticsList}/>

                {/*节日签*/}
                <Route path={this.props.match.url + "/festivalsign/list"} component={FestivalSignActivityList}/>
                <Route path={this.props.match.url + "/festivalsign/create"} render={(props) => {
                    props['editType'] = 'create';
                    return <FestivalSignActivityCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/festivalsign/edit/:id"} render={(props) => {
                    props['editType'] = 'edit';
                    return <FestivalSignActivityCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/festivalsign/copy/:id"} render={(props) => {
                    props['editType'] = 'copy';
                    return <FestivalSignActivityCreate {...props}/>
                }}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo
    }
}

export default connect(mapStateToProps)(Ext)
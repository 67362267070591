import React from "react";
import styles from "./signflow.module.css";

export class InputNullMask extends React.Component {
    render() {
        return (
            <div className={styles.inputNullMask}>
                {this.props.errMsg}
            </div>
        );
    }
}

export class InputNullFullMask extends React.Component {
    render() {
        return (
            <div className={styles.inputNullMask + ' ' + styles.inputMaskFull}>
                {this.props.errMsg}
            </div>
        );
    }
}

export class InputErrMask extends React.Component {
    render() {
        return (
            <div className={styles.inputErrMask}>
                {this.props.errMsg}
            </div>
        );
    }
}

export class InputRegCodeErrMask extends React.Component {
    render() {
        return (
            <div className={styles.inputRegCodeErr}>
                {this.props.errMsg}
            </div>
        );
    }
}
import React from "react";
import {Path} from "../path";
import styles from './warn.module.css'
import {Link} from "react-router-dom";

class ProbationWarn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            probationText: this.getProbation(props.probation)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            probationText: this.getProbation(nextProps.probation)
        })
    }

    getProbation(probation) {
        var text = [];
        var probation_show = window.sessionStorage.getItem("PROBATION_SHOW");
        var show = true;
        if (probation_show) {
            var offset = new Date().getTime() - Number(probation_show);
            var hour = offset / (60 * 60 * 1000)
            if (hour < 1) {
                show = false;
            }
        }
        if (show && probation) {
            if (probation.products) {
                for (var i = 0; i < probation.products.length; i++) {
                    var product = probation.products[i];
                    if (product.status == 1) {
                        var now = new Date();
                        var offset = product.expireAt - now.getTime();
                        if (offset > 0) {
                            var days = Math.round(offset / (24 * 60 * 60 * 1000))
                            if (days == 0) {
                                text.push('您的［' + product.name + '］今天就要过期了哦～')
                            } else if (days > 0 && days < 8) {
                                text.push('您的［' + product.name + '］还有 ' + days + ' 天就要过期了哦～')
                            }
                        }
                    }
                }
                text.push('请立即续费使用');
            }
            if (text.length == 0 && probation.probationDays > 0) {
                text.push('您的［产品体验时间］还有 ' + probation.probationDays + ' 天就要过期了哦～还没体验过的产品请抓紧时间体验哦～')
                text.push('已经购买或兑换的产品不受影响,可以继续使用')
            }
        }
        return text;
    }

    close(e) {
        $(e.target).parent().parent().hide();
        var now = new Date();
        window.sessionStorage.setItem("PROBATION_SHOW", now.getTime() + "");
    }

    render() {
        return (
            <div>
                {
                    this.state.probationText.length > 1 ? <div className={styles.pwarn}>
                        <div className={styles.header}>
                            <span onClick={this.close} className={styles.close}></span>
                        </div>
                        <div className={styles.body}>
                            {
                                this.state.probationText.map(function (data) {
                                    return (
                                        <p>{data}</p>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.footer}>
                            <Link to={Path.getVipPurPath()}>
                                <button className="mxd-btn mxd-btn-primary">立即续费</button>
                            </Link>
                        </div>
                    </div> : ''
                }
            </div>
        )
    }
}

export default ProbationWarn
import React from "react";

let smsSender = {
    color: '#00ABFF',
    display: 'inline-block',
    fontSize: 14,
    textAlign: 'center',
    cursor: 'pointer',
    lineHeight: 'inherit',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    padding: 0
};

let smsCountDown = {
    color: '#909298',
    fontSize: 14
};


class Sender extends React.Component {
    render() {
        return (
            <a style={smsSender} onClick={this.props.send}>获取短信验证码
            </a>
        );
    }
}

class CountDown extends React.Component {

    constructor(props) {
        super(props);
        this.countDown = this.countDown.bind(this);
        this.state = {
            millis: 61
        }
    }

    componentDidMount() {
        this.countDown();
    }

    countDown() {
        var millis = this.state.millis - 1;
        this.setState({
            millis: millis
        });
        if (this.state.millis > 1) {
            setTimeout(this.countDown, 1000);
        } else {
            this.props.timeout();
        }
    }

    prevent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    render() {
        return (
            <div style={smsCountDown} onClick={this.prevent}>
                {this.state.millis} 秒后可重发
            </div>
        );
    }
}

class SMSSender extends React.Component {

    constructor(props) {
        super(props);
        this.send = this.send.bind(this);
        this.timeout = this.timeout.bind(this);
        this.hasSent = this.hasSent.bind(this);
        this.state = {
            sent: false
        }
    }

    hasSent() {
        this.setState({
            sent: true
        });
    }

    send(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.doSend(this.hasSent);
    }

    timeout() {
        this.setState({
            sent: false
        });
    }


    render() {
        var sms = this.state.sent ? <CountDown timeout={this.timeout}/> : <Sender send={this.send}/>;
        return (
            <div>{sms}</div>
        );
    }
}

export default SMSSender
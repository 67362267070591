import React from 'react'
// import "./style/welcome.less"
import {withRouter} from "react-router-dom";
import {FetchUtil} from '../fetch_util'
import {connect} from "react-redux";
import {
    URL_GET_MSG_TEMPLATE_TASK_ACTIVE_LIST,
    URL_HOME_ORDER_DATA,
    URL_HOME_PRODUCT_NOEXPIRE,
    URL_HOME_TASKPOSTER_STATS_ALL_YESTERDAY
} from "../variables"
import {Path} from "../path";
import Util from "../utils"
// import Pic from "../../images/welcome/quanlver.jpg"
import img from '../../images/welcome/kefuNew.png'
import mxdfwh from "../../images/mxdfwh.png"
import tool from "../../images/welcome/tool.jpg"
import motion from "../../images/welcome/motion.jpg"
import goods from "../../images/welcome/goods.jpg"
import education from "../../images/welcome/education.jpg"
import EwmPic from "../../images/welcome/rightTop.png"
import hutui from "../../images/hutui.png"
import WL from '../../images/welcome/wl.png'

import {Button, message} from "antd"
import {Modal} from "antd/lib/index";

let fetchUtil = new FetchUtil();

class Welcome extends React.Component {

    constructor(props) {
        super(props);
        this.gotoOrderList = this.gotoOrderList.bind(this)
        this.gotoTaskData = this.gotoTaskData.bind(this)
        this.getTaskAllData = this.getTaskAllData.bind(this)
        this.gotoMsgCreate = this.gotoMsgCreate.bind(this)
        this.gotoOrderBuy = this.gotoOrderBuy.bind(this)
        this.getDate = this.getDate.bind(this)
        this.initDateNum = this.initDateNum.bind(this)
        this.getByteLen = this.getByteLen.bind(this)
        this.screenChange = this.screenChange.bind(this)
        this.resize = this.resize.bind(this);
        this.setImg = this.setImg.bind(this);
        this.setImgLeave = this.setImgLeave.bind(this);
        this.getExpire = this.getExpire.bind(this);
        this.getOrderData = this.getOrderData.bind(this);
        this.toThousands = this.toThousands.bind(this);
        this.orderNumChange = this.orderNumChange.bind(this);
        this.changeList = this.changeList.bind(this);
        this.getExpireData = this.getExpireData.bind(this);
        this.setDataConHover = this.setDataConHover.bind(this);
        this.setDataConHoverLeave = this.setDataConHoverLeave.bind(this);
        this.getSubscriptionList = this.getSubscriptionList.bind(this);
        this.closeAuthorization = this.closeAuthorization.bind(this);
        this.gotoCreate = this.gotoCreate.bind(this);
        this.state = {
            describeNum: 20,
            goodsIndex: 0,//将续费的产品是第几个
            proList: [],
            willExpire: 0,
            rightConHeight: 0,
            subscriptionList: [],
            authorizationShow: false,//
            order: [{status: 0, count: 0}, {status: 1, count: 0}, {status: 2, count: 0},],
            taskData: {
                newFansCount: 0,   //新增
                netFansCount: 0,  //净增
                partFansCount: 0,  //参与
                completeCount: 0  //完成
            },
            notifyEndDate: '2024-05-01 00:00:00',
            data: {
                case: [{
                    img: goods,
                    href: "https://mp.weixin.qq.com/s/MlYhp0nmxxqZVqIakd49NA",
                    title: "半亩花田微服务",
                    describe: "「半亩花田微服务」公众号在2019年2月21日发起了一波送鹅蛋手霜的任务宝活动，活动开始4小时就结束了，500份奖品被领完。2月25日又开启了第二波活动，不到一个半小时，新增500份奖品再次一领而空，1个小时涨粉6000+。"
                }, {
                    img: tool,
                    href: "https://mp.weixin.qq.com/s/cfqRsxnz31_zPZ0AyzEMQg",
                    title: "ProcessOn",
                    describe: "在2月底，公众号「ProcessOn」策划了一场超高曝光率的任务宝活动，活动启动推广图文阅读量增至3W+，活动曝光率提高500%。"
                }, {
                    img: motion,
                    href: "https://mp.weixin.qq.com/s/IAyGennhhGGp-qacNdZsWg",
                    title: "瑜伽TV",
                    describe: "听说很多人都想0预算涨粉涨它个几万十几万，这种想法很美好，但现实也不是不可能。今天分享一个「瑜伽TV」去年的案例，虽然没有突破上万涨粉，但真的是0预算实现了近8000的涨粉裂变，对于他们首次在小范围推广尝试，这个数据效果也是非常不错的了。"
                }, {
                    img: education,
                    href: "https://mp.weixin.qq.com/s/mrPYcT-mMWDKbyn0k8EGSg",
                    title: "嗨职场",
                    describe: "最近，有一个任务宝活动分享在咱们的社群里火了，就因为使用了媒想到任务宝，两周涨了20000粉，话刚一出，就吸引了大批群友的围观讨论。今天就跟大家具体说说这个案例，看看「嗨职场」是如何做到每周增粉一万的。"
                },],
            }
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.screenChange();
        this.resize()
        this.getExpire()
        this.getOrderData()
        this.getTaskAllData()
        this.getSubscriptionList()
        // console.log($(".welcomeConThree"))
        // let height = $(".welcomeConThree").height()
        let height = $("#welcomeConThree")[0].offsetHeight
        // console.log(height)
        this.setState({
            rightConHeight: height
        })
        $("body").addClass("bodyWidth")
        if (new Date().getTime() < 1676905200000) {
            Modal.error({
                title: '【重要】媒想到分销和拼团微信商户支付能力下线通知',
                content: (
                    <div>
                        由于微信支付收款资金结算方式调整，媒想到将于2023年2月19号中午12点下线媒想到提供的微信商户收款功能。请使用媒想到分销或拼团产品并使用媒想到提供的微信商户收款的商家尽快申请并配置自己的微信商户。
                        详情请见
                        <a className="text-primary" target="_blank" href="https://pay.weixin.qq.com/index.php/public/cms/content_detail?lang=zh&id=126521">《收款资金结算方式调整通知》</a>
                        ，
                        <a className="text-primary" target="_blank" href="https://pay.weixin.qq.com/index.php/public/cms/content_detail?lang=zh&id=126073">《企业付款到零钱产品调整通知》</a>
                    </div>
                ),
                okText: '知道了'
            })
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
        $("body").removeClass("bodyWidth")
    }

    // 得到公众号列表
    getSubscriptionList() {
        fetchUtil.init()
            .setUrl(URL_GET_MSG_TEMPLATE_TASK_ACTIVE_LIST)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    // this.setState({
                    //     subscriptionList:[]
                    // })
                    if (msg.respMsg && msg.respMsg.subsList.length > 0) {
                        this.setState({
                            authorizationShow: false,
                            subscriptionList: msg.respMsg.subsList
                        })
                    } else {
                        this.setState({
                            authorizationShow: true,
                            // subscriptionList: msg.respMsg.subsList
                        })
                    }

                }
            })
            .catch(() => {

            })
    }

    closeAuthorization() {
        this.setState({
            authorizationShow: false
        })
    }

    gotoCreate(data, index) {
        if (data && data.length > 0) {
            if (index != 5 || index != 4) {
                this.setState({
                    authorizationShow: false
                })
            } else {
                message.info("敬请期待！")
            }
            switch (index) {
                case 0:
                    this.props.history.push(Path.getSubsManagePath());
                    break;
                case 1:
                    this.props.history.push(Path.getShareTreasureCreatePath());
                    break;
                case 3:
                    this.props.history.push(Path.getCreateTaskposterPath());
                    break;
                case 4:
                    this.props.history.push(Path.getGoodsCreate());
                    break;
                // case 5:this.props.history.push(Path.getSubsManagePath());break;
            }
        } else {
            if (index != 5 || index != 4) {
                this.setState({
                    authorizationShow: true
                })
            } else {
                message.info("敬请期待！")
            }

        }

    }

    changeList(data) {
        let arr = [], obj = {}
        data.map((val, index) => {
            val.products.map((value, j) => {
                obj["nickName"] = val["nickName"]
                obj["proName"] = value["proName"]
                obj["expireDate"] = value["expireDate"]
                obj["itemType"] = value["itemType"]
                obj["appId"] = val["appId"]
                arr.push(JSON.stringify(obj))
            })

        })
        return arr
    }

    getExpire() {
        fetchUtil.init()
            .setUrl(URL_HOME_PRODUCT_NOEXPIRE)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((res) => {
                if (res.respCode == 0) {
                    // let list = []
                    // if (res.respMsg.proList.length > 0) {
                    //     list = this.changeList(res.respMsg.proList)
                    // }

                    this.setState({
                        // proList: list,
                        willExpire: res.respMsg.willExpire
                    })
                }
            })
    }

    getOrderData() {
        fetchUtil.init()
            .setUrl(URL_HOME_ORDER_DATA)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((res) => {
                if (res.respCode == 0) {
                    this.setState({
                        order: res.respMsg.order
                    })
                }
            })
    }

    getTaskAllData() {
        fetchUtil.init()
            .setUrl(URL_HOME_TASKPOSTER_STATS_ALL_YESTERDAY)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((res) => {
                if (res.respCode == 0) {
                    this.setState({
                        taskData: res.respMsg
                    })
                }
            })
    }


    toThousands(num) {
        var num = (num || 0).toString(), result = '';
        while (num.length > 3) {
            result = ',' + num.slice(-3) + result;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return result;
    }

    screenChange() {
        window.addEventListener('resize', this.resize);
    }

    resize(e) {
        let clientW = document.documentElement.clientWidth || document.body.clientWidth, length = ""
        if (clientW <= 1300) {
            length = parseInt(156 / 60)
        } else {
            length = parseInt((clientW - 420) / 60)
        }
        // let height = $("#welcomeConThree").height()
        let height = parseInt($("#welcomeConThree")[0].offsetHeight)
        // console.log(height)
        this.setState({
            describeNum: length * 2,
            rightConHeight: height
        })

    }

    gotoOrderList(status) {
        this.props.history.push(Path.getGoodsOrderList(), {status: status})
    }


    gotoTaskData(e) {
        e.nativeEvent.stopImmediatePropagation()
        this.props.history.push(Path.getTaskposterStasPath() + 0)
    }

    gotoMsgCreate(data) {
        if (data && data.length > 0) {
            this.setState({
                authorizationShow: false
            })
            this.props.history.push(Path.getMsgTaskRecordPath(), {flag: true})
        } else {
            this.setState({
                authorizationShow: true
            })
        }

    }

    gotoOrderBuy(index) {
        let renew = {};
        // let string = this.state.proList[index]
        // let obj = JSON.parse(string), renew = {}
        // let itemType = obj.itemType
        // renew["itemType"] = obj.itemType
        renew["flag"] = 1
        // renew["appId"] = obj.appId
        sessionStorage.setItem('renew', JSON.stringify(renew));
        this.props.history.push(Path.getMineProductInfo())
    }

    initDateNum(num) {
        if (num > 9) {
            return num
        } else {
            return "0" + num
        }
    }

    getDate(date) {
        let dateData = new Date(date)
        let str = dateData.getFullYear() + "年" + this.initDateNum(dateData.getMonth() + 1) + "月" + this.initDateNum(dateData.getDate()) + "日"
        return str
    }

    getByteLen(val) {
        var len = 0;
        for (var i = 0; i < val.length; i++) {
            var a = val.charAt(i);
            if (a.match(/[^\x00-\xff]/ig) != null) {
                len += 2;
            } else {
                len += 1;
            }
        }
        return len;
    }

    setImg(index) {
        $(".welcomeThreeImgData")[index].style.animation = "enlarge 500ms"
        $(".welcomeThreeImgData")[index].style.transform = "scale(1.1)"
        $(".welcomeThreeConTitle")[index].style.animation = "titleMove ease-in-out 500ms"
        $(".welcomeThreeConTitle")[index].style.transform = "translateX(10px)"
    }

    setImgLeave(index) {
        $(".welcomeThreeImgData")[index].style.animation = "narrow 500ms"
        $(".welcomeThreeImgData")[index].style.transform = "scale(1)"
        $(".welcomeThreeConTitle")[index].style.animation = "titleMoveOut ease-in-out 500ms"
        $(".welcomeThreeConTitle")[index].style.transform = "translateX(0)"
    }

    setDataConHover(index) {
        // $(".welcomeConOneTopIcon")[index].style.animation = "enlarge 500ms"
        // $(".welcomeConOneTopIcon")[index].style.transform = "scale(1.1)"
        $(".welcomeConOneTopIcon")[index].style.animation = "ensmall 500ms"
        $(".welcomeConOneTopIcon")[index].style.transform = "scale(0.9)"
        // $(".welcomeConOneTopIcon")[index].style.color = "#0c8eff"

    }

    setDataConHoverLeave(index) {
        // $(".welcomeConOneTopIcon")[index].style.animation = "narrow 500ms"
        // $(".welcomeConOneTopIcon")[index].style.transform = "scale(1)"
        $(".welcomeConOneTopIcon")[index].style.animation = "narrowsmall 500ms"
        $(".welcomeConOneTopIcon")[index].style.transform = "scale(1)"
        // $(".welcomeConOneTopIcon")[index].style.color = "#3A3A3A"
    }


    orderNumChange(num) {
        let str = num.toString(), unit = ""
        if (str.length >= 5 && str.length < 9) {
            str = parseFloat(num / 10000).toFixed(1)
            unit = "万"
        } else if (str.length >= 9) {
            str = parseFloat(num / 100000000).toFixed(1)
            unit = "亿"
        }
        return {
            str: str,
            unit: unit
        }
    }

    getExpireData(orderIndex, newIndex) {
        this.setState({
            goodsIndex: newIndex
        })
    }

    isShowNotify = () => {
        let notifyEndDate = this.state.notifyEndDate;
        let now = new Date().getTime();
        let endTime = new Date(notifyEndDate).getTime();
        if (endTime > now) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <div className="content">
                <div>
                    <div className="contentBody welcome clearfix">
                        <div className="mxd-float-left welcomeLeft">
                            {
                                this.state.willExpire == 1 ?
                                    <div className="clearfix welcomeTop">
                                        <div className="mxd-float-left welcomeTopData">
                                            <div
                                                onClick={this.gotoOrderBuy.bind(this, this.state.goodsIndex)}>
                                                <i className="icon-tips-horn"></i>
                                                <span className="welcomeTootip">提示：您有产品即将到期，为了不影响您的使用，请您尽快续费。
                                                    {/*<span*/}
                                                    {/*className="welcomeName">{data.nickName}</span>的<span*/}
                                                    {/*className="welcomeName">{data.proName}</span>产品截止至<span*/}
                                                    {/*className="">{this.getDate(data.expireDate)}</span>到期，为了不影响您的使用，请您尽快续费。*/}
                            </span>
                                                <a className="mxd-float-right welcomeLookMore welcomeLookMoreMoney">查看详情</a>
                                            </div>
                                            {/*<Carousel vertical autoplay={true} dots={false}*/}
                                            {/*beforeChange={this.getExpireData}>*/}
                                            {/*{*/}
                                            {/*this.state.proList.map((val, index) => {*/}
                                            {/*let data = JSON.parse(val)*/}
                                            {/*return (*/}
                                            {/*<div*/}
                                            {/*onClick={this.gotoOrderBuy.bind(this, this.state.goodsIndex)}>*/}
                                            {/*<i className="icon-tips-horn"></i>*/}
                                            {/*<span className="welcomeTootip">提示：您<span*/}
                                            {/*className="welcomeName">{data.nickName}</span>的<span*/}
                                            {/*className="welcomeName">{data.proName}</span>产品截止至<span*/}
                                            {/*className="">{this.getDate(data.expireDate)}</span>到期，为了不影响您的使用，请您尽快续费。</span>*/}
                                            {/*<a className="mxd-float-right welcomeLookMore welcomeLookMoreMoney">续费</a>*/}
                                            {/*</div>*/}
                                            {/*)*/}
                                            {/*})*/}
                                            {/*}*/}

                                            {/*</Carousel>*/}
                                        </div>

                                    </div> : ""
                            }
                            {
                                this.isShowNotify() ? <div className="clearfix welcomeTop welcomeTopTooltip">
                                    <div className="mxd-float-left welcomeTopData">
                                        <div>
                                            <i className="icon-tips-horn"></i>
                                            <p className={'welcomeTootWrapper'}>
                                            <span
                                                className="welcomeTootip">【更新通知！！！】订阅号任务宝邀请方式调整，<a href="https://www.yuque.com/liyang-gz4ar/mxd/logx7prhggbc0ti3" target="_blank">具体参考文档说明</a></span>
                                            </p>
                                        </div>
                                    </div>

                                </div> : ''
                            }
                            <div className="clearfix welcomeTop welcomeTopTooltip">
                                <div className="mxd-float-left welcomeTopData">
                                    <div>
                                        <i className="icon-tips-horn"></i>
                                        <p className={'welcomeTootWrapper'}>
                                            <span
                                                className="welcomeTootip welcomeTootipLink">&nbsp;&nbsp;媒想到仅提供技术支持，请您遵守
                                                <a href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl"
                                                   target="_blank"
                                                   className='text-primary'>《微信开放平台协议》、</a>
                                                <a href="https://mp.weixin.qq.com/cgi-bin/announce?action=getannouncement&amp;key=1503979103&amp;version=1&amp;lang=zh_CN&amp;platform=2"
                                                   target="_blank"
                                                   className='text-primary'>《微信公众平台协议》、</a>
                                                <a data-v-7964e333=""
                                                   href="https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2"
                                                   target="_blank" className='text-primary'>《微信商户号服务协议》、</a>
                                                <a href="https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_external_links_content_management_specification"
                                                   target="_blank" className='text-primary'>《微信外部链接内容管理规范》、</a>
                                                <a href="https://work.weixin.qq.com/nl/eula" target="_blank"
                                                   className='text-primary'>《企业微信服务协议》</a>
                                                等管理协议，给粉丝提供良好的活动体验，及时处理售后问题。</span>
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="welcomeConOne welcomeConOneContent">
                                <div className="clearfix welcomeConOneTitle">
                                    <p className="welcomeConOneTitleCon mxd-float-left">
                                        <span
                                            className="welcomeConOneTitleTootip mxd-tooltip-dividing"></span>
                                        <span className="welcomeConOneTitleWord">昨日数据</span>
                                    </p>
                                    <a className="mxd-float-right welcomeLookMore"
                                       onClick={this.gotoTaskData}>更多数据</a>
                                </div>
                                <div className="clearfix welcomeConOneCon">
                                    {
                                        this.state.taskData ?
                                            <div>
                                                <div
                                                    className="welcomeConOneData welcomeConOneDataCon mxd-float-left"
                                                    onMouseOver={this.setDataConHover.bind(this, 0)}
                                                    onMouseMove={this.setDataConHover.bind(this, 0)}
                                                    onMouseOut={this.setDataConHoverLeave.bind(this, 0)}
                                                    onClick={this.gotoTaskData}>
                                                    <div className="clearfix welcomeConOneTop">
                                                        <div
                                                            className="welcomeConOneTopIcon welcomeConOneTopIconFans">
                                                            <p className="welcomeConOneDataNum">{Util.orderNumChange(this.state.taskData.newFansCount).str}<span
                                                                className="dataUnit">{Util.orderNumChange(this.state.taskData.newFansCount).unit}</span>
                                                            </p>
                                                            <span
                                                                className="welcomeConOneDataWord">新增粉丝</span>
                                                            {/*<i className="mxd-float-right welcomeConOneTopIcon icon-create-fans"></i>*/}
                                                        </div>

                                                    </div>
                                                    {/*<div className="clearfix welcomeConOneBottom">*/}
                                                    {/*<span className="welcomeConOneDataCount mxd-float-left">昨日数据：<span>{this.toThousands(data.lastData)}</span></span>*/}
                                                    {/*<span className="welcomeConOneDataCount mxd-float-right">累计：<span>{this.toThousands(data.count)}</span></span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div
                                                    className="welcomeConOneData welcomeConOneDataCon mxd-float-left"
                                                    onMouseOver={this.setDataConHover.bind(this, 1)}
                                                    onMouseMove={this.setDataConHover.bind(this, 1)}
                                                    onMouseOut={this.setDataConHoverLeave.bind(this, 1)}
                                                    onClick={this.gotoTaskData}>
                                                    <div className="clearfix welcomeConOneTop">
                                                        <div
                                                            className="welcomeConOneTopIcon welcomeConOneTopIconGrowth">
                                                            <p className="welcomeConOneDataNum">{Util.orderNumChange(this.state.taskData.netFansCount).str}<span
                                                                className="dataUnit">{Util.orderNumChange(this.state.taskData.netFansCount).unit}</span>
                                                            </p>
                                                            <span
                                                                className="welcomeConOneDataWord">净增粉丝</span>
                                                            {/*<i className="mxd-float-right welcomeConOneTopIcon icon-growth-fans"></i>*/}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="welcomeConOneData welcomeConOneDataCon mxd-float-left"
                                                    onMouseOver={this.setDataConHover.bind(this, 2)}
                                                    onMouseMove={this.setDataConHover.bind(this, 2)}
                                                    onMouseOut={this.setDataConHoverLeave.bind(this, 2)}
                                                    onClick={this.gotoTaskData}>
                                                    <div className="clearfix welcomeConOneTop">
                                                        <div
                                                            className="welcomeConOneTopIcon welcomeConOneTopIconFllower">
                                                            <p className="welcomeConOneDataNum">{Util.orderNumChange(this.state.taskData.partFansCount).str}<span
                                                                className="dataUnit">{Util.orderNumChange(this.state.taskData.partFansCount).unit}</span>
                                                            </p>
                                                            <span
                                                                className="welcomeConOneDataWord">参与粉丝</span>
                                                            {/*<i className="mxd-float-right welcomeConOneTopIcon icon-fllower-fans"></i>*/}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="welcomeConOneData welcomeConOneDataCon mxd-float-left"
                                                    onMouseOver={this.setDataConHover.bind(this, 3)}
                                                    onMouseMove={this.setDataConHover.bind(this, 3)}
                                                    onMouseOut={this.setDataConHoverLeave.bind(this, 3)}
                                                    onClick={this.gotoTaskData}>
                                                    <div className="clearfix welcomeConOneTop">
                                                        <div
                                                            className="welcomeConOneTopIcon welcomeConOneTopIconTask">
                                                            <p className="welcomeConOneDataNum">{Util.orderNumChange(this.state.taskData.completeCount).str}<span
                                                                className="dataUnit">{Util.orderNumChange(this.state.taskData.completeCount).unit}</span>
                                                            </p>
                                                            <span
                                                                className="welcomeConOneDataWord">完成任务</span>
                                                            {/*<i className="mxd-float-right welcomeConOneTopIcon icon-task-fans"></i>*/}
                                                        </div>

                                                    </div>
                                                    {/*<div className="clearfix welcomeConOneBottom">*/}
                                                    {/*<span className="welcomeConOneDataCount mxd-float-left">昨日数据：<span>{this.toThousands(data.lastData)}</span></span>*/}
                                                    {/*<span className="welcomeConOneDataCount mxd-float-right">累计：<span>{this.toThousands(data.count)}</span></span>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div> : ""
                                    }
                                </div>

                            </div>
                            <div className="clearfix welcomeConTwo">
                                <div className="welcomeConTwoLeft mxd-float-left">
                                    <div className="clearfix welcomeConOneTitle">
                                        <p className="welcomeConOneTitleCon mxd-float-left">
                                            <span className="mxd-tooltip-dividing"></span>
                                            <span className="welcomeConOneTitleWord">商品订单</span>
                                        </p>
                                        <a className="mxd-float-right welcomeLookMore"
                                           onClick={this.gotoOrderList.bind(this, -1)}>更多数据</a>
                                    </div>
                                    {
                                        this.state.order.length > 0 ?
                                            <div className="welcomeConTwoCon clearfix">
                                                {
                                                    this.state.order.map((data, index) => {
                                                        return (
                                                            <div
                                                                className="welcomeConTwoOne mxd-float-left"
                                                                onClick={this.gotoOrderList.bind(this, data.status)}>
                                                                <span
                                                                    className="welcomeConTwoLeftData">{this.orderNumChange(data.count).str}<span
                                                                    className="dataUnit">{this.orderNumChange(data.count).unit}</span></span>
                                                                <div
                                                                    className={"clearfix welcomeConTwoLeftIcon " + (data.status == 0 ? "welcomeConTwoLeftIconGoods" : data.status == 1 ? "welcomeConTwoLeftIconDelivery" : "welcomeConTwoLeftIconComplate")}>
                                                                    <span
                                                                        className="mxd-float-left welcomeConTwoLeftWord">{data.status == 0 ? "待发货" : data.status == 1 ? "已发货" : "已完成"}</span>
                                                                    {/*<i className={"mxd-float-right welcomeConTwoLeftIcon " + (data.status == 0 ? "icon-goods" : data.status == 1 ? "icon-delivery" : "icon-complate")}></i>*/}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : ""
                                    }

                                </div>
                                <div className="welcomeConTwoLeft mxd-float-right">
                                    <div className="welcomeConOneTitle">
                                        <p className="welcomeConOneTitleCon">
                                            <span className="mxd-tooltip-dividing"></span>
                                            <span className="welcomeConOneTitleWord">常用功能</span>
                                        </p>
                                    </div>
                                    <div className="welcomeConTwoCon welcomeConTwoRightCon">
                                        <div className="welcomeConTwoRightTop">
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoCreate.bind(this, this.state.subscriptionList, 3)}>
                                                 <span className="welcomeConTwoRightOne">
                                                    <i className="icon-create-task welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">新建任务</span>
                                                </span>
                                            </span>
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoCreate.bind(this, this.state.subscriptionList, 4)}>
                                                 <span className="welcomeConTwoRightOne">
                                                    <i className="icon-create-goods welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">新增商品</span>
                                                </span>
                                            </span>
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoCreate.bind(this, this.state.subscriptionList, 0)}>
                                                <span className="welcomeConTwoRightOne">
                                                    <i className="icon-public-vx welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">绑公众号</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="welcomeConTwoRightBottom">
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoMsgCreate.bind(this, this.state.subscriptionList)}>
                                                <span className="welcomeConTwoRightOne">
                                                    <i className="icon-new-msg welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">新建消息</span>
                                                </span>
                                            </span>
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoCreate.bind(this, this.state.subscriptionList, 1)}>
                                                <span className="welcomeConTwoRightOne">
                                                    <i className="icon-new-share welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">新建分享</span>
                                                </span>
                                            </span>
                                            <span className="welcomeConTwoRightOneCon"
                                                  onClick={this.gotoCreate.bind(this, this.state.subscriptionList, 5)}>
                                                <span className="welcomeConTwoRightOne">
                                                    <i className="icon-group-task welcomeConTwoRightIcon"></i>
                                                    <span
                                                        className="welcomeConTwoRightOneWord">建群任务</span>
                                                </span>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {
                                this.state.data.case.length > 0 ?
                                    <div className="welcomeConOne welcomeConThree" id={'welcomeConThree'}>
                                        <div className="clearfix welcomeConOneTitle">
                                            <p className="welcomeConOneTitleCon mxd-float-left">
                                                <span
                                                    className="welcomeConOneTitleTootip mxd-tooltip-dividing"></span>
                                                <span className="welcomeConOneTitleWord">增长案例</span>
                                            </p>
                                        </div>
                                        <div
                                            className="clearfix welcomeConOneCon welcomeConThreeCon">
                                            {
                                                this.state.data.case.map((data, index) => {
                                                    return (
                                                        <a target="_blank" href={data.href}
                                                           onMouseOver={this.setImg.bind(this, index)}
                                                           onMouseMove={this.setImg.bind(this, index)}
                                                           onMouseLeave={this.setImgLeave.bind(this, index)}
                                                           className="welcomeConOneData welcomeConThreeData mxd-float-left">
                                                            <div className="welcomeThreeImg">
                                                                <img src={data.img}
                                                                     className="welcomeThreeImgData"/>
                                                            </div>
                                                            <div className="welcomeThreeCon">
                                                                <span
                                                                    className="welcomeThreeConTitle">{data.title}</span>
                                                                {
                                                                    data.describe ? <span
                                                                        className={parseInt(this.getByteLen(data.describe) / this.state.describeNum) >= 2 ? "welcomeThreeConDescribe" : "welcomeThreeConDescribeNo"}>{data.describe}</span> : ""
                                                                }

                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> : ""
                            }
                        </div>
                        <div className="mxd-float-left welcomeRight">
                            <div className='welcomeLeftImg'>
                                <img src={WL}/>
                            </div>
                            {/*<div className="welcomeRightOne" style={{height: 230}}>*/}
                            {/*<div className={'welcomeRightOneCon'}>*/}
                            {/*<img src={Anli} style={{width: 220}}/>*/}
                            {/*<Button type="primary" className={"anliLink"}*/}
                            {/*href="https://mp.weixin.qq.com/s/mut8FtoNK5cXn0QJ_9czoQ"*/}
                            {/*target="_blank">点击查看详情</Button>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*<div className="welcomeRightOne">*/}
                            {/*<div className="welcomeRightOneTop welcomeRightTwoTop">*/}
                            {/*<h4>5万+私域流量免费帮推</h4>*/}
                            {/*<p>还有机会得<span className='text-red'>任务宝30天</span>会员</p>*/}
                            {/*<br/>*/}
                            {/*<div>*/}
                            {/*<img src={hutui} width={140}/>*/}
                            {/*</div>*/}
                            {/*<p style={{paddingTop: 6}}>扫码咨询</p>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*<div className="welcomeRightOne welcomeRightTwo" style={{height: 240}}>*/}
                            {/*<div className={'welcomeRightOneCon'}>*/}
                            {/*<img src={EwmPic} className={'rightImg'}/>*/}
                            {/*<p className={'rightWord'}>扫码入群免费领取</p>*/}
                            {/*<p className={'rightWord'}>企业微信任务宝7天会员</p>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*<div className="welcomeRightOne welcomeRightTwo">*/}
                            {/*<div className="welcomeRightOneTop welcomeRightTwoTop">*/}
                            {/*/!*<p className="welcomeRightOneTopTitle">媒想到服务号</p>*!/*/}
                            {/*/!*<p className="welcomeRightOneTopBorder"></p>*!/*/}
                            {/*/!*<p className="welcomeRightTwoBottomDescribe welcomeRightTwoBottomDescribe">扫码关注</p>*!/*/}
                            {/*/!*<p className="welcomeRightTwoBottomDescribe welcomeRightTwoBottomDescribeWord">免费领取<span*!/*/}
                            {/*/!*className={'mxd-pink-word'}>H5&小程序商城会员</span>*!/*/}
                            {/*/!*</p>*!/*/}
                            {/*/!*<div className="welcomeRightTwoBorder">*!/*/}
                            {/*/!*<span className="welcomeRightTwoBorderOne">*!/*/}
                            {/*/!*<span className="welcomeBorderHorizontal"></span>*!/*/}
                            {/*/!*<span className="welcomeBorderVertical"></span>*!/*/}
                            {/*/!*</span>*!/*/}
                            {/*/!*<span className="welcomeRightTwoBorderTwo">*!/*/}
                            {/*/!*<span className="welcomeBorderHorizontal"></span>*!/*/}
                            {/*/!*<span className="welcomeBorderVertical"></span>*!/*/}
                            {/*/!*</span>*!/*/}
                            {/*/!*<span className="welcomeRightTwoBorderThree">*!/*/}
                            {/*/!*<span className="welcomeBorderHorizontal"></span>*!/*/}
                            {/*/!*<span className="welcomeBorderVertical"></span>*!/*/}
                            {/*/!*</span>*!/*/}
                            {/*/!*<span className="welcomeRightTwoBorderFore">*!/*/}
                            {/*/!*<span className="welcomeBorderHorizontal"></span>*!/*/}
                            {/*/!*<span className="welcomeBorderVertical"></span>*!/*/}
                            {/*/!*</span>*!/*/}
                            {/*/!*<img src={mxdfwh} className="welcomeRightOneBottomVX"/>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*<h4>免费领</h4>*/}
                            {/*<h4>分销/拼团/砍价30天会员</h4>*/}
                            {/*<p>多款营销工具助力变现</p>*/}
                            {/*<br/>*/}
                            {/*<div>*/}
                            {/*<img src={mxdfwh} width={140}/>*/}
                            {/*</div>*/}
                            {/*<p style={{paddingTop: 6}}>扫码进群免费领取</p>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                {/*<Authorization authorizationShow={this.state.authorizationShow}*/}
                {/*closeAuthorization={this.closeAuthorization}/>*/}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo
    }
}

export default connect(mapStateToProps)(withRouter(Welcome))
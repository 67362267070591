import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './registerServiceWorker';

import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';

ReactDOM.render(<ConfigProvider locale={zh_CN}><App/></ConfigProvider>, document.getElementById('container'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

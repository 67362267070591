import React from 'react';
import {Redirect, Route} from "react-router-dom";

import loadable from "../../loadable";

const GoodsList = loadable(() => import('./goods/goods_list.bundle'));
const GoodsCreate = loadable(() => import('./goods/goods_create.bundle'));
const OrderList = loadable(() => import('./order/order_list.bundle'));
const OrderDeliver = loadable(() => import('./deliver/order_deliver.bundle'));
const OrderChecker = loadable(() => import('./checker/order_checker.bundle'));
const OrderDetail = loadable(() => import('./order/order_detail.bundle'));
const GoodsEdit = loadable(() => import('./goods/goods_edit.bundle'));
const OrderCheckerCreate = loadable(() => import("./checker/order_checker_create.bundle"));
const OrderCheckerData = loadable(() => import("./checker/order_checker_data.bundle"));

/**
 * 店铺
 */
class Myshop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/goods/list"}/>
                )}/>
                <Route path={this.props.match.url + "/goods/list"} component={GoodsList}/>
                <Route path={this.props.match.url + "/goods/create"} component={GoodsCreate}/>
                <Route path={this.props.match.url + "/order/list"} component={OrderList}/>
                <Route path={this.props.match.url + "/order/deliver"} component={OrderDeliver}/>
                <Route path={this.props.match.url + "/order/checker/list"} component={OrderChecker}/>
                <Route path={this.props.match.url + "/order/checker/create"} component={OrderCheckerCreate}/>
                <Route path={this.props.match.url + "/order/checker/data/:id"} component={OrderCheckerData}/>
                <Route path={this.props.match.url + "/goods/edit/:id"} component={GoodsEdit}/>
                <Route path={this.props.match.url + "/order/detail"} component={OrderDetail}/>


                <Route path={this.props.match.url + "/taskpost/goods/list"} component={GoodsList}/>
                <Route path={this.props.match.url + "/taskpost/goods/create"} component={GoodsCreate}/>
                <Route path={this.props.match.url + "/taskpost/order/list"} component={OrderList}/>
                <Route path={this.props.match.url + "/taskpost/goods/edit/:id"} component={GoodsEdit}/>
                <Route path={this.props.match.url + "/taskpost/order/detail"} component={OrderDetail}/>
                <Route path={this.props.match.url + "/taskpost/order/deliver"} component={OrderDeliver}/>
                <Route path={this.props.match.url + "/taskpost/order/checker/list"} component={OrderChecker}/>
            </div>
        );
    }
}

export default Myshop
class UserUtil {

    init(userInfo) {
        this.vip = false;
        this.inProbation = false;
        this.purchaseInfo = [];
        if (userInfo) {
            this.serviceType = (userInfo.subsInfo.serviceType == 2) ? 2 : 1;
            this.verifyType = userInfo.subsInfo.verifyType >= 0 ? 0 : -1;
            this.probationDays = userInfo.probationDays;
            var purchaseList = userInfo.subsInfo.purchaseList;
            if (purchaseList && purchaseList.length > 0) {
                this.vip = true;
                for (var i = 0; i < purchaseList.length; i++) {
                    if (purchaseList[i].expired) {
                        //已过期
                        this.purchaseInfo.push({
                            type: purchaseList[i].type,
                            status: 2
                        })
                    } else {
                        this.purchaseInfo.push({
                            type: purchaseList[i].type,
                            status: 1,
                            expireAt: purchaseList[i].expireAt
                        })
                    }
                }
            }
            //判断试用期
            var regDate = new Date(userInfo.subsInfo.authDate);
            this.regDate = regDate;
            var now = new Date();
            if ((regDate.getTime() + this.probationDays * 24 * 60 * 60 * 1000) > now.getTime()) {
                //在试用期
                this.inProbation = true;
            } else {
                this.inProbation = false;
            }
        }
    }

    getServiceType() {
        return this.serviceType;
    }

    getVerifyType() {
        return this.verifyType;
    }

    isVip() {
        return this.vip;
    }

    isInProbation() {
        return this.inProbation;
    }

    getProductInfo(type) {
        if (this.vip) {
            //是vip
            for (var i = 0; i < this.purchaseInfo.length; i++) {
                if (this.purchaseInfo[i].type == type) {
                    return this.purchaseInfo[i];
                }
            }
        }
        if (this.inProbation) {
            //在试用期
            return {
                type: type,
                status: 1,
                expireAt: this.regDate.getTime() + this.probationDays * 24 * 60 * 60 * 1000
            }
        } else {
            return {
                type: type,
                status: 2,
            }
        }
    }

    //获取试用期剩余天数
    getProbationDays() {
        if (this.inProbation) {
            //在试用期
            var now = new Date();
            var offset = Math.floor((now.getTime() - this.regDate.getTime()) / (24 * 60 * 60 * 1000));
            if (offset < 0) {
                offset = 0;
            }
            return this.probationDays - offset;
        } else {
            //不在试用期了
            return -1;
        }
    }


    getProducts() {
        var products = [];
        if (this.getServiceType() == 2) {
            //消息通
            var tem = this.getProductInfo(8000);
            tem['name'] = '消息宝';
            products.push(tem);
        }
        //任务宝
        var tp;
        if (this.getServiceType() == 2) {
            tp = this.getProductInfo(8006);
        } else {
            tp = this.getProductInfo(8004);
        }
        tp['name'] = '任务宝';
        products.push(tp);
        //分享宝
        var st = this.getProductInfo(8010);
        st['name'] = '分享宝';
        products.push(st);
        //渠道二维码
        if (this.getServiceType() == 2) {
            var cq = this.getProductInfo(8012);
            cq['name'] = '渠道码';
            products.push(cq);
        }
        return products;
    }
}

export default UserUtil
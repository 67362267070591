import React from 'react';
import {Redirect, Route} from "react-router-dom";
import Purchase from './purchase/purchase'
import loadable from "../../loadable";

const RetailList = loadable(() => import('./retail/retail_list.bundle'));
const RetailAdd = loadable(() => import('./retail/retail_add.bundle'));
const MemberData = loadable(() => import('./retail/member_data.bundle'));
const RetailRank = loadable(() => import('./retail/retail_rank.bundle'));
const RetailReport = loadable(() => import('./retail_report/retail_report.bundle'));
const RetailCode = loadable(() => import('./retail/retail_code_record.bundle'));
const CashOut = loadable(() => import('./cash/cash_out.bundle'));
const CashList = loadable(() => import('./cash/cash_record.bundle'));
const WriteOffList = loadable(() => import('./writeOff/manage.bundle'));
const WriteOffCreate = loadable(() => import('./writeOff/create.bundle'));
const WriteOffData = loadable(() => import('./writeOff/data_count.bundle'));
const Channellist = loadable(() => import('./channel/channel_list.bundle'));
const ChannelAdd = loadable(() => import('./channel/channel_add.bundle'));
const RetailShop = loadable(() => import('./shop/shop.bundle'));
const RetailShopEdit = loadable(() => import('./shop/shop_edit.bundle'));
const RetailMsg = loadable(() => import('./msg/msg.bundle'));
const RetailMsgAdd = loadable(() => import('./msg/msg_add.bundle'));

/**
 * 店铺
 */
class Myshop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/retail/list"}/>
                )}/>
                <Route exact path={this.props.match.url + '/retail'} render={() => (
                    <Redirect to={this.props.match.url + "/retail/list"}/>
                )}/>
                <Route path={this.props.match.url + "/retail/list"} component={RetailList}/>
                <Route path={this.props.match.url + "/retail/data/:id"} component={MemberData}/>
                <Route path={this.props.match.url + "/retail/create"} render={(props) => {
                    props.type = 'create';
                    return <RetailAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/retail/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <RetailAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/retail/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <RetailAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/retail/rank/:id"} component={RetailRank}/>
                <Route path={this.props.match.url + "/retail/report/:id"} component={RetailReport}/>
                <Route path={this.props.match.url + "/retail/code/:id"} component={RetailCode}/>

                <Route exact path={this.props.match.url + '/cash'} render={() => (
                    <Redirect to={this.props.match.url + "/cash/record"}/>
                )}/>
                <Route path={this.props.match.url + "/cash/record"} component={CashList}/>
                <Route path={this.props.match.url + "/cash/out"} component={CashOut}/>
                <Route path={this.props.match.url + "/purchase"} component={Purchase}/>

                <Route path={this.props.match.url + "/writeoff/list"} component={WriteOffList}/>
                <Route path={this.props.match.url + "/writeoff/create"} component={WriteOffCreate}/>
                <Route path={this.props.match.url + "/writeoff/data/:id"} component={WriteOffData}/>
                <Route path={this.props.match.url + "/channel/list"} component={Channellist}/>
                <Route path={this.props.match.url + "/channel/create"} render={(props) => {
                    props.type = 'create';
                    return <ChannelAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/channel/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <ChannelAdd {...props}/>
                }}/>
                <Route exact path={this.props.match.url + "/retail/shop"} render={(props) => {
                    props.type = 'edit';
                    return <RetailShop {...props}/>
                }}/>
                <Route exact path={this.props.match.url + "/retail/shop/edit"} render={(props) => {
                    props.type = 'edit';
                    return <RetailShopEdit {...props}/>
                }}/>
                <Route exact path={this.props.match.url + "/msg/list"} component={RetailMsg}/>
                <Route exact path={this.props.match.url + "/msg/create"} render={(props) => {
                    props.type = 'create';
                    return <RetailMsgAdd {...props}/>
                }}/>
                <Route exact path={this.props.match.url + "/msg/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <RetailMsgAdd {...props}/>
                }}/>

            </div>
        );
    }
}

export default Myshop
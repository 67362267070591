import React from "react";
import {Link} from "react-router-dom";
import styles from "./subs_auth.module.css"
import indexStyle from "../layout/index.module.css"
import {URL_GET_AURH_URI, URL_LOGOUT} from "../variables";
import {FetchUtil} from '../fetch_util'
import Footer from '../signflow/footer'
import {Path, toSignIn} from "../path";
import img_avatar from '../../images/avatar.png'

let fetchUtil = new FetchUtil();

class SubsAuth extends React.Component {

    constructor(props) {
        super(props);
        this.toAuth = this.toAuth.bind(this);
        this.exit = this.exit.bind(this);
    }

    //公众号授权
    toAuth() {
        let fetchUtil = new FetchUtil();
        fetchUtil.init()
            .setUrl(URL_GET_AURH_URI)
            .setMethod('GET')
            .setCookieCors()
            .setOvertime(2000)
            .setReturnType('text')
            .dofetch()
            .then((data) => {
                if ('error' != data) {
                    window.location.href = data;
                }
            })
            .catch(() => {

            });
    }

    exit() {
        fetchUtil.init()
            .setUrl(URL_LOGOUT)
            .setMethod('POST')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0 || msg.respCode == 1050) {
                    //注销成功
                    this.props.history.push(Path.getSigninPath());
                }
            })
            .catch(() => {

            });
    }

    render() {
        var toAuth = this.toAuth;
        var success = true;
        var text = '请授权绑定公众号';
        var re = '';
        var email = '';
        var result = this.props.match.params.result;
        var search = this.props.location.search;
        var timeout = false;
        if (typeof search != 'undefined') {
            var p = JSON.parse(decodeURIComponent(search.substring(3)));
            if (typeof p.user != 'undefined') {
                email = p.user;
            }
            if (typeof result != 'undefined' && result == 'fail') {
                //授权失败
                success = false;
                if (p.type == 1) {
                    //session结束
                    text = '登录超时，请重新登录并授权绑定公众号';
                    re = <Link className={styles.login} to="/signin">&nbsp;&nbsp;登录</Link>;
                    timeout = true;
                } else if (p.type == 2) {
                    text = '一个账号最多只能授权5个公众号';
                    re = <Link className={styles.login} to="/signin">&nbsp;&nbsp;请登录</Link>;
                } else if (p.type == 3) {
                    text = '该公众号已绑定账号' + p.owner;
                    re = <Link className={styles.login} to="/signin">&nbsp;&nbsp;请登录</Link>;
                } else if (p.type == 4) {
                    text = '授权绑定公众号出错，请重新授权';
                }
            } else {
                if (p.type == -1) {
                    //登陆成功
                    text = '账户登录成功，请授权绑定公众号';
                } else if (p.type == -2) {
                    text = '账户注册成功，请授权绑定公众号'
                }
            }
        }
        var authImmgStyle;
        if (success) {
            authImmgStyle = styles.authStatusImg + " " + styles.successImg
        } else {
            authImmgStyle = styles.authStatusImg + " " + styles.failImg
        }
        return (
            <div>
                <header>
                    <div className={indexStyle.siteNav}>
                        <div className={indexStyle.logoWraper}>
                            <Link to={Path.getSigninPath()}>
                                <span className={indexStyle.logo}></span>
                            </Link>
                            <span className={indexStyle.slogan}></span>
                        </div>
                        <div className={indexStyle.hdSet}>
                            <div className={"dropdown " + indexStyle.hdSetItem}>
                                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                   aria-expanded="false">
                                    <span><img src={img_avatar}/></span>
                                    <span className={"mxd-caret caret"}></span>
                                </a>
                                <ul className={"mxd-dropdown-menu dropdown-menu dropdown-menu-right"}>
                                    <li className="text-center">
                                        <a className={"menu-item " + indexStyle.exit} onClick={this.exit}>退出登录</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                <div className={styles.auth}>
                    <div className={authImmgStyle}></div>
                    <p className={styles.successText}>{text}{re}</p>
                    {
                        timeout ? '' : <button className={"mxd-btn mxd-btn-primary " + styles.authBtn}
                                               onClick={toAuth}>微信公众号授权登录</button>
                    }
                </div>
                <Footer/>
            </div>
        );
    }
}

export default SubsAuth
import React from 'react';
import {Redirect, Route} from "react-router-dom";

import loadable from "../../loadable";

const LogisticsList = loadable(() => import("./list/list.bundle"));
const LogisticsCreate = loadable(() => import("./create/create.bundle"));
const LogisticsLookList = loadable(() => import("./list/look.bundle"));

class Logistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url}
                       render={() => (<Redirect to={this.props.match.url + "/list"}/>)}
                />
                <Route path={this.props.match.url + "/list"} component={LogisticsList}/>
                <Route path={this.props.match.url + "/create"} render={(props) => {
                    props.type = 'create';
                    return <LogisticsCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <LogisticsCreate {...props}/>
                }}/>
                <Route path={this.props.match.url + "/look/:id"} component={LogisticsLookList}/>
            </div>
        )
    }

}

export default Logistics;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const DOMAIN_QUN = process.env.REACT_APP_DOMAIN;
export let URL_SIGNIN = DOMAIN + '/mxd/user/signin';
export let URL_SEND_REGCODE = DOMAIN + '/mxd/user/reg_code';
export let URL_SIGNUP = DOMAIN + '/mxd/user/signup';
export let URL_LOGIN_STATUS = DOMAIN + '/mxd/user/login_status';
export let URL_LOGOUT = DOMAIN + '/mxd/user/logout';
export let URL_GET_AURH_URI = DOMAIN + '/mxd/wechat/auth_uri';
export let URL_GET_USER_BASEINFO = DOMAIN + '/mxd/user/base_info';
// 消息宝
export let URL_GET_WECHAT_TEMPLATE_LIST = DOMAIN + '/mxd/template_msg/wechat_template/list';
export let URL_GET_MY_TEMPLATE_LIST = DOMAIN + '/mxd/template_msg/my_template/list';
export let URL_SYNC_MY_TEMPLATE_LIST = DOMAIN + '/mxd/template_msg/my_template/sync';
export let URL_SYNC_MY_TEMPLATE_PREVIEW = DOMAIN + '/mxd/template_msg/preview?msgId=';
export let URL_ADD_MSG_TEMPLATE = DOMAIN + '/mxd/template_msg/my_template/add';
export let URL_IS_MSG_TEMPLATE_NULL = DOMAIN + '/mxd/template_msg/my_template/isnull';
export let URL_GET_MSG_TEMPLATE_SELECT_LIST = DOMAIN + '/mxd/template_msg/my_template/select_list';
export let URL_ADD_TEMPLATE_MSG = DOMAIN + '/mxd/template_msg/add';
export let URL_EDIT_TEMPLATE_MSG = DOMAIN + '/mxd/template_msg/edit';
export let URL_DELETE_TEMPLATE_MSG = DOMAIN + '/mxd/template_msg/delete';
export let URL_GET_TEMPLATE_MSG_LIST = DOMAIN + '/mxd/template_msg/list';
export let URL_GET_TEMPLATE_MSG_SELECT_LIST = DOMAIN + '/mxd/template_msg/selectlist';
export let URL_SYNC_FANS = DOMAIN + '/mxd/fans/sync';
export let URL_SYNC_FANS_STATUS = DOMAIN + '/mxd/fans/sync/status';
export let URL_SYNC_FANS_PROGRESS = DOMAIN + '/mxd/fans/sync/progress';
export let URL_GET_FANS_LIST = DOMAIN + '/mxd/fans/list';
export let URL_SEARCH_FANS = DOMAIN + '/mxd/fans/search';
export let URL_ADD_SUBSTAG = DOMAIN + '/mxd/substag/add';
export let URL_DELETE_SUBSTAG = DOMAIN + '/mxd/substag/delete';
export let URL_RENAME_SUBSTAG = DOMAIN + '/mxd/substag/rename';
export let URL_GET_SUBSTAG_LIST = DOMAIN + '/mxd/substag/list';
export let URL_ADD_MSG_TEMPLATE_TASK = DOMAIN + '/mxd/template_msg/task/add';
export let URL_GET_MSG_TEMPLATE_TASK_LIST = DOMAIN + '/mxd/template_msg/task/list';
export let URL_QUERY_MSG_TEMPLATE_TASK_LIST = DOMAIN + '/mxd/template_msg/task/query';
export let URL_EXPORT_MSG_TEMPLATE_TASK_LIST = DOMAIN + '/mxd/template_msg/task/export';
export let URL_GET_MSG_TEMPLATE_SUB_EXPRIRED = DOMAIN + '/mxd/subs/expired';
export let URL_GET_MSG_TEMPLATE_MSG_TASK_TP_MSGLIMIT = DOMAIN + '/mxd/template_msg/task/tp_msglimit';
// 列表详情
export let URL_GET_MSG_TEMPLATE_MSG_TASK_DETAIL = DOMAIN + '/mxd/template_msg/task/detail';
// 获取新建模板的模板
export let URL_GET_MSG_TEMPLATE_MSG_SYNC = DOMAIN + '/mxd/template_msg/my_template/sync';
export let URL_GET_MSG_TEMPLATE_MSG_PREVIEW = DOMAIN + '/mxd/template_msg/preview';
// 获取用户头像
export let URL_GET_MSG_SUBS_HEADING = DOMAIN + '/mxd/subs/headimg';
export let URL_GET_MSG_UPLOAD_PIC = DOMAIN + '/mxd/images/upload_wx';//上传图片
export let URL_CREATE_TEMPLATE_MSG_TASK_ADD = DOMAIN + '/mxd/template_msg/task/add';//新建
export let URL_GET_MSG_TEMPLATE_TASK_ACTIVE_LIST = DOMAIN + '/mxd/subs/active/list';
export let URL_GET_MSG_TEMPLATE_TASK_ACTIVE_CORPLIST = DOMAIN + '/mxd/subs/active/corplist';
export let URL_UPDATE_FAN_TAGS = DOMAIN + '/mxd/fans/tags/modify';
export let URL_UPDATE_FAN_REMARK = DOMAIN + '/mxd/fans/remark/modify';
export let URL_GET_VERIFY_CODE = DOMAIN + '/mxd/user/verifycode?rd=';
export let URL_FP_CHECK_ACCOUNT = DOMAIN + '/mxd/findpw/check_account';
export let URL_FP_CHECK_VC = DOMAIN + '/mxd/findpw/check_vc';
export let URL_FP_SETPWD = DOMAIN + '/mxd/findpw/setpwd';
export let URL_USER_RESETPWD = DOMAIN + '/mxd/user/reset_pwd';
export let URL_ADD_TASKPOSTER = DOMAIN + '/mxd/taskposter/add';
export let URL_TASKPOSTER_UPLOADIMG = DOMAIN + '/mxd/taskposter/uploadimg';
export let URL_MODIFY_TASKPOSTER = DOMAIN + '/mxd/taskposter/modify';
export let URL_TASKPOSTER_RANK_QRCODE = DOMAIN + '/mxd/taskposter/rank/qrcode?taskposterId=';
export let URL_GET_TASKPOSTER_LIST = DOMAIN + '/mxd/taskposter/list';
export let URL_GET_TASKPOSTER_SUBSLIST = DOMAIN + '/mxd/taskposter/subslist';
export let URL_GET_TASKPOSTER = DOMAIN + '/mxd/taskposter/get';
export let URL_PRECHECK_TASKPOSTER = DOMAIN + '/mxd/taskposter/precheck';
export let URL_DELETE_TASKPOSTER = DOMAIN + '/mxd/taskposter/delete';
export let URL_CLOSE_TASKPOSTER = DOMAIN + '/mxd/taskposter/close';
export let URL_GET_TASKPOSTER_LTM = DOMAIN + '/mxd/taskposter/ltm';
export let URL_START_TASKPOSTER = DOMAIN + '/mxd/taskposter/start';
export let URL_STAS_TASKPOSTER = DOMAIN + '/mxd/taskposter/stas';
export let URL_PROMPTION_COUPONCODE = DOMAIN + '/mxd/promption/coupon_code';
export let URL_PROMPTION_STATIS_OLD = DOMAIN + '/mxd/promption/statis_old';
export let URL_PROMPTION_LOG_OLD = DOMAIN + '/mxd/promption/log_old';
export let URL_PROMPTION_STATIS = DOMAIN + '/mxd/promption/statis';
export let URL_PROMPTION_LOG = DOMAIN + '/mxd/promption/log';
export let URL_PROMPTION_LOGIN_STATUS = DOMAIN + '/mxd/promption/login_status';
export let URL_PROMPTION_SIGNIN_QRCODE = DOMAIN + '/mxd/promption/signin_qrcode?qrid=';
export let URL_PROMPTION_SIGNIN_CHECK = DOMAIN + '/mxd/promption/signin_check';
export let URL_PROMPTION_LOGOUT = DOMAIN + '/mxd/promption/logout';
export let URL_PROMPTION_FXQRCODE = DOMAIN + '/mxd/promption/fxqrcode';
export let URL_TASKPOSTER_ACTIVEFANS = DOMAIN + '/mxd/taskposter/activefan/list';
export let URL_GET_TASKPOSTER_SELECTLIST = DOMAIN + '/mxd/taskposter/selectlist';
export let URL_GET_TASKPOSTER_DETAIL = DOMAIN + '/mxd/taskposter/detail';
export let URL_TASKPOSTER_ACTIVEFANS_STAS = DOMAIN + '/mxd/taskposter/activefan/stas';
export let URL_TASKPOSTER_ACTIVEFANS_SEARCH = DOMAIN + '/mxd/taskposter/activefan/search';
export let URL_UPDATE_TASKPOSTER_ACTIVEFANS_PRIZE = DOMAIN + '/mxd/taskposter/activefan/prize';
export let URL_UPDATE_TASKPOSTER_ACTIVEFANS_REMARK = DOMAIN + '/mxd/taskposter/activefan/remark';
export let URL_GET_TASKPOSTER_ACTIVEFANS_FORMDATA = DOMAIN + '/mxd/taskposter/activefan/formdata';
export let URL_TASKPOSTER_BASESTAS = DOMAIN + '/mxd/taskposter/base_stas';
export let URL_TASKPOSTER_STAT_TOTAL = DOMAIN + '/mxd/taskposter/stat/total';
export let URL_TASKPOSTER_STAT_PART = DOMAIN + '/mxd/taskposter/stat/part';
export let URL_TASKPOSTER_STAT_FANS_PERIOD = DOMAIN + '/mxd/taskposter//stats/todaydetail';
export let URL_TASKPOSTER_STAT_FANSLEVELNUM = DOMAIN + '/mxd/taskposter/stat/fansLevelNum';
export let URL_TASKPOSTER_STAT_ACTIVITY = DOMAIN + '/mxd/taskposter/stat/activity';
export let URL_TASKPOSTER_STAT_CHANNEL = DOMAIN + '/mxd/taskposter/stats/channel';
export let URL_TASKPOSTER_CHANNEL_SUBS_LIST = DOMAIN + '/mxd/taskposter/channel_subs_list';
export let URL_STOP_MSGTASK = DOMAIN + '/mxd/template_msg/task/stop';
export let URL_START_MSGTASK = DOMAIN + '/mxd/template_msg/task/start';
export let URL_SHARETREASURE_UPLOADMATCHIMG = DOMAIN + '/mxd/sharetreasure/upload/matchimg';
export let URL_ADD_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/add';
export let URL_MODIFY_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/modify';
export let URL_MANUALPASS_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/pass';
export let URL_SHARETREASURE_CLOSE_START = DOMAIN + '/mxd/sharetreasure/close/start';
export let URL_GET_SHARETREASURE_LIST = DOMAIN + '/mxd/sharetreasure/list';
export let URL_GET_SHARETREASURE_SELECTLIST = DOMAIN + '/mxd/sharetreasure/selectlist';
export let URL_DELETE_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/delete';
export let URL_CLOSE_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/close';
export let URL_START_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/start';
export let URL_GET_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/get';
export let URL_SEARCH_SHARETREASURE = DOMAIN + '/mxd/sharetreasure/search';
export let URL_GET_SHARETREASURE_DETAIL = DOMAIN + '/mxd/sharetreasure/detail';
export let URL_SHARETREASURE_FANLOG = DOMAIN + '/mxd/sharetreasure/fan_stlog';
export let URL_SHARETREASURE_LIST_SUBSTITLE = DOMAIN + '/mxd/sharetreasure/list/substitle';
export let URL_SEARCH_SHARETREASURE_FANLOG = DOMAIN + '/mxd/sharetreasure/fan_stlog/search';
export let URL_ADD_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/add';
export let URL_CHANNELQRCODE_SUBSLIST = DOMAIN + '/mxd/channelqrcode/subslist';
export let URL_EDIT_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/edit';
export let URL_ADD_CHANNELQRCODE_GROUP = DOMAIN + '/mxd/channelqrcode/group/add';
export let URL_GET_CHANNELQRCODE_GROUPS = DOMAIN + '/mxd/channelqrcode/group/list';
export let URL_GET_CHANNELQRCODE_GROUPS1 = DOMAIN + '/mxd/channelqrcode/group/withqrcount/list';
export let URL_GET_CHANNELQRCODE_LIST = DOMAIN + '/mxd/channelqrcode/list';
export let URL_DELETE_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/delete';
export let URL_DELETE_CHANNELQRCODE_GROUP = DOMAIN + '/mxd/channelqrcode/group/delete';
export let URL_CHANNELQRCODE_MV2GROUP = DOMAIN + '/mxd/channelqrcode/movetogroup';
export let URL_GET_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/get';
export let URL_GET_CHANNELQRCODE_FANS = DOMAIN + '/mxd/channelqrcode/fanslist';
export let URL_DOWNLOAD_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/download?id=';
export let URL_DOWNLOAD_GROUP_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/group/download?id=';
export let URL_MODIFY_CHANNELQRCODE_NAME = DOMAIN + '/mxd/channelqrcode/name';
export let URL_MODIFY_CHANNELQRCODE_GROUPNAME = DOMAIN + '/mxd/channelqrcode/group/name';
export let URL_SEARCH_CHANNELQRCODE = DOMAIN + '/mxd/channelqrcode/search';
export let URL_SEARCH_CHANNELQRCODE_FANS = DOMAIN + '/mxd/channelqrcode/fans/search';
export let URL_CHANNELQRCODE_STATS_TOTAL = DOMAIN + '/mxd/channelqrcode/stats/total';
export let URL_CHANNELQRCODE_STATS_CHART = DOMAIN + '/mxd/channelqrcode/stats/chart';
export let URL_CHANNELQRCODE_STATS_EXPORT = DOMAIN + '/mxd/channelqrcode/stats/export';
export let URL_COMMON_UPLOADIMG = DOMAIN + '/mxd/common/uploadimg';
export let URL_WXMEDIA_ADDNEWS = DOMAIN + '/mxd/wx_media/add_news';
export let URL_WXMEDIA_EDITNEWS = DOMAIN + '/mxd/wx_media/edit_news';
export let URL_WXMEDIA_DELETENEWS = DOMAIN + '/mxd/wx_media/delete_news';
export let URL_GET_WXMEDIA_NEWS = DOMAIN + '/mxd/wx_media/news';
export let URL_SWITCH_SUBS = DOMAIN + '/mxd/user/switch_subs';
export let URL_TASKPOSTER_FANSSUPPORT = DOMAIN + '/mxd/taskposter/activefan/fanssupport';
export let URL_ADD_FORM = DOMAIN + '/mxd/form/add';
export let URL_MODIFY_FORM = DOMAIN + '/mxd/form/modify';
export let URL_GET_FORM_LIST = DOMAIN + '/mxd/form/query';
export let URL_GET_ALLFORM_LIST = DOMAIN + '/mxd/form/listall';
export let URL_GET_FORM = DOMAIN + '/mxd/form/get';
export let URL_DELETE_FORM = DOMAIN + '/mxd/form/delete';
export let URL_FORM_DATA = DOMAIN + '/mxd/form/data';
export let URL_GET_UNBOUND_FORM = DOMAIN + '/mxd/form/unbound';
export let URL_GET_LISTHEAD_FORM = DOMAIN + '/mxd/form/listhead';
export let URL_GET_DATA_FORMID_FORM = DOMAIN + '/mxd/form/data/formId';
export let URL_POST_EXPORT_FORM = DOMAIN + '/mxd/form/export';
export let URL_GET_PROMOINFO_FORM = DOMAIN + '/mxd/form/promoinfo';
export let URL_POST_SAVESELECT_FORM = DOMAIN + '/mxd/form/saveselect';
export let URL_POST_BROWSE_FORM = DOMAIN + '/mxd/form/browse';

export let URL_ADD_REDEEMCODE = DOMAIN + '/mxd/redeemcode/add';
export let URL_APPEND_REDEEMCODE = DOMAIN + '/mxd/redeemcode/append';
export let URL_GET_REDEEMCODE_LIST = DOMAIN + '/mxd/redeemcode/query';
export let URL_DELETE_REDEEMCODE = DOMAIN + '/mxd/redeemcode/delete';
export let URL_GET_ALLREDEEMCODE_LIST = DOMAIN + '/mxd/redeemcode/listall';
export let URL_REDEEMCODE_DATA = DOMAIN + '/mxd/redeemcode/data';
export let URL_GET_UNBOUND_REDEEMCODE = DOMAIN + '/mxd/redeemcode/unbound';
export let URL_GET_BATCH_DELETE_REDEEMCODE = DOMAIN + '/mxd/redeemcode/data/batch_delete';
export let URL_DEL_TEMPLATETASK = DOMAIN + '/mxd/template_msg/task/delete';
export let URL_TP_CHANNEL_LIST = DOMAIN + '/mxd/taskposter/channel/list';
export let URL_MODIFY_TPCHANNEL_NAME = DOMAIN + '/mxd/taskposter/channel/name';
export let URL_DOWNLOAD_TPCHANNELQRCODE = DOMAIN + '/mxd/taskposter/channel/qrdl/';
export let URL_CREATE_TPCHANNEL = DOMAIN + '/mxd/taskposter/channel';
export let URL_TP_STATS_TOTAL = DOMAIN + '/mxd/taskposter/stats/total';
export let URL_TP_STATS_ABPOSTER_DATA = DOMAIN + '/mxd/taskposter/stats/abposter/data';
export let URL_TP_STATS_DAYDETAIL = DOMAIN + '/mxd/taskposter/stats/daydetail';
export let URL_GET_SUBS_LIST = DOMAIN + '/mxd/subs/list';
export let URL_TASKPOSTER_CHANNEL_DELETE = DOMAIN + '/mxd/taskposter/channel/delete';

// 任务宝-标签
export let URL_TASKPOSTER_QUERY = DOMAIN + '/mxd/taskposter/query';
export let URL_TASKPOSTER_GROUP_list = DOMAIN + '/mxd/taskposter/group/list';
export let URL_TASKPOSTER_GROUP_CREATE = DOMAIN + '/mxd/taskposter/group/create';
export let URL_TASKPOSTER_GROUP_EDIT = DOMAIN + '/mxd/taskposter/group/edit';
export let URL_TASKPOSTER_GROUP_DELETE = DOMAIN + '/mxd/taskposter/group/delete';
export let URL_TASKPOSTER_GROUP_MOVETO = DOMAIN + '/mxd/taskposter/moveto-group';

export let URL_GET_SHOPPING_LIST = DOMAIN + '/mxd/shopping/list';
export let URL_SHOPPING_DELETE = DOMAIN + '/mxd/shopping/delete';
export let URL_SHOPPING_ADD = DOMAIN + '/mxd/shopping/add';
export let URL_SHOPPING_AMOUNT = DOMAIN + '/mxd/shopping/amount';
export let URL_SHOPPING_PAY_PRODUCT = DOMAIN + '/mxd/shopping/select/detail';
export let URL_CREATE_ORDER = DOMAIN + '/mxd/order/create';
export let URL_CANCEL_ORDER = DOMAIN + '/mxd/order/cancle';
export let URL_ESSENTIAL = DOMAIN + '/mxd/order/essential';
export let URL_ORDER_LIST = DOMAIN + '/mxd/order/list';
export let URL_ORDER_DETAIL = DOMAIN + '/mxd/order/detail';
export let URL_SUBMIT_PAYVOUCHER = DOMAIN + '/mxd/order/submit/payVoucher';
export let URL_ORDER_STATUS = DOMAIN + '/mxd/order/status';
export let URL_IMAGES_UPLOAD = DOMAIN + '/mxd/images/upload';
export let URL_WXPAY_QRCODE = DOMAIN + '/mxd/wxpay/payqrcode';
export let URL_GET_NOBIND_TASKGOODS = DOMAIN + '/mxd/goods/notaskposter/list';

export let URL_GOODS_LIST = DOMAIN + '/mxd/goods/list'
export let URL_GOODS_DETAIL_QRCODE = DOMAIN + '/mxd/goods/detail/qrcode'
export let URL_GOODS_DETELE = DOMAIN + '/mxd/goods/delete'

export let URL_GOODSORDER_GOODNAME_LIST = DOMAIN + "/mxd/goodsOrder/goodsName/list"
export let URL_GOODSORDER_LIST = DOMAIN + "/mxd/goodsOrder/list"
export let URL_GOODSORDER_FANSORDER_LIST = DOMAIN + "/mxd/goodsOrder/fansOrder/list"
export let URL_GOODSORDER_DETAIL = DOMAIN + "/mxd/goodsOrder/detail"
export let URL_GOODSORDER_DELIVER = DOMAIN + "/mxd/goodsOrder/deliver"
export let URL_GOODSORDER_EXPORT = DOMAIN + "/mxd/goodsOrder/export"

// 商品添加
export let UEL_GOODS_ADD = DOMAIN + '/mxd/goods/add';
export let URL_GOODS_FORMAT_LIST = DOMAIN + '/mxd/goods/format/list';
export let URL_GOODS_FORMAT_ADD = DOMAIN + '/mxd/goods/format/add';
export let URL_GOODS_FORMAT_EDIT = DOMAIN + '/mxd/goods/format/edit';
export let URL_GOODS_PREVIEW = DOMAIN + '/mxd/goods/preview';
export let URL_GOODS_GET = DOMAIN + '/mxd/goods/get'
export let URL_GOODS_EDIT = DOMAIN + '/mxd/goods/edit'
export let URL_TP_TEMPLATE_CHECK = DOMAIN + '/mxd/taskposter/template_check';
export let URL_TP_BATCH_TEMPLATE_CHECK = DOMAIN + '/mxd/taskposter/batch_template_check';
export let URL_GOODS_NAMEUNIQUE = DOMAIN + '/mxd/goods/nameUnique';

// 首页
export let URL_HOME_PRODUCT_NOEXPIRE = DOMAIN + "/mxd/home/product/willexpire"
export let URL_HOME_ORDER_DATA = DOMAIN + "/mxd/home/order/data"
export let URL_HOME_TASKPOSTER_STATS_ALL_YESTERDAY = DOMAIN + "/mxd/taskposter/stats/all/yesterday"


// 黑名单
export let URL_BLACKLIST_ADD = DOMAIN + '/mxd/taskposter/blacklist/add';
export let URL_BLACKLIST_REMOVE = DOMAIN + '/mxd/taskposter/blacklist/remove';
export let URL_BLACKLIST_LISTALL = DOMAIN + '/mxd/taskposter/blacklist/listall';
export let URL_TASKPOSTER_BLACKLIST = DOMAIN + '/mxd/taskposter/activefan/list/inblacklist';
export let URL_TASKPOSTER_CHANNEL_CREATE = DOMAIN + '/mxd/taskposter/channel/add';
export let URL_TASKPOSTER_CHANNEL_EDIT = DOMAIN + '/mxd/taskposter/channel/modify';
export let URL_TP_CHANNEL_SUBSCHECK = DOMAIN + '/mxd/taskposter/channel/subscheck';
export let URL_TP_CHANNEL_EDIT = DOMAIN + '/mxd/taskposter/channel/';

// 头部导航
export let URL_NOTICE_LIST = DOMAIN + '/mxd/home/notice/list';
export let URL_NOTICE_READ = DOMAIN + '/mxd/home/notice/read';

// 续费
export let URL_ORDER_RENEW = DOMAIN + '/mxd/shopping/renew';
export let URL_ORDER_RENEW_ADD = DOMAIN + '/mxd/order/renew/add';

// 任务宝导出
export let URL_ACTIVEFAN_EXPORT = DOMAIN + '/mxd/taskposter/activefan/export';
export let URL_ACTIVEFAN_SUPPORT_EXPORT = DOMAIN + '/mxd/taskposter/activefan/supportExport';
export let URL_ACTIVEFAN_TAG = DOMAIN + '/mxd/taskposter/activefan/batchTagging';
export let URL_TASKPOSTER_STATS_EXPORT = DOMAIN + '/mxd/taskposter/stats/daydetail/export';

// 百度编辑器上传图片
export let URL_UEDITOEUPLOAD = DOMAIN + '/mxd/ueditor/ueditorUpload.do';

// 官网首页
export let URL_WEBSITE = DOMAIN + '/home.html';

// 进群宝-任务
export let URL_ROOMTASK_LIST = DOMAIN_QUN + '/wqun/roomtask/list';
export let URL_ROOMTASK_KICKCONFIG = DOMAIN_QUN + '/wqun/roomtask/kickInfo';
export let URL_ROOMTASK_NAMEUNIQUE = DOMAIN_QUN + '/wqun/roomtask/nameUnique';
export let URL_ROOMTASK_TASKDETAIL = DOMAIN_QUN + '/wqun/roomtask/taskDetail';
export let URL_ROOMTASK_ADD = DOMAIN_QUN + '/wqun/roomtask/add';
export let URL_ROOMTASK_EDIT = DOMAIN_QUN + '/wqun/roomtask/edit';
export let URL_ROOMTASK_DEL = DOMAIN_QUN + '/wqun/roomtask/delete';
export let URL_ROOMTASK_CHANGEPASS = DOMAIN_QUN + '/wqun/roomtask/changePass';
export let URL_ROOMTASK_INROOMCOUNTSET = DOMAIN_QUN + '/wqun/roomtask/inroomCountSetting';
export let URL_ROOMTASK_CHANGEAUTOADDOPEN = DOMAIN_QUN + '/wqun/roomtask/changeAutoAddOpen';
export let URL_ROOMTASK_EDITAUTOADDROOM = DOMAIN_QUN + '/wqun/roomtask/editAutoAddRoom';
export let URL_ROOMTASK_DISTINCTMEM = DOMAIN_QUN + '/wqun/roomtask/distinctMem';
export let URL_ROOMTASK_CONFIGS = DOMAIN_QUN + '/wqun/roomtask/configs';
export let URL_ROOMTASK_ROOMTAGS = DOMAIN_QUN + '/wqun/roomtask/roomTags';
export let URL_ROOMTASK_MANUALADDROOM = DOMAIN_QUN + '/wqun/roomtask/manualAddRoom';

// 群聊管理
export let URL_ROOM_LIST = DOMAIN_QUN + '/wqun/room/list';
export let URL_ROOM_TRANSFEROWNER = DOMAIN_QUN + '/wqun/room/transferOwner';
export let URL_ROOM_ROMOVEROBOT = DOMAIN_QUN + '/wqun/room/removeRobot';
export let URL_ROOM_DISSOLVE = DOMAIN_QUN + '/wqun/room/dissolve';
export let URL_TOOM_BULLETINMODIFY = DOMAIN_QUN + '/wqun/room/bulletinModify';
export let URL_ROOM_CHANGEAVAILABLE = DOMAIN_QUN + '/wqun/room/changeAvailable';
export let URL_ROOM_CHANGEVERIFY = DOMAIN_QUN + '/wqun/room/changeVerify';
export let URL_ROOM_CHANGEAUTOFEE = DOMAIN_QUN + '/wqun/room/changeAutoFee';
export let URL_ROOM_WORKERS = DOMAIN_QUN + '/wqun/room/allWorkers';
export let URL_ROOM_NAMEMODIFY = DOMAIN_QUN + '/wqun/room/nameModify';
export let URL_ROOM_CHANGEADFILTER = DOMAIN_QUN + '/wqun/room/changeAdFilter';
export let URL_ROOM_PULLROBOT = DOMAIN_QUN + '/wqun/room/pullRobot';
export let URL_ROOM_SETTINGWORKER = DOMAIN_QUN + '/wqun/room/settingWorker';
export let URL_ROOM_NOTIFY_ADDWORKERROOM = DOMAIN_QUN + '/wqun/room/notify/addWorkerRoom'

// 群分组
export let URL_ROOMGROUP_LIST = DOMAIN_QUN + '/wqun/roomGroup/list';
export let URL_ROOMGROUP_UNIQUENAME = DOMAIN_QUN + '/wqun/roomGroup/uniqueName';
export let URL_ROOMGROUP_ADD = DOMAIN_QUN + '/wqun/roomGroup/add';
export let URL_ROOMGROUP_DISTRIBUTE = DOMAIN_QUN + '/wqun/roomGroup/distribute';
export let URL_ROOMGROUP_EDIT = DOMAIN_QUN + '/wqun/roomGroup/edit';

// 群成员
export let URL_ROOMMEM_ROOMEMLIST = DOMAIN_QUN + '/wqun/roommem/roomemList';
export let URL_ROOMTASK_ROOMNAMELIST = DOMAIN_QUN + '/wqun/roomtask/roomnameList';
export let URL_ROOMMEM_SETTINGWORKER = DOMAIN_QUN + '/wqun/roommem/settingWorker';
export let URL_ROOMMEM_CANCLEWORKER = DOMAIN_QUN + '/wqun/roommem/cancleWorker';
export let URL_ROOMMEM_VERIFIEDROOMMEM = DOMAIN_QUN + '/wqun/roommem/audit';
export let URL_ROOMMEM_WARNINGWORKER = DOMAIN_QUN + '/wqun/roommem/warningRoommem';
export let URL_ROOMMEM_KICKOUTROOM = DOMAIN_QUN + '/wqun/roommem/kickOutRoom';

// 群聊黑名单
export let URL_BLACKLIST_LIST = DOMAIN_QUN + '/wqun/blacklist/list';
export let URL_USER_TASKROOMS = DOMAIN_QUN + '/wqun/roomtask/infoList';
export let URL_BLACKLIST_REMOVEBLACKLIST = DOMAIN_QUN + '/wqun/blacklist/removeBlacklist';

// 数据统计
export let URL_DATA_ROOMTASK_TOTALCOUNT = DOMAIN_QUN + '/wqun/data/roomtask/totalCount';
export let URL_DATA_ROOMTASK_COLUMNAR = DOMAIN_QUN + '/wqun/data/roomtask/columnar';
export let URL_DATA_ROOMTASK_CHART = DOMAIN_QUN + '/wqun/data/roomtask/chart';
export let URL_DATA_ROOMTASK_LIST = DOMAIN_QUN + '/wqun/data/roomtask/list';

// 机器人
export let URL_ROBOT_DATA = DOMAIN_QUN + '/wqun/robot/data';
export let URL_ROBOT_LIST = DOMAIN_QUN + '/wqun/robot/list';
export let URL_ROBOT_ADD = DOMAIN_QUN + '/wqun/robot/add';
export let URL_ROBOT_REMOVE = DOMAIN_QUN + '/wqun/robot/remove';

// 群发
export let URL_GROUPSENDMSG_LIST = DOMAIN_QUN + '/wqun/groupSendMsg/list';
export let URL_GROUPSENDMSG_DETAIL = DOMAIN_QUN + '/wqun/groupSendMsg/detail';
export let URL_GROUPSENDMSG_SENDDETAIL = DOMAIN_QUN + '/wqun/groupSendMsg/sendDetail';
export let URL_GROUPSENDMSG_DETELE = DOMAIN_QUN + '/wqun/groupSendMsg/delete';
export let URL_GROUPSENDMSG_OPEN = DOMAIN_QUN + '/wqun/groupSendMsg/open';
export let URL_GROUPSENDMSG_STOP = DOMAIN_QUN + '/wqun/groupSendMsg/stop';
export let URL_GROUPSENDMSG_SELECT_ROOMLIST = DOMAIN_QUN + '/wqun/groupSendMsg/select/roomList';
export let URL_GROUPSENDMSG_ADD = DOMAIN_QUN + '/wqun/groupSendMsg/add';
export let URL_GROUPSENDMSG_TITLEUNIQUE = DOMAIN_QUN + '/wqun/groupSendMsg/titleUnique';
export let URL_GROUPSENDMSG_GROUP_ROOMLIST = DOMAIN_QUN + '/wqun/groupSendMsg/group/roomList';
export let URL_GROUPSENDMSG_ADDROOMLIST = DOMAIN_QUN + '/wqun/groupSendMsg/addRoomList';
export let URL_GROUPSENDMSG_TASK_ROOMLIST = DOMAIN_QUN + '/wqun/groupSendMsg/task/roomList';

// 上传文件
export let URL_DISTRI_GOODS_AUDIO = DOMAIN + '/mxd/upload/audio';
export let URL_DISTRI_GOODS_UPLOAD = DOMAIN + '/mxd/mfs/upload';

// 分销
export let URL_DISTRI_GOODS_ADD = DOMAIN + '/mxd/distri/goods/add';
export let URL_DISTRI_GOODS_MODIFY = DOMAIN + '/mxd/distri/goods/modify';
export let URL_DISTRI_GOODS = DOMAIN + '/mxd/distri/goods';
export let URL_DISTRI_GOODS_QUERY = DOMAIN + '/mxd/distri/goods/query';
export let URL_DISTRI_GOODS_STATUS = DOMAIN + '/mxd/distri/goods/status';
export let URL_DISTRI_GOODS_DELETE = DOMAIN + '/mxd/distri/goods/delete';
export let URL_DISTRI_GOODS_PROMIINFO = DOMAIN + '/mxd/distri/goods/promoinfo';
export let URL_DISTRI_GOODS_SELECTLIST = DOMAIN + '/mxd/distri/goods/selectlist';
export let URL_DISTRI_INCOME = DOMAIN + '/mxd/distri/income';
export let URL_DISTRI_STATFLOW = DOMAIN + '/mxd/distri/statflow';
export let URL_DISTRI_RANKING = DOMAIN + '/mxd/distri/ranking';
export let URL_DISTRI_RECORD = DOMAIN + '/mxd/distri/inv_record';
export let URL_DISTRI_REDEEMCODELIST = DOMAIN + '/mxd/distri/redeemcodelist';
export let URL_DISTRI_ACCOUNTCASH = DOMAIN + '/mxd/distri/account_cash';
export let URL_DISTRI_WITHDRAW_APPLY = DOMAIN + '/mxd/distri/withdraw/apply';
export let URL_DISTRI_WITHDRAW_LIST = DOMAIN + '/mxd/distri/withdraw/list';
export let URL_DISTRI_QRCODE = DOMAIN + '/mxd/distri/subspayer/qrcode';
export let URL_DISTRI_GETINFO = DOMAIN + '/mxd/distri/subspayer/info';
export let URL_DISTRI_FEERATE = DOMAIN + '/mxd/distri/feerate';
export let URL_USER_LEVEL = DOMAIN + '/mxd/distri/viplevel';
export let URL_ORDERCHECKER_LIST = DOMAIN + '/mxd/orderchecker/list';
export let URL_ORDERCHECKER_AUTH_QRCODE = DOMAIN + '/mxd/orderchecker/auth_qrcode';
export let URL_ORDERCHECKER_AUTH_INFO = DOMAIN + '/mxd/orderchecker/auth_info';
export let URL_ORDERCHECKER_DELETE = DOMAIN + '/mxd/orderchecker/delete';
export let URL_ORDERCHECKER_ADD = DOMAIN + '/mxd/orderchecker/add';
export let URL_ORDERCHECKER_ORDERLIST = DOMAIN + '/mxd/orderchecker/orderlist';
export let URL_ORDERCHECKER_SELECTLIST = DOMAIN + '/mxd/orderchecker/selectlist';
export let URL_DISTRI_EXPORT = DOMAIN + '/mxd/distri/order/export';


// 分销渠道码
export let URL_DISTRI_CHANNEL_PROMOINFO = DOMAIN + '/mxd/distri/channel/promoinfo';
export let URL_DISTRI_CHANNEL_LIST = DOMAIN + '/mxd/distri/channel/list';
export let URL_DISTRI_CHANNEL_EDIT = DOMAIN + '/mxd/distri/channel/edit';
export let URL_DISTRI_CHANNEL_CREATE = DOMAIN + '/mxd/distri/channel/create';
export let URL_DISTRI_CHANNEL_SELECTLIST = DOMAIN + '/mxd/distri/channel/selectlist';
export let URL_DISTRI_CHANNEL_DELETE = DOMAIN + '/mxd/distri/channel/delete';
export let URL_DISTRI_CHANNEL = DOMAIN + '/mxd/distri/channel';

// 分销消息模板
export let URL_DISTRI_NOTICECONFIG = DOMAIN + '/mxd/distri/noticeconfig/list';
export let URL_DISTRI_NOTICECONFIG_DEL = DOMAIN + '/mxd/distri/noticeconfig/delete';
export let URL_DISTRI_NOTICECONFIG_ADD = DOMAIN + '/mxd/distri/noticeconfig/add';
export let URL_DISTRI_NOTICECONFIG_EDIT = DOMAIN + '/mxd/distri/noticeconfig/edit';
export let URL_DISTRI_NOTICECONFIG_DETAIL = DOMAIN + '/mxd/distri/noticeconfig/detail';

// 推送
export let URL_PUSH_TASK_ADD = DOMAIN + '/mxd/pushfansMsg/task/add';
export let URL_PUAH_TASK_DETAIL = DOMAIN + '/mxd/pushfansMsg/task/detail';
export let URL_PUSH_TASK_MODIFY = DOMAIN + '/mxd/pushfansMsg/task/modify';
export let URL_PUSH_TASK_TITLEUNQUIUE = DOMAIN + '/mxd/pushfansMsg/task/titleUnqiue';
export let URL_PUSH_TASK_LIST = DOMAIN + '/mxd/pushfansMsg/task/list';
export let URL_PUSH_TASK_DEL = DOMAIN + '/mxd/pushfansMsg/task/delete';
export let URL_PUSH_TASK_CHANGEOPEN = DOMAIN + '/mxd/pushfansMsg/task/changeOpen';
export let URL_PUSH_TSAK_LOG = DOMAIN + '/mxd/pushfansMsg/push/log';
export let URL_PUSH_TSAK_LOG_EXPORT = DOMAIN + '/mxd/pushfansMsg/push/log/export';
export let URL_PUSH_TSAK_PREVIEW = DOMAIN + '/mxd/pushfansMsg/task/preview';

// 拼团
export let URL_GROUPBUY_GOODS_ADD = DOMAIN + '/mxd/groupbuy/goods/add';
export let URL_GROUPBUY_GOODS_MODIFY = DOMAIN + '/mxd/groupbuy/goods/modify';
export let URL_GROUPBUY_GOODS_DETAIL = DOMAIN + '/mxd/groupbuy/goods';
export let URL_GROUPBUY_GOODS_LIST = DOMAIN + '/mxd/groupbuy/goods/query';
export let URL_GROUPBUY_GOODS_STATUS = DOMAIN + '/mxd/groupbuy/goods/status';
export let URL_GROUPBUY_GOODS_DELETE = DOMAIN + '/mxd/groupbuy/goods/delete';
export let URL_GROUPBUY_GOODS_PROMOINFO = DOMAIN + '/mxd/groupbuy/goods/promoinfo';
export let URL_GROUPBUY_GOODS_SELECTLIST = DOMAIN + '/mxd/groupbuy/goods/selectlist';
export let URL_GROUPBUY_ORDER_LIST = DOMAIN + '/mxd/groupbuy/order/list';
export let URL_GROUPBUY_ORDER_DETAIL = DOMAIN + '/mxd/groupbuy/order';
export let URL_GROUPBUY_ORDER_DELIVER = DOMAIN + '/mxd/groupbuy/order/deliver';
export let URL_GROUPBUY_ORDER_SELECTLIST = DOMAIN + '/mxd/groupbuy/goods/selectlist';
export let URL_GROUPBUY_ACCOUNT_CASH = DOMAIN + '/mxd/groupbuy/account_cash';
export let URL_GROUPBUY_WITHDREW_LIST = DOMAIN + '/mxd/groupbuy/withdraw/list';
export let URL_GROUPBUY_SUBSPAYER_INFO = DOMAIN + '/mxd/groupbuy/subspayer/info';
export let URL_GROUPBUY_WITHDRAW_APPLY = DOMAIN + '/mxd/groupbuy/withdraw/apply';
export let URL_GROUPBUY_REDEEMCODELIST = DOMAIN + '/mxd/groupbuy/redeemcodelist';
export let URL_GROUPBUY_NOTICECONFIG = DOMAIN + '/mxd/groupbuy/noticeconfig/list';
export let URL_GROUPBUY_NOTICECONFIG_DEL = DOMAIN + '/mxd/groupbuy/noticeconfig/delete';
export let URL_GROUPBUY_NOTICECONFIG_ADD = DOMAIN + '/mxd/groupbuy/noticeconfig/add';
export let URL_GROUPBUY_NOTICECONFIG_EDIT = DOMAIN + '/mxd/groupbuy/noticeconfig/edit';
export let URL_GROUPBUY_NOTICECONFIG_DETAIL = DOMAIN + '/mxd/groupbuy/noticeconfig/detail';
export let URL_GROUPBUY_LEVEL = DOMAIN + '/mxd/groupbuy/viplevel';
export let URL_GROUPBUY_EXPORT = DOMAIN + '/mxd/groupbuy/order/export';


// 活码

// 通用
export let URL_LIVECODE_PROMOINFO = DOMAIN + '/mxd/livecode/promoinfo';
export let URL_LIVECODE_DELETE = DOMAIN + '/mxd/livecode/delete';
export let URL_LIVECODE_STATUS = DOMAIN + '/mxd/livecode/status';

// 客服码
export let URL_LIVECODE_CUSTOMER_CREATE = DOMAIN + '/mxd/livecode/kf/code_create';
export let URL_LIVECODE_CUSTOMER_EDIT = DOMAIN + '/mxd/livecode/kf/code_edit';
export let URL_LIVECODE_CUSTOMER_LIST = DOMAIN + '/mxd/livecode/kf/code_list';
export let URL_LIVECODE_CUSTOMER_DETAIL = DOMAIN + '/mxd/livecode/kf/code_detail';
export let URL_LIVECODE_CUSTOMER_QRCODE_LIST = DOMAIN + '/mxd/livecode/kf/qrcode_list';
export let URL_LIVECODE_CUSTOMER_QRCODE_ACTIVE = DOMAIN + '/mxd/livecode/kf/qrcode_active';
export let URL_LIVECODE_CUSTOMER_QRCODE_Add = DOMAIN + '/mxd/livecode/kf/qrcode_add';
export let URL_LIVECODE_CUSTOMER_QRCODE_EDIT = DOMAIN + '/mxd/livecode/kf/qrcode_edit';
export let URL_LIVECODE_CUSTOMER_QRCODE_REPLACE = DOMAIN + '/mxd/livecode/kf/qrcode_replace';
export let URL_LIVECODE_CUSTOMER_QRCODE_DELETE = DOMAIN + '/mxd/livecode/kf/qrcode_delete';
export let URL_LIVECODE_CUSTOMER_QRCODE_SCANLIMIT = DOMAIN + '/mxd/livecode/kf/qrcode_scanlimit';
export let URL_LIVECODE_CUSTOMER_INDEX = DOMAIN + '/mxd/livecode/kf/qrcode_index';
export let URL_LIVECODE_CUSTOMER_QRCODE_CLOSE = DOMAIN + '/mxd/livecode/kf/qrcode_close';
export let URL_LIVECODE_CUSTOMER_QRCODE_OPEN = DOMAIN + '/mxd/livecode/kf/qrcode_open';

// 群活码
export let URL_LIVECODE_GROUP_TAGS_LIST = DOMAIN + '/mxd/livecode/qun/tag_list';
export let URL_LIVECODE_GROUP_TAGS_CREATE = DOMAIN + '/mxd/livecode/qun/tag_create';
export let URL_LIVECODE_GROUP_TAGS_DEL = DOMAIN + '/mxd/livecode/qun/tag_delete';
export let URL_LIVECODE_GROUP_TAGS_EDIT = DOMAIN + '/mxd/livecode/qun/tag_edit';
export let URL_LIVECODE_GROUP_CREATE = DOMAIN + '/mxd/livecode/qun/code_create';
export let URL_LIVECODE_GROUP_EDIT = DOMAIN + '/mxd/livecode/qun/code_edit';
export let URL_LIVECODE_GROUP_LIST = DOMAIN + '/mxd/livecode/qun/code_list';
export let URL_LIVECODE_GROUP_DETAIL = DOMAIN + '/mxd/livecode/qun/code_detail';
export let URL_LIVECODE_GROUP_QRCODE_LIST = DOMAIN + '/mxd/livecode/qun/qrcode_list';
export let URL_LIVECODE_GROUP_QRCODE_ACTIVE = DOMAIN + '/mxd/livecode/qun/qrcode_active';
export let URL_LIVECODE_GROUP_QRCODE_ADD = DOMAIN + '/mxd/livecode/qun/qrcode_add';
export let URL_LIVECODE_GROUP_QRCODE_EDIT = DOMAIN + '/mxd/livecode/qun/qrcode_edit';
export let URL_LIVECODE_GROUP_QRCODE_REPLACE = DOMAIN + '/mxd/livecode/qun/qrcode_replace';
export let URL_LIVECODE_GROUP_QRCODE_DELETE = DOMAIN + '/mxd/livecode/qun/qrcode_delete';
export let URL_LIVECODE_GROUP_QRCODE_SCANLIMIT = DOMAIN + '/mxd/livecode/qun/qrcode_scanlimit';
export let URL_LIVECODE_GROUP_QRCODE_EXPIRE = DOMAIN + '/mxd/livecode/qun/qrcode_expire';
export let URL_LIVECODE_LEVEL = DOMAIN + '/mxd/livecode/vippay';
export let URL_LIVECODE_GROUP_INDEX = DOMAIN + '/mxd/livecode/qun/qrcode_index';
export let URL_LIVECODE_GROUP_QRCODE_CLOSE = DOMAIN + '/mxd/livecode/qun/qrcode_close';
export let URL_LIVECODE_GROUP_QRCODE_OPEN = DOMAIN + '/mxd/livecode/qun/qrcode_open';

// 数据统计
export let URL_LIVECODE_ALLCODE = DOMAIN + '/mxd/livecode/allcode';
export let URL_LIVECODE_STAT_TREND = DOMAIN + '/mxd/livecode/stat/trend';
export let URL_LIVECODE_STAT_AREADISTRI = DOMAIN + '/mxd/livecode/stat/arealdistri';

// 自定义菜单
export let URL_SUBSMEMU_SELECTMENU = DOMAIN + '/mxd/subsmenu/selectmenu'
export let URL_SUBSMEMU_CREATEMENU = DOMAIN + '/mxd/subsmenu/createmenu'
export let URL_SUBSMEMU_SELECTONE = DOMAIN + '/mxd/subsmenu/selectone'
export let URL_SUBSMEMU_GXHLIST = DOMAIN + '/mxd/subsmenu/gxhlist'
export let URL_SUBSMEMU_EDITSTATU = DOMAIN + '/mxd/subsmenu/editstatu'
export let URL_SUBSMEMU_DELMENU = DOMAIN + '/mxd/subsmenu/delmenu'
export let URL_SUBSMEMU_EDITMENU = DOMAIN + '/mxd/subsmenu/editmenu'
export let URL_SUBSMEMU_SELECTAUTOREPLY = DOMAIN + '/mxd/subsmenu/selectAutoreply'
export let URL_SUBSMEMU_AUTOREPLY = DOMAIN + '/mxd/subsmenu/autoreply'
export let URL_SUBSMEMU_DELALLMENU = DOMAIN + '/mxd/subsmenu/delallmenu'

export let URL_GET_MSG_TEMPLATE_SUB_ONTRIAL = DOMAIN + '/mxd/subs/ontrial';

// 购买中心
export let URL_PRODUCT_PURLIST = DOMAIN + '/mxd/product/purlist';
export let URL_PRODUCT_PRICELIST = DOMAIN + '/mxd/product/pricelist';
export let URL_PRODUCT_PAYAMOUNT = DOMAIN + '/mxd/product/payamount';


export let URL_HELP_GETDOC = DOMAIN + "/mxd/help/getdoc"
export let URL_HELP_GETCONTENT = DOMAIN + "/mxd/help/getcontent"
export let URL_HELP_LISTMENU = DOMAIN + "/mxd/help/listmenu"


export let NOHEADIMG = 'https://static.94mxd.com.cn/images/wx_noheadimg.png';


// 导航栏
export let URL_NAV_CONFIG = DOMAIN + "/mxd/nav/config";


// 企业微信
export let URL_CORP_CUSFISSION_QUERY = DOMAIN + "/mxd/corp/cusfission/query";
export let URL_CORP_CUSFISSION_STATUS = DOMAIN + "/mxd/corp/cusfission/status";
export let URL_CORP_CUSFISSION_DELETE = DOMAIN + "/mxd/corp/cusfission/delete";
export let URL_CORP_APP_AUTH_URL = DOMAIN + "/mxd/corp/app/auth_uri";
export let URL_CORP_APP_AGENT_CONFIG = DOMAIN + "/mxd/corp/app/agent_config";
export let URL_CORP_CUSFISSION_CREATE = DOMAIN + '/mxd/corp/cusfission/create';
export let URL_CORP_CUSFISSION_EDIT = DOMAIN + '/mxd/corp/cusfission/edit';
export let URL_CORP_CUSFISSION_DETAIL = DOMAIN + '/mxd/corp/cusfission/detail';
export let URL_CORP_MEMBER_FISSION_LIST = DOMAIN + '/mxd/corp/member/fission_list';
export let URL_CORP_APP_AUTH_LIST = DOMAIN + '/mxd/corp/app/auth_list';
export let URL_CORP_MEMBER_LIST = DOMAIN + "/mxd/corp/member/list";
export let URL_CORP_MEMBER_BATCH_FISSION = DOMAIN + "/mxd/corp/member/batch_fission";
export let URL_CORP_CUSFISSION_CHANNEK_SUBS_LIST = DOMAIN + '/mxd/corp/cusfission/channel_subs_list';
export let URL_CORP_CUSFISSION_SELECTLIST = DOMAIN + '/mxd/corp/cusfission/selectlist';
export let URL_CORP_MEMBER_SYNC = DOMAIN + "/mxd/corp/member/sync";
export let URL_CORP_CUSFISSION_STATS_TOTAL = DOMAIN + "/mxd/corp/cusfission/stats/total";
export let URL_CORP_CUSFISSION_STATS_DAYDETAIL = DOMAIN + "/mxd/corp/cusfission/stats/daydetail";
export let URL_CORP_CUSFISSION_STATS_TODAYDETAIL = DOMAIN + "/mxd/corp/cusfission/stats/todaydetail";
export let URL_CORP_CUSFISSION_STATS_DAYDETAIL_EXPORT = DOMAIN + "/mxd/corp/cusfission/stats/daydetail/export";
export let URL_CORP_CUSFISSION_ACTIVEFAN_LIST = DOMAIN + '/mxd/corp/cusfission/activefan/list';
export let URL_CORP_CUSFISSION_ACTIVEFAN_SEARCH = DOMAIN + '/mxd/corp/cusfission/activefan/search';
export let URL_CORP_CUSFISSION_ACTIVEFAN_PRIZE = DOMAIN + '/mxd/corp/cusfission/activefan/prize';
export let URL_CORP_CUSFISSION_ACTIVEFAN_REMARK = DOMAIN + '/mxd/corp/cusfission/activefan/remark';
export let URL_CORP_CUSFISSION_ACTIVEFAN_STAS = DOMAIN + '/mxd/corp/cusfission/activefan/stas';
export let URL_CORP_CUSFISSION_ACTIVEFAN_FANSSUPPORT = DOMAIN + '/mxd/corp/cusfission/activefan/fanssupport';
export let URL_CORP_CUSFISSION_ACTIVEFAN_BATCHTAGGING = DOMAIN + '/mxd/corp/cusfission/activefan/batchTagging';
export let URL_CORP_CUSFISSION_ACTIVEFAN_EXPORT = DOMAIN + '/mxd/corp/cusfission/activefan/export';
export let URL_GET_CUSFISSION_LTM = DOMAIN + '/mxd/corp/cusfission/ltm';
export let URL_CORP_MEMBER_SEARCH = DOMAIN + '/mxd/corp/member/search';


/*物流查询*/
export let URL_LOGISTICS_CREATE = DOMAIN + '/mxd/logistics/create';
export let URL_LOGISTICS_DETAIL = DOMAIN + '/mxd/logistics/detail';
export let URL_LOGISTICS_EDIT = DOMAIN + '/mxd/logistics/edit';


// 物流
export let URL_LOGISTICS_LIST = DOMAIN + '/mxd/logistics/list';
export let URL_LOGISTICS_OPENURL = DOMAIN + '/mxd/logistics/openurl';
export let URL_LOGISTICS_ALLOPENURL = DOMAIN + '/mxd/logistics/allopenurl';
export let URL_LOGISTICS_DELETE = DOMAIN + '/mxd/logistics/delete';
export let URL_LOGISTICS_DATA_APPEND = DOMAIN + '/mxd/logistics/data/append';
export let URL_LOGISTICS_DATA_LIST = DOMAIN + '/mxd/logistics/data/list';
export let URL_LOGISTICS_DATA_DELETE = DOMAIN + '/mxd/logistics/data/delete';
export let URL_LOGISTICS_DATA_CUSTOM_FIELD = DOMAIN + '/mxd/logistics/data/custom_field';
export let URL_GET_TASKPOSTER_LINKINFO = DOMAIN + '/mxd/taskposter/linkinfo';

//节日签
export let URL_QUERY_FESTIVAL_ACTIVITYLIST = DOMAIN + '/mxd/festivalsign/activity_list';
export let URL_DELETE_FESTIVAL_ACTIVITYLIST = DOMAIN + '/mxd/festivalsign/activity_del';
export let URL_CREATE_FESTIVAL_ACTIVITY = DOMAIN + '/mxd/festivalsign/activity_create';
export let URL_EDIT_FESTIVAL_ACTIVITY = DOMAIN + '/mxd/festivalsign/activity_edit';
export let URL_MODIFY_FESTIVAL_ACTIVITY_STATUS = DOMAIN + '/mxd/festivalsign/activity_status';
export let URL_GET_FESTIVAL_ACTIVITY_DETAIL = DOMAIN + '/mxd/festivalsign/activity_detail';
export let URL_GET_GOODSORDER_CHECKER_LIST = DOMAIN + '/mxd/goodsOrder/checker/list';
export let URL_GOODSORDER_CHECKER_AUTH_QRCODE = DOMAIN + '/mxd/goodsOrder/checker/auth_qrcode';
export let URL_GOODSORDER_CHECKER_AUTH_INFO = DOMAIN + '/mxd/goodsOrder/checker/auth_info';
export let URL_ADD_GOODSORDER_CHECKER = DOMAIN + '/mxd/goodsOrder/checker/add';
export let URL_DEL_GOODSORDER_CHECKER = DOMAIN + '/mxd/goodsOrder/checker/delete';
export let URL_QUERY_GOODSCHECKER_ORDER = DOMAIN + '/mxd/goodsOrder/checker/order/query';
export let URL_GET_GOODSCHECKER_NAMELIST = DOMAIN + '/mxd/goodsOrder/checker/namelist';
export let URL_GET_GOODSORDER_DELIVER_RECORDLIST = DOMAIN + '/mxd/goodsOrder/bulkdeliver/recordlist';
export let URL_DEL_GOODSORDER_DELIVER_RECORD = DOMAIN + '/mxd/goodsOrder/bulkdeliver/delete';
export let URL_QUERY_GOODSORDER_LIST = DOMAIN + '/mxd/goodsOrder/query';
export let URL_GET_GOODSORDER_DATASTATS = DOMAIN + '/mxd/goodsOrder/dataStats';
export let URL_GET_GOODSORDER_BUYERINFO = DOMAIN + '/mxd/goodsOrder/buyerInfo';
export let URL_DELIVER_GOODSORDER = DOMAIN + '/mxd/goodsOrder/deliver';
export let URL_EDIT_GOODSORDER_DELIVERINFO = DOMAIN + '/mxd/goodsOrder/deliverInfo';
export let URL_UPLOAD_GOODSORDER_BULKDELIVER_DATA = DOMAIN + '/mxd/goodsOrder/bulkdeliver/uploadData';
export let URL_DOWNLOAD_GOODSORDER_BULKDELIVER_FAILDATA = DOMAIN + '/mxd/goodsOrder/bulkdeliver/downloadFail';
export let URL_GET_SYSTEM_UPGRADE_NEWCOUNT = DOMAIN + '/mxd/sysupgrade/newcount';
export let URL_VIEWCILCK_SYSTEM_UPGRADE = DOMAIN + '/mxd/sysupgrade/viewclick';

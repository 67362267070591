import React from 'react';
import {Redirect, Route} from "react-router-dom";

import loadable from "../../loadable";

const ActivityList = loadable(() => import('./activity/activity_list.bundle'));
const ActivityAdd = loadable(() => import('./activity/activity_add.bundle'));
const CashList = loadable(() => import('./cash/cash_record.bundle'));
const CashOut = loadable(() => import('./cash/cash_out.bundle'));
const OrderList = loadable(() => import('./order/order_list.bundle'));
const OrderDetail = loadable(() => import('./order/order_detail.bundle'));
const RedeemCode = loadable(() => import('./redeemcode/redeemcode.bundle'));
const GroupMsg = loadable(() => import('./msg/msg.bundle'));
const GroupMsgAdd = loadable(() => import('./msg/msg_add.bundle'));

/**
 * 店铺
 */
class Groupbuy extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/list"}/>
                )}/>
                <Route path={this.props.match.url + "/list"} render={(props) => {
                    props.type = 'create';
                    return <ActivityList {...props}/>
                }}/>
                <Route path={this.props.match.url + "/create"} render={(props) => {
                    props.type = 'create';
                    return <ActivityAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <ActivityAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/copy/:id"} render={(props) => {
                    props.type = 'copy';
                    return <ActivityAdd {...props}/>
                }}/>
                <Route path={this.props.match.url + "/cash/list"} component={CashList}/>
                <Route path={this.props.match.url + "/cash/out"} component={CashOut}/>
                <Route path={this.props.match.url + "/order/list/:id?"} component={OrderList}/>
                <Route path={this.props.match.url + "/order/detail/:id"} component={OrderDetail}/>
                <Route path={this.props.match.url + "/code/:id"} component={RedeemCode}/>
                <Route path={this.props.match.url + "/msg"} exact component={GroupMsg}/>

                <Route path={this.props.match.url + "/msg/create"} render={(props) => {
                    props.type = 'create';
                    return <GroupMsgAdd {...props}/>
                }}/>

                <Route path={this.props.match.url + "/msg/edit/:id"} render={(props) => {
                    props.type = 'edit';
                    return <GroupMsgAdd {...props}/>
                }}/>

            </div>
        );
    }
}

export default Groupbuy
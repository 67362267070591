import React from 'react'
import styles from './find_pwd.module.css'
import signflowStyles from './signflow.module.css'
import Footer from './footer'
import {InputErrMask, InputNullMask, InputRegCodeErrMask} from './inputmask'
import Util, {checkEmail} from "../utils";
import {URL_FP_CHECK_ACCOUNT, URL_GET_VERIFY_CODE} from "../variables";
import {FetchUtil} from "../fetch_util";
import {Path} from "../path";
import {Link} from "react-router-dom";
import {MsgBox} from "../common/msg_box";

let fetchUtil = new FetchUtil();

class FindPwd extends React.Component {

    constructor(props) {
        super(props);
        this.checkSuccess = this.checkSuccess.bind(this);
        this.back = this.back.bind(this);
        this.state = {
            checkSuccess: false,
            email: ''
        }
    }

    checkSuccess(email, code) {
        this.setState({
            checkSuccess: true,
            email: email,
            code: code
        })
    }

    back() {
        this.setState({
            checkSuccess: false
        })
    }

    render() {
        return (
            <div>
                {this.state.checkSuccess ?
                    <CheckEmailSuccess email={this.state.email} code={this.state.code} back={this.back}/> :
                    <CheckEmailForm checkSuccess={this.checkSuccess}/>}
                <Footer/>
            </div>
        )
    }
}

class CheckEmailSuccess extends React.Component {

    constructor(props) {
        super(props);
        this.reSend = this.reSend.bind(this);
        this.state = {
            expire: false
        }
    }

    reSend() {
        fetchUtil.init()
            .setUrl(URL_FP_CHECK_ACCOUNT)
            .setMethod('POST')
            .setCookieCors()
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setBody({
                email: this.props.email,
                verifyCode: this.props.code
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    MsgBox.showSuccessMsg_2s("发送成功")
                } else if (msg.respCode == 1023) {
                    this.setState({
                        expire: true
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    expire: true
                })
            });
    }

    render() {
        return (
            <div className={signflowStyles.container + ' ' + styles.fpFormContainer}>
                <div className={signflowStyles.logoWhite}>
                </div>
                <div className={signflowStyles.signContainer}>
                    <div className={signflowStyles.formTitle}>忘记密码</div>
                    <div>
                        <p className={styles.checkSuccessText}>
                            我们已向您的注册邮箱<br/>
                            {Util.coverEmail(this.props.email)}<br/>
                            发送了一封重置密码的邮件
                        </p>
                        <a href={"http://" + Util.getEmailLink(this.props.email)} target="_blank">
                            <button className={"mxd-btn mxd-btn-primary " + signflowStyles.signBtn}>进入邮箱</button>
                        </a>
                    </div>
                    <div className={signflowStyles.signSwitch}>
                        <Link className="pull-left" to={Path.getSigninPath()}>返回登录</Link>
                        {
                            this.state.expire ?
                                <span className="pull-right">验证过期！<a onClick={this.props.back}>重新验证</a></span> :
                                <span className="pull-right">没有收到？<a onClick={this.reSend}>重新发送</a></span>
                        }
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        )
    }
}

class CheckEmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.showInputEmail = this.showInputEmail.bind(this);
        this.hideEmailMask = this.hideEmailMask.bind(this);
        this.showInputDigits = this.showInputDigits.bind(this);
        this.hideDigitsMask = this.hideDigitsMask.bind(this);
        this.submit = this.submit.bind(this);
        this.email = this.email.bind(this);
        this.digits = this.digits.bind(this);
        this.showInputEmailErr = this.showInputEmailErr.bind(this);
        this.changeVerifyCode = this.changeVerifyCode.bind(this);

        this.state = {
            email: '',
            digits: '',
            inputEmailMsg: '请输入邮箱',
            inputEmailErrMsg: '',
            inputEmail: false,
            inputEmailErr: false,
            inputDigits: false,
            inputDigitsErr: false,
            inputDigitsMsg: '请输入图片中的数字或字母',
            inputDigitsErrMsg: '验证码填写错误',
            randomNum: Math.random()
        }
    }

    showInputEmail() {
        if (this.state.email.length == 0) {
            this.setState({
                inputEmail: true
            });
        }
    }

    showInputEmailErr(errMsg) {
        this.setState({
            inputEmailErr: true,
            inputEmailErrMsg: errMsg
        });
    }

    hideEmailMask() {
        this.setState({
            inputEmail: false,
            inputEmailErr: false
        });
        this.refs.email.focus();
    }

    showInputDigits() {
        if (this.state.digits.length == 0) {
            this.setState({
                inputDigits: true
            });
        }
    }

    showInputDigitsErr() {
        this.setState({
            inputDigitsErr: true
        });
    }

    hideDigitsMask() {
        this.setState({
            inputDigits: false,
            inputDigitsErr: false
        });
        this.refs.digits.focus();
    }

    email(e) {
        let value = e.target.value;
        this.setState({
            email: value
        });
    }

    digits(e) {
        let value = e.target.value;
        this.setState({
            digits: value
        });
    }

    changeVerifyCode(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({
            randomNum: Math.random()
        })
    }

    submit(event) {
        var digits = this.state.digits;
        var email = this.state.email;
        if (email.length == 0) {
            this.showInputEmail();
            return;
        }
        if (!checkEmail(email)) {
            this.showInputEmailErr('请输入正确的邮箱');
            return;
        }
        if (digits.length == 0) {
            this.showInputDigits();
            return;
        }
        //请求后台给手机发送验证码
        var target = $(event.target);
        Util.disabledBtn(target, true);
        fetchUtil.init()
            .setUrl(URL_FP_CHECK_ACCOUNT)
            .setMethod('POST')
            .setCookieCors()
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setBody({
                email: email,
                verifyCode: digits
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    this.props.checkSuccess(email, digits);
                } else if (msg.respCode == 1022) {
                    this.showInputEmailErr('账户不存在');
                } else if (msg.respCode == 1023) {
                    this.showInputDigitsErr();
                }
                Util.disabledBtn(target, false);
            })
            .catch((error) => {
                Util.disabledBtn(target, false);
            });
    }

    render() {
        var inputEmailNullMask = this.state.inputEmail ? <InputNullMask errMsg={this.state.inputEmailMsg}/> : '';
        var inputEmailErrMask = this.state.inputEmailErr ? <InputErrMask errMsg={this.state.inputEmailErrMsg}/> : '';
        var inputDigitsNullMask = this.state.inputDigits ? <InputNullMask errMsg={this.state.inputDigitsMsg}/> : '';
        var inputDigitsErrMask = this.state.inputDigitsErr ?
            <InputRegCodeErrMask errMsg={this.state.inputDigitsErrMsg}/> : '';
        return (
            <div className={signflowStyles.container + ' ' + styles.fpFormContainer}>
                <div className={signflowStyles.logoWhite}>
                </div>
                <div className={signflowStyles.signContainer}>
                    <div className={signflowStyles.formTitle}>忘记密码</div>
                    <div>
                        <div className={signflowStyles.inputWraper} onClick={this.hideEmailMask}>
                            <input ref="email" type="email" placeholder="您的邮箱" className={signflowStyles.signInput}
                                   onBlur={this.showInputEmail} onChange={this.email}/>
                            {inputEmailNullMask}
                            {inputEmailErrMask}
                        </div>
                        <div className={signflowStyles.inputWraper} onClick={this.hideDigitsMask}>
                            <input ref="digits" type="text" placeholder="图片中的字母或数字"
                                   className={signflowStyles.signInput + ' ' + styles.verifycodeInput}
                                   onBlur={this.showInputDigits} onChange={this.digits}/>
                            {inputDigitsNullMask}
                            {inputDigitsErrMask}
                            <a className={styles.verifycode} onClick={this.changeVerifyCode}>
                                <img src={URL_GET_VERIFY_CODE + this.state.randomNum}/>
                            </a>
                        </div>
                        <div>
                            <button
                                className={"mxd-btn mxd-btn-primary " + signflowStyles.signBtn}
                                onClick={this.submit}>验证邮箱
                            </button>
                        </div>
                        <div className={signflowStyles.signSwitch}>
                            <Link to={Path.getSigninPath()}>返回登录</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FindPwd
import React from 'react'
import styles from './promotion.module.css'
import {FetchUtil} from '../fetch_util'
import {URL_PROMPTION_FXQRCODE, URL_PROMPTION_LOG, URL_PROMPTION_LOGOUT, URL_PROMPTION_STATIS} from "../variables";
import Pagination from '../common/pagination'
import ImgLogoWhite from '../../images/logo.svg'
import Util from "../utils";
import {Icon, Popover, Table} from "antd";

let fetchUtil = new FetchUtil();

class FxHome extends React.Component {

    constructor(props) {
        super(props);
        this.getLog = this.getLog.bind(this);
        this.state = {
            totalReg: 0,
            totalConver: 0,
            totalDeal: 0,
            totalCashback: 0,
            todayReg: 0,
            todayConver: 0,
            todayDeal: 0,
            todayCashback: 0,
            pageInfo: null,
            desc: '',
            headimgurl: ''
        }
    }

    componentDidMount() {
        Util.initScroll(this.refs.contentScroll);
        fetchUtil.init()
            .setMethod('GET')
            .setUrl(URL_PROMPTION_STATIS)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    var respMsg = msg.respMsg;
                    this.setState({
                        totalReg: respMsg.totalReg,
                        totalConver: respMsg.totalConver,
                        totalDeal: respMsg.totalDeal,
                        totalCashback: respMsg.totalCashback,
                        todayReg: respMsg.todayReg,
                        todayConver: respMsg.todayConver,
                        todayDeal: respMsg.todayDeal,
                        todayCashback: respMsg.todayCashback,
                        desc: respMsg.desc,
                        headimgurl: respMsg.headimgurl && respMsg.headimgurl.length > 0 ? respMsg.headimgurl : 'https://static.94mxd.com.cn/images/wx_noheadimg.png',
                    })
                }
            })
            .catch(() => {

            })
        this.getLog(1);
    }

    exit() {
        fetchUtil.init()
            .setUrl(URL_PROMPTION_LOGOUT)
            .setMethod('POST')
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                window.location.reload();
            })
            .catch(() => {

            })
    }

    getLog(pageNum) {
        fetchUtil.init()
            .setMethod('GET')
            .setUrl(URL_PROMPTION_LOG + '?pageNum=' + pageNum)
            .setCookieCors()
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    var respMsg = msg.respMsg;
                    if (respMsg) {
                        for (var i = 0; i < respMsg.list.length; i++) {
                            var prom = respMsg.list[i]
                            if (prom.regDate) {
                                prom.regDate = new Date(prom.regDate).format('yyyy-MM-dd')
                            }
                            if (prom.payDate) {
                                prom.payDate = new Date(prom.payDate).format('yyyy-MM-dd');
                            }
                            if (prom.itemType) {
                                if (prom.itemType == 8000) {
                                    prom.itemType = '消息通'
                                } else if (prom.itemType == 8004 || prom.itemType == 8006) {
                                    prom.itemType = '任务宝'
                                } else if (prom.itemType == 8010) {
                                    prom.itemType = '分享宝'
                                } else if (prom.itemType == 8012) {
                                    prom.itemType = '渠道码'
                                }
                            }
                        }
                        this.setState({
                            pageInfo: respMsg
                        })
                    }
                }
            })
            .catch(() => {
            })
    }

    render() {
        const Columns = [
            {
                title: '公众号名称',
                align: 'center',
                render: (text, record, index) => (
                    <span>{record.appName}</span>
                )
            },
            {
                align: 'center',
                title: '注册日期',
                render: (text, record, index) => (
                    <span>{record.regDate}</span>
                )
            },
            {
                align: 'center',
                title: '付费日期',
                render: (text, record, index) => (
                    <span>{record.payDate ? record.payDate : '-'}</span>
                )
            },
            {
                align: 'center',
                title: '购买时长',
                render: (text, record, index) => (
                    <span>{record.duration ? record.duration + '个月' : '-'}</span>
                )
            },
            {
                align: 'center',
                title: '付费金额',
                render: (text, record, index) => (
                    <span>{record.price ? '¥' + (record.price / 100) : '-'}</span>
                )
            },
            {
                align: 'center',
                title: '产品类型',
                render: (text, record, index) => (
                    <span>{record.itemType ? record.itemType : '-'}</span>
                )
            },
            {
                align: 'center',
                title: '返点金额',
                render: (text, record, index) => (
                    <span>{record.cashback ? '¥' + record.cashback : '-'}</span>
                )
            }
        ];
        return (
            <div className={styles.bg} ref="contentScroll">
                <div>
                    <div className={styles.header}>
                        <img width="120px" height="48px" src={ImgLogoWhite}/>
                        <div className={styles.headerRight}>
                            <ul>
                                <li className={"dropdown"}>
                                    <a className="dropdown-toggle" data-toggle="dropdown" role="button"
                                       aria-haspopup="true"
                                       aria-expanded="false"><span><img className={styles.avatar}
                                                                        src={this.state.headimgurl}/></span><span
                                        className={"mxd-caret caret"}></span></a>
                                    <ul className="dropdown-menu mxd-dropdown-menu dropdown-menu-right">
                                        <li>
                                            <a className="menu-item-xs" href={URL_PROMPTION_FXQRCODE}>下载推广二维码</a>
                                        </li>
                                        <li>
                                            <a className="cursor-pointer menu-item-xs" onClick={this.exit}>退出</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.title}>
                        {this.state.desc && this.state.desc.length > 0 ? this.state.desc + ' | ' : ''}推广统计
                    </div>
                    <div className={styles.stats}>
                        <div className="row">
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>累计注册量</h3>
                                    <p>{this.state.totalReg}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>累计转化量</h3>
                                    <p>{this.state.totalConver}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>累计成交金额</h3>
                                    <p>{this.state.totalDeal / 100}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>累计返点金额</h3>
                                    <p>{this.state.totalCashback}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>今日注册量</h3>
                                    <p>{this.state.todayReg}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>今日转化量</h3>
                                    <p>{this.state.todayConver}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>今日成交金额</h3>
                                    <p>{this.state.todayDeal / 100}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={styles.dataItem}>
                                    <h3>今日返点金额</h3>
                                    <p>{this.state.todayCashback}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.tc}>
                            {/*<table className="mxd-table">*/}
                            {/*<thead>*/}
                            {/*<tr>*/}
                            {/*<th>公众号名称</th>*/}
                            {/*<th>注册日期</th>*/}
                            {/*<th>付费日期</th>*/}
                            {/*<th>购买时长</th>*/}
                            {/*<th>付费金额</th>*/}
                            {/*<th>产品类型</th>*/}
                            {/*<th>返点金额</th>*/}
                            {/*</tr>*/}
                            {/*</thead>*/}
                            {/*<tbody>*/}
                            {/*{*/}
                            {/*this.state.pageInfo && this.state.pageInfo.list.length > 0 ? this.state.pageInfo.list.map(function (data, i) {*/}
                            {/*return (*/}
                            {/*<tr>*/}
                            {/*<td>{data.appName}</td>*/}
                            {/*<td>{data.regDate}</td>*/}
                            {/*<td>{data.payDate ? data.payDate : '-'}</td>*/}
                            {/*<td>{data.duration ? data.duration + '个月' : '-'}</td>*/}
                            {/*<td>{data.price ? '¥' + (data.price / 100) : '-'}</td>*/}
                            {/*<td>{data.itemType ? data.itemType : '-'}</td>*/}
                            {/*<td>{data.cashback ? '¥' + data.cashback : '-'}</td>*/}
                            {/*</tr>*/}
                            {/*)*/}
                            {/*}) : ''*/}
                            {/*}*/}
                            {/*</tbody>*/}
                            {/*</table>*/}
                            {/*{*/}
                            {/*this.state.pageInfo && this.state.pageInfo.list.length == 0 ?*/}
                            {/*<div className="mxd-norecord">没有记录！</div> : ''*/}
                            {/*}*/}
                            {/*<div className="clearfix"></div>*/}
                            {/*<Pagination id="fxPag" pagInfo={this.state.pageInfo} toPage={this.getLog}/>*/}
                            {this.state.pageInfo ?
                                <Table columns={Columns}
                                       dataSource={this.state.pageInfo.list}
                                       pagination={{
                                           total: this.state.pageInfo ? this.state.pageInfo.total : 1,
                                           current: this.state.pageInfo ? this.state.pageInfo.pageNum : 1,
                                           hideOnSinglePage: true,
                                           onChange: this.getLog,
                                           defaultPageSize: 6
                                       }}
                                ></Table> : ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FxHome
import React from 'react'
import {Icon, Select} from 'antd';
import {Path} from '../../path'

const Option = Select.Option;

/**
 * 公众号下拉菜单选择器
 * 示例： <SubsSelector data={this.state.subs} defaultValue={0} onChange={this.subsChange}/>
 *
 */
class SubsSelector extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $("#mxdIndex").addClass("mxd-sidebar-nosecond");
        $('.mxd-sidebar-lmenu').removeClass('active');
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    render() {
        return (
            <div className='contentBody'>
                {/*<div className="comBread">*/}
                {/*<div className="ant-breadcrumb"><span><span className="ant-breadcrumb-link"><a*/}
                {/*href={Path.getPurchase()}>购买协议</a></span><span*/}
                {/*className="ant-breadcrumb-separator">/</span></span>*/}
                {/*</div>*/}
                {/*</div>*/}
                <div className='mxd-panel minHeightNormal '>
                    <div className='comPurchase'>
                        <h3>「媒想到」产品购买及服务协议</h3>
                        <div>
                            <p>本服务条款是北京乐营科技有限公司与您就【媒想到】产品使用过程中相关事项所订立的有效合约。您通过网络页面点击确认或以其他方式选择接受本服务条款，即表示您与【媒想到】已达成协议并同意接受本服务条款的全部约定内容。</p>

                            <h4>一、产品相关</h4>
                            <p>1.【媒想到】产品目前主要包括任务宝、消息宝、分享宝、渠道码、节日签、分销、拼团、活码。
                                备注：任务宝、消息宝、分享宝仅支持「企业认证过的订阅号」和「企业认证过的服务号」，渠道码仅支持「企业认证过的服务号」，不支持未经过认证的服务号和订阅号、个人号；分销、拼团、活码无需绑定公众号，注册登录后，在有会员时长的前提下即可使用。
                            </p>
                            <h4>二、付费相关</h4>
                            <p>1.【媒想到】产品（任务宝、消息宝、分享宝、渠道码、节日签）收费是按单个公众号收费，不是按【媒想到】账号收费。即付费一次只能一个公众号使用；分销、拼团、活码是按照【媒想到】账号收费，付费一次只能一个媒想到账号使用。</p>
                            <p>2.购买【媒想到】（任务宝、消息宝、分享宝、渠道码、节日签）产品会员时长在一年以上，中间可更换公众号主体一次。不支持各产品之间VIP切换。例如：购买消息宝VIP一年，不可中途切换成任务宝VIP。分销、拼团、活码会员不支持更换账号。</p>
                            <p>3.服务费用将在您订购页面予以公示或由产品销售人员报价；您可在报价后选择支付方式：</p>
                            <p>乙方收款方式：</p>
                            <p>①：对公打款</p>
                            <p>②：支付宝支付</p>
                            <p>③：微信支付</p>
                            <p>4.乙方开通服务必须在甲方付款成功后，且付款成功后1月内有效；</p>
                            <p>5.付款成功后5个工作日内乙方为甲方提供由等额有效的增值税普通发票（如需专票请另行备注）。</p>
                            <h4>三、媒想到服务内容说明</h4>
                            <p>1.初期标准培训：乙方负责承担甲方所购买产品的开通指导和初期使用的业务指导培训。</p>
                            <p>2.服务方式：远程服务模式，即乙方通过电话、微信等方式网络方式提供服务；乙方承诺在甲方提出关于所购买【媒想到】产品软件的服务请求后，在1个工作日内给予响应并提供服务。
                            </p>
                            <p>3.服务时间： 乙方为甲方提供服务时间为每个工作日早九晚七，如遇休息日，乙方提供服务但不受本协议规定时间限制。
                            </p>
                            <h4>四、甲方权利及责任说明</h4>
                            <p>1.甲方完全拥有在使用本服务时录入数据和资料（包括但不限于企业资料、产品资料、服务资料等信息）的所有权。</p>
                            <p>2.甲方应确保有专人对【媒想到】系统的使用和管理负责。</p>
                            <p>3.甲方只能在自家公司商业范围内使用服务，禁止发送违反法律的信息，禁止发送和储存带有病毒的、蠕虫的、木马的和其他有害的计算机代码、文件、脚本和程序。</p>
                            <p>4.甲方自行对输入数据的准确性、可靠性、合法性、适用性等负责。</p>
                            <p>5.使用【媒想到】产品做活动期间，若出现由同行竞品恶意举报等行为导致的公众号功能被禁用、删粉、封号等情况，责任由甲方自行承担。</p>
                            <p>6.使用【媒想到】产品做活动期间，若甲方发布虚假活动被乙方发现，乙方有权停止甲方产品使用权并且不予退还产品会员费用；如若对乙方造成影响，
                                甲方承担乙方活动损失包括但不限于因此造成损失费及诉讼费等。</p>
                            <p>7.甲方在购买前请确认所购乙方产品功能是否满足使用需求，产品一旦售出， 不以无法满足甲方功能需求退款。</p>
                            <h4>五、保密条款</h4>
                            <p>本协议任何一方对在合作过程中所知悉或接触到的对方未向社会公开的商业秘密负有保密义务。未经对方书面许可，任何一方不得将其泄露给第三方，也不得用于本协议之外的任何其他用途，否则应承担违约责任并赔偿损失。在本协议终止后，双方在本协议项下的保密义务并不随之终止，双方仍需遵守本协议之保密条款，履行其所承诺的保密义务。
                            </p>
                            <h4>六、免责条款</h4>
                            <p>1.任何一方均不承担因不可抗力（指不能预见、不能克服且不能避免的客观情况，包括但不限于火灾、洪水、台风、雷击、地震等自然灾害，战争，暴乱，罢工，黑客攻击，电信部门临时技术管制、腾讯政策、阿里云服务器升级或故障等）所致延迟或无法履行本协议约定义务的法律责任。</p>
                            <p>2.【媒想到】产品版本升级，乙方会提前3天发布升级通告，甲方需自行调整活动时间以避免升级造成的不稳定等问题。<br/>
                                版本升级期间，甲方反馈问题后，乙方会在一个小时内处理解决（该时限仅限于工作日有效），所造成的其他后果不再承担任何责任。</p>
                            <p>3.如因【媒想到】产品后台非人为因素（如系统bug修复过程等）造成的不稳定，给甲方造成无法正常使用产品服务，乙方应为甲方1天补偿3天相同产品服务时长作为赔偿。</p>
                            <p>4.乙方不承担因甲方工作人员进行错误操作出现故障导致的数据混乱、丢失等责任。</p>
                            <p>5.在免费期间，乙方对产品提供有效性服务建议，但不对其中任何错误或漏洞提供任何担保，并不对甲方使用过程中的问题承担任何责任。</p>
                            <p>6.因人为因素（如知识产权问题等）造成乙方未按本协议履行义务造成的损失，乙方对本服务条款所承担责任的赔偿总额不超过违约对应产品的服务费总额。</p>
                            <h4>七、协议有效期和效用性</h4>
                            <p>1.本协议自甲方 付费起自动生效，具有法律效力。</p>
                            <p>2.本协议的有效性不因交易双方的职务变换、工作变更、工作调动等情况而受任何影响；本协议的有效性不因双方法人的变更而受任何影响；本协议的有效性不因双方单位名称的改变而受任何影响。</p>

                            <br/>

                            <div className='text-right'>
                                <p>北京乐营科技有限公司</p>
                                <p>2019年11月13日</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default SubsSelector
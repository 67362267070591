class Public {
    static name() {
        return '年'
    }

    static get_duration(m) {
        return parseFloat(m) / 12;
    }

    static proName(num) {
        if (num == 8000) {
            return '消息宝'
        }
        if (num == 8006) {
            return '任务宝'
        }
        if (num == 8010) {
            return '分享宝'
        }
        if (num == 8012) {
            return '渠道码'
        }
        if (num == 8014) {
            return '活码'
        }
        if (num == 9010) {
            return '拼团'
        }

        if (num == 9000) {
            return '分销'
        }

        if (num == 7000) {
            return '客户裂变'
        }
        if (num == 8016) {
            return '节日签'
        }
    }

    static proNum(name) {
        if (name == '消息宝') {
            return 8000;
        }
        if (name == '任务宝') {
            return 8006;
        }
        if (name == '分享宝') {
            return 8010;
        }
        if (name == '渠道码') {
            return 8012;
        }
        if (name == '活码') {
            return 8014
        }
        if (name == '拼团') {
            return 9010
        }
        if (name == '分销') {
            return 9000
        }

        if (name == '客户裂变') {
            return 7000;
        }

        if (name == '节日签') {
            return 8016
        }
    }

    static yhPrice(price) {
        price = parseFloat(price);
        if (isNaN(price) || price < 0) {
            price = 0;
        }
        let num = 0;
        if (price >= 6000) {
            num = 600
        }
        if (price >= 12000) {
            num = 3000
        }
        if (price >= 22000) {
            num = 6000
        }
        if (price >= 32000) {
            num = 12000
        }
        if (price >= 48000) {
            num = 21000
        }
        if (price >= 64000) {
            num = price / 2;
        }

        return num;
    }
}

export default Public;
import React from 'react'
import {URL_WEBSITE} from "../../../variables";
import Message from "../../../common/ant/Message";

class SidebarLogo extends React.Component {
    constructor(props) {
        super(props);

    }

    gotoHome(flag){
        if(flag){
            Message.warning("请先保存当前快捷菜单")
        }else{
            window.location.href = URL_WEBSITE
        }
    }

    render() {
        return (
            <a href="javascript:"
               onClick={this.gotoHome.bind(this,this.props.visible)}>
                <div className={'siteNavImg'}>
                    <i className={'siteNavIcon'}></i>
                </div>
            </a>
        )
    }

}

export default SidebarLogo;
import React from 'react'
import signStyles from '../signflow/signflow.module.css'
import styles from './promotion.module.css'
import Footer from "../signflow/footer";
import {URL_PROMPTION_SIGNIN_CHECK, URL_PROMPTION_SIGNIN_QRCODE} from "../variables";
import {FetchUtil} from '../fetch_util'

let fetchUtil = new FetchUtil();

class FxSignIn extends React.Component {

    constructor(props) {
        super(props);
        this.signInCheck = this.signInCheck.bind(this);
        this.qrid = this.guid();
    }

    componentDidMount() {
        this.signInCheck();
    }

    signInCheck() {
        fetchUtil.init()
            .setUrl(URL_PROMPTION_SIGNIN_CHECK)
            .setMethod('POST')
            .setCookieCors()
            .setBody({
                qrid: this.qrid
            })
            .dofetch()
            .then((msg) => {
                if (msg.respCode != 0) {
                    setTimeout(this.signInCheck, 2000)
                } else {
                    if (this.props.checkIn) {
                        this.props.checkIn();
                    }
                }
            })
            .catch(() => {
                setTimeout(this.signInCheck, 2000)
            })
    }

    guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
    }

    render() {
        return (
            <div className={signStyles.signBg}>
                <div className={signStyles.upperpart}></div>
                <div className={signStyles.container + ' ' + signStyles.signinContainer}>
                    <div className={signStyles.logoWhite}>
                    </div>
                    <div className={signStyles.signContainer}>
                        <div className={signStyles.formTitle}>扫码登录</div>
                        <div className={styles.signinQrcode}>
                            <img src={URL_PROMPTION_SIGNIN_QRCODE + this.qrid}/>
                        </div>
                        <div className={styles.tip}>微信扫码进入媒想到分销系统</div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default FxSignIn
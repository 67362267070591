class MxdModal {

    static show(select, options) {
        if ($(select).parent().hasClass('mxd-modal-wrapper')) {
            //直接显示
            $(select).parent().addClass('in');
        } else {
            var point = $('body');
            $(".mxd-modal-wrapper").children(select).each(function () {
                point = $(this).parent();
                $(this).remove();
            });
            var modal = document.querySelector(select);
            var close = $(select + ' .close');
            if (close) {
                close.click(function () {
                    var temp = this;
                    for (var i = 0; i < 10; i++) {
                        temp = temp.parentNode;
                        if (temp.classList.contains('mxd-modal-wrapper')) {
                            temp.classList.remove("in");
                            break;
                        }
                    }
                });
            }
            var canel = $(select + ' *[data-dismiss="modal"]');
            if (canel) {
                canel.click(function () {
                    var temp = this;
                    for (var i = 0; i < 10; i++) {
                        temp = temp.parentNode;
                        if (temp.classList.contains('mxd-modal-wrapper')) {
                            temp.classList.remove("in");
                            break;
                        }
                    }
                });
            }
            if (options) {
                if (options.style) {
                    $(select).css(options.style);
                }
                if (options.classname) {
                    $(select).addClass(options.classname);
                }
            }
            if (point.hasClass('mxd-modal-wrapper')) {
                point.append($(select));
                point.addClass('in');
            } else {
                var wrapper = document.createElement('div');
                wrapper.setAttribute('class', 'mxd-modal-wrapper in');
                wrapper.appendChild(modal);
                wrapper.addEventListener('click', function (e) {
                    if (e.target.className.indexOf('mxd-modal-wrapper') != -1) {
                        this.classList.remove("in");
                    }
                });
                point.append(wrapper);
            }
        }

        // var modal = document.querySelector(select);
        // if (modal.parentNode.classList.contains('mxd-modal-wrapper')) {
        //     modal.parentNode.classList.add("in");
        // } else {
        //
        // }
    }

    static hide(select) {
        $(select).parent().removeClass('in');
    }
}

export default MxdModal
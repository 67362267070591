import React from 'react'
import {Link} from "react-router-dom";
import {Path} from "../path";
import styles from './warn.module.css'
import Modal from "../common/modal";

class ProductExpired extends React.Component {

    close() {
        Modal.hide("#productExpiredModal");
    }

    render() {
        return (
            <div id="productExpiredModal" className="mxd-modal">
                <div className="mxd-modal-header">
                    <span className="close"></span>
                </div>
                <div className="mxd-modal-body">
                    <div className={styles.expiredText}>
                        <p>您的&nbsp;&nbsp;[<span id="expriedProductName"></span>]&nbsp;&nbsp;产品已过期</p>
                        <p>想要继续使用，请购买</p>
                    </div>
                    <div className={styles.expiredPur}>
                        <Link to={Path.getVipPurPath()} target="_blank">
                            <button className="mxd-btn mxd-btn-primary" data-dismiss="modal">立即购买</button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductExpired
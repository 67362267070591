import React from 'react';
import {Redirect, Route} from "react-router-dom";
import Purchase from './purchase/purchase'

import loadable from "../../loadable";

const Product = loadable(() => import('./order/order_add_list.bundle'));
const Order_list = loadable(() => import('./order/order_list.bundle'));
const Order_detail = loadable(() => import('./order/order_detail.bundle'));
const Pay = loadable(() => import('./order/order_pay.bundle'));
const Confirm = loadable(() => import('./order/order_pay_confirm.bundle'));
const Renew = loadable(() => import('./order/order_renew.bundle'));

class Mine extends React.Component {
    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.url} render={() => (
                    <Redirect to={this.props.match.url + "/order/add"}/>
                )}/>
                <Route exact path={this.props.match.url + '/order'} render={() => (
                    <Redirect to={this.props.match.url + "/order/add"}/>
                )}/>
                <Route exact path={this.props.match.url + '/order/add'} component={Product}/>
                <Route exact path={this.props.match.url + '/order/confirm'} component={Confirm}/>
                <Route exact path={this.props.match.url + '/order/pay'} component={Pay}/>
                <Route exact path={this.props.match.url + '/order/list'} component={Order_list}/>
                <Route exact path={this.props.match.url + '/order/detail'} component={Order_detail}/>
                <Route exact path={this.props.match.url + '/order/renew'} component={Renew}/>
                <Route path={this.props.match.url + "/purchase"} component={Purchase}/>
            </div>
        )
    }
}

export default Mine;
import React from "react"
import md5 from "js-md5";
import 'whatwg-fetch'
import BScroll from 'better-scroll';
import {Icon, Modal} from "antd";
import "./common/overdueTips/style/index.less"
import ProName from "./mine/order/public_set"
import {URL_CORP_APP_AGENT_CONFIG, URL_DISTRI_GOODS_UPLOAD} from "./variables";
import {FetchUtil} from "./fetch_util";
import WWOpenData from "./corp/member/common/member";
import ImgWXqrcode from '../images/welcome/kefu.png'
import Message from "./common/ant/Message";

const confirm = Modal.confirm
let expireData = [];
const corpid = "",
    provider_secret = ""


export function checkPhoneNo(phoneNo) {
    var phoneNoReg = /^1[3456789]\d{9}$/;
    if (!phoneNoReg.test(phoneNo)) {
        return false;
    }
    return true;
}

// 验证手机号和座机号
export function checkMobile(phoneNo) {
    var phoneNoReg = /^[\d-]+$/;
    if (!phoneNoReg.test(phoneNo)) {
        return false;
    }
    return true;
}

export function checkEmail(email) {
    var emailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,10}$/;
    if (!emailReg.test(email)) {
        return false;
    }
    return true;
}

//检查验证码是否合法
export function checkSmsCode(code) {
    var codeReg = /^\d{6}$/;
    if (!codeReg.test(code)) {
        return false;
    }
    return true;
}

export function checkPassword(pwd) {
    var pwdReg = /^(?![^a-zA-Z]+$)(?!\D+$).{8,}$/;
    if (!pwdReg.test(pwd)) {
        return false;
    }
    return true;
}

export function checkUrl(url) {
    var urlReg = /^((https?|ftp)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    // var urlReg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/gi;
    if (!urlReg.test(url)) {
        return false;
    }
    return true
}

export function hashPwd(pwd) {
    pwd = md5(pwd + 'Hq44cyp4mT9Fh5eNrZ67bjifidFhW%fb0ICjx#6gE59@P@Hr8%!WuYBa1yvytq$qh1FEM18qA8Hp9m3VLux9luIYpeYzA2l2W3Z');
    return pwd;
}

export function changeUrlArg(url, arg, arg_val) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[\?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
}

export function getUrlArg(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");

    var r = window.location.search.substr(1).match(reg);

    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

var oldFetchfn = fetch; //拦截原始的fetch方法
export function fetchTimeout(input, opts) {//定义新的fetch方法，封装原有的fetch方法
    var fetchPromise = oldFetchfn(input, opts);
    var timeoutPromise = new Promise(function (resolve, reject) {
        setTimeout(() => {
            reject(new Error("fetch timeout"))
        }, opts.timeout)
    });
    return Promise.race([fetchPromise, timeoutPromise])
}


class Util {
    // 直接裁剪到需要的字数
    static limitVal(str, limit) {
        var w = 0;
        if (str != '' && str != null) {
            for (var i = 0; i < str.length; i++) {
                var c = str.charCodeAt(i);
                if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                    w++;
                } else {
                    w += 3;
                }
                if (w > limit) {
                    str = str.substr(0, i);
                    break;
                }
            }
        }
        return str;
    }

    // 得到汉字和字母的总数
    static getStringLen(val) {
        var len = 0;
        for (var i = 0; i < val.length; i++) {
            var a = val.charAt(i);
            if (a.match(/[^\x00-\xff]/ig) != null) {
                len += 2;
            } else {
                len += 1;
            }
        }
        return len;
    }


    // 直接裁剪到需要的字数
    static limitValWx(val, limit) {
        var w = 0;
        if (val != '' && val != null) {
            var len = 0, flag = false
            for (var i = 0; i < val.length; i++) {
                let reg = /^[\u4e00-\u9fa5]+$/
                let regx = /^[a-zA-Z0-9]+$/
                if (reg.test(val[i])) {
                    len += 3;
                } else if (regx.test(val[i])) {
                    len++
                } else {
                    flag = true
                }
                if (len > limit) {
                    val = val.substr(0, i);
                    break;
                } else if (flag = true && (len + 3 > limit)) {
                    val = val.substr(0, i);
                    break;
                }
            }

            // console.log(len)
            // for (var i = 0; i < str.length; i++) {
            //     var c = str.charCodeAt(i);
            //     if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            //         w++;
            //     } else {
            //         w += 3;
            //     }
            //
            // }
        }
        return val;
    }

    // 得到汉字和字母的总数
    static getStringLenWx(val) {
        var len = 0, flag = false
        for (var i = 0; i < val.length; i++) {
            let reg = /^[\u4e00-\u9fa5]+$/
            let regx = /^[a-zA-Z0-9]+$/
            if (reg.test(val[i])) {
                len += 3;
            } else if (regx.test(val[i])) {
                len++
            } else {
                flag = true
            }
        }
        if (flag) {
            len += 3
        }

        return len;
    }


    static orderNumChange(num) {
        let str = num.toString(), unit = ""
        if (str.length >= 5 && str.length < 9) {
            str = parseFloat(num / 10000).toFixed(1)
            unit = "万"
        } else if (str.length >= 9) {
            str = parseFloat(num / 100000000).toFixed(1)
            unit = "亿"
        }
        return {
            str: str,
            unit: unit
        }
    }

    // 分，单位换算
    static centChange(num) {
        num = Number(num);
        if (isNaN(num) || num < 0) {
            num = 0;
        }
        let str = num.toString(), unit = "元";
        if (str.length >= 7 && str.length < 11) {
            str = parseFloat(num / 1000000).toFixed(2);
            unit = "万"
        } else if (str.length >= 11) {
            str = parseFloat(num / 10000000000).toFixed(2);
            unit = "亿"
        } else {
            str = parseFloat(num / 100).toFixed(2);
        }
        return {
            str: str,
            unit: unit
        }
    }

    // 分，换算成元
    static centTransformOut(num) {
        num = parseFloat(num) / 100;
        let price = 0;
        if (isNaN(num) || num < 0) {
            price = 0.00;
        } else {
            price = num;
        }

        price = price.toFixed(2);

        return price;
    }

    // 分换算成元，保留负数
    static centTransformOutMin(num) {
        num = parseFloat(num) / 100;
        let price = 0;
        if (isNaN(num)) {
            price = 0.00;
        } else {
            price = num;
        }

        price = price.toFixed(2);

        return price;
    }

    // 月转换成年
    static monthTransformOut(month) {
        let year = Number(month) / 12;
        return year;
    }


    static inputWordLimit(limit, e) {
        var v = e.target.value;
        if (v.length > limit) {
            e.target.value = v.substring(0, limit);
            $(e.target).siblings('.wordlimit').html(limit + "/" + limit)
        } else {
            $(e.target).siblings('.wordlimit').html(v.length + "/" + limit)
        }
    }

    static setIsSaveing(flag) {
        if (flag) {
            $("#saveBtn").html("保存中...");
            $("#saveBtn").attr("disabled", "true");
        } else {
            $("#saveBtn").html("保存");
            $("#saveBtn").removeAttr("disabled");
        }
    }

    static disabledBtn(select, disabled, text, disabledText) {
        if (disabled) {
            if (disabledText) {
                $(select).html(disabledText);
            }
            $(select).attr("disabled", "true");
        } else {
            if (text) {
                $(select).html(text);
            }
            $(select).removeAttr("disabled");
        }
    }

    static setIsSaveing(btnSelect, flag) {
        if (flag) {
            $(btnSelect).html("保存中...");
            $(btnSelect).attr("disabled", "true");
        } else {
            $(btnSelect).html("保存");
            $(btnSelect).removeAttr("disabled");
        }
    }

    static isNull(val) {
        if (val == null || typeof val == 'undefined') {
            return true;
        }
        return false;
    }

    static isEmpty(val) {
        // val = val.trim();
        if (val == null || String(val) == '' || typeof val == 'undefined') {
            return true;
        }
        return false;
    }

    static transformEndTime(endtime) {
        var offset = endtime - new Date().getTime();
        if (offset > 0) {
            var days = Math.floor(offset / (24 * 60 * 60 * 1000));
            if (days > 0) {
                return days + '天';
            }
            var hours = Math.floor(offset / (60 * 60 * 1000));
            if (hours > 0) {
                return hours + '小时';
            }
            var minutes = Math.floor(offset / (60 * 1000));
            if (minutes > 0) {
                return minutes + '分钟';
            }
        }
    }

    static transformStartedTime(starttime) {
        var offset = new Date().getTime() - starttime;
        if (offset > 0) {
            var days = Math.floor(offset / (24 * 60 * 60 * 1000));
            if (days > 0) {
                return days + '天';
            }
            var hours = Math.floor(offset / (60 * 60 * 1000));
            if (hours > 0) {
                return hours + '小时';
            }
            var minutes = Math.floor(offset / (60 * 1000));
            if (minutes > 0) {
                return minutes + '分钟';
            }
        }
    }

    static initScroll(ref) {
        const options = {
            scrollY: true,
            // disableMouse: false,
            // disableTouch: true,
            mouseWheel: {
                speed: 20,
                invert: false,
                easeTime: 300
            },
            scrollbar: {
                fade: false,
                interactive: true // 1.8.0 新增
            },
            tap: true,
            preventDefault: false,
            preventDefaultException: {
                tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|DIV|UL|LI|P|SPAN|TABLE)$/,
                className: /(^|\s)editable(\s|$)/,
            }
        };
        return new BScroll(ref, options);
    }

    static initCollapse() {
        $('.mxd-collapse-trigger').click(function () {
            var target = $(this).attr('data-target');
            if (!$(target).hasClass('mxd-collapse')) {
                $(target).addClass('mxd-collapse');
                $(target).hide(100);
            } else {
                $(target).removeClass('mxd-collapse');
                $(target).show(100);
            }
        })
    }

    static wordLimit(limit, e) {
        var v = e.target.value;
        $(e.target).siblings('.word-limit').html(v.length + "/" + limit)
    }

    static initDatetimepicker(select, changeDate, option) {
        if (option) {
            $(select).datetimepicker(option).on('hide', function () {
                $(this).blur();
            }).on('changeDate', function () {
                if (changeDate) {
                    changeDate()
                }
            });
        } else {
            $(select).datetimepicker({
                format: 'yyyy-mm-dd hh:ii',
                language: "zh-CN",
                todayHighlight: true,
                autoclose: true
            }).on('hide', function () {
                $(this).blur();
            }).on('changeDate', function () {
                if (changeDate) {
                    changeDate()
                }
            });
        }
    }

    static getPreDate(date) {
        var now = new Date();
        var day = 24 * 60 * 60 * 1000;
        var dateb = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        var date0 = dateb + day;
        var date1 = date0 + day;
        var date2 = date1 + day;
        var datetime = date.getTime();
        var msg = '';
        if (datetime > dateb && datetime < date2) {
            var time = date.format('hh点mm分');
            if (datetime < date0) {
                msg = '今天' + time
            } else if (datetime < date1) {
                msg = '明天' + time
            } else if (datetime < date2) {
                msg = '后天' + time
            }
        } else {
            msg = date.format('yyyy-MM-dd hh:mm')
        }
        return msg;
    }

    static getDateLite(datetime) {
        var date = new Date(datetime);
        var now = new Date();
        var day = 24 * 60 * 60 * 1000;
        var dd = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        var date0 = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() + day;
        var date1 = date0 + day;
        var date2 = date1 + day;
        var msg = '';
        if (datetime >= dd && datetime < date2) {
            var time = date.format('hh点mm分ss秒');
            if (datetime < date0) {
                msg = '今天' + time
            } else if (datetime < date1) {
                msg = '明天' + time
            } else if (datetime < date2) {
                msg = '后天' + time
            }
        } else {
            msg = date.format('yyyy-MM-dd hh:mm:ss')
        }
        return msg;
    }

    // 时分秒转化成秒
    static transfromOutSecond(datetime) {
        var date = null, hour = null, minute = null, second = null;
        if (datetime) {
            date = new Date(datetime);
            hour = date.getHours();
            minute = date.getMinutes();
            second = date.getSeconds();
            second += hour * 60 * 60;
            second += minute * 60;
        }

        return second

    }

    //秒转化成时分秒
    static transfromInSecond(datetime) {
        var date = null, hour = null, minute = null, second = null, str = null;
        hour = parseInt(datetime / (60 * 60));
        minute = parseInt((datetime - hour * 60 * 60) / 60);
        second = datetime - hour * 60 * 60 - minute * 60;
        if (String(hour).length < 2) {
            hour = '0' + hour;
        }
        if (String(minute).length < 2) {
            minute = '0' + minute;
        }
        if (String(second).length < 2) {
            second = '0' + second;
        }
        str = hour + ':' + minute + ':' + second;
        return str;
    }

    static coverEmail(email) {
        if (email) {
            return email.substring(0, 3) + "******" + email.substring(email.indexOf('@') - 1);
        }
    }

    static showProductExpiredModal(msg) {
        // var name = '';
        // if (Path.isInTemplateMsg()) {
        //     name = '消息宝';
        // } else if (Path.isInTaskpost()) {
        //     name = '任务宝';
        // } else if (Path.isInShareTreasure()) {
        //     name = '分享宝'
        // } else if (Path.isInChannelQRCode()) {
        //     name = "渠道码"
        // }
        if (document.getElementById("overdueTips")) {
            return
        }
        let arr = msg.expireInfo
        let productName = "", productId = "", obj = {}, appId = "", appIdArr = [];
        // let arr = msg
        if (msg.type == 1) {
            confirm({
                title: '',
                icon: <Icon/>,
                width: 520,
                zIndex: 999999,
                content: (
                    <div id="overdueTips">
                        <div className="overdueTips">
                            <i className="icon-tips-modal overdueTipsIcon"></i>
                            <span className="overdueTipsWord">提示</span>
                            {
                                arr.map((data, index) => {
                                    productName = ProName.proName(data.productItem)
                                    productId = data.productItem
                                    // appId = data.appId;
                                    appIdArr.push(data.appId);
                                    return (
                                        <p className="overdueTipsCon">您的公众号<span
                                            className="tootip name">{data.nickName}</span>使用的<span
                                            className="tootip name">{productName}</span>产品已到期，建议您尽快升级会员，升级后方可继续使用该功能
                                        </p>
                                    )
                                })
                            }

                        </div>
                    </div>
                ),
                okText: "立即升级",
                cancelText: "取消",
                onOk() {
                    obj["itemType"] = productId;
                    obj["flag"] = 3;
                    obj["target"] = "_black"
                    obj["appId"] = appIdArr
                    window.sessionStorage.setItem("renew", JSON.stringify(obj))
                    let href = window.location.href.split(window.location.pathname)[0]
                    window.open(href + "/index/mine/purchase/confirm")
                },
                onCancel() {

                },
            });
        }


        if (msg.type == 2) {
            let overScanLimit = msg.overScanLimit;
            let codeType = msg.codeType;
            let codeText = null;
            if (codeType == 1) {
                codeText = '客服'
            }
            if (codeType == 2) {
                codeText = '群'
            }

            let type = msg.productType;
            let typeText = null;
            if (type == 9000) {
                typeText = '高级'
            }
            if (type == 9010) {
                typeText = '高级'
            }
            overScanLimit ?
                confirm({
                    title: '',
                    icon: <Icon/>,
                    width: 520,
                    zIndex: 999999,
                    content: (
                        <div><span>超过扫码限制，最大扫码次数为{msg.maxCount}次</span></div>
                    ),
                    okText: "确定",
                    cancelText: "取消",
                    onOk() {
                    },
                    onCancel() {

                    },
                }) :
                confirm({
                    title: '',
                    icon: <Icon/>,
                    width: 520,
                    zIndex: 999999,
                    content: (
                        <div>
                            <div className={'text-center'} style={{'margin-left': '-40px'}}>
                                <div className={'overdueTips'}>
                                    <i className="icon-tips-modal overdueTipsIcon"></i>
                                    <span className="overdueTipsWord">提示</span>
                                    您还不是<span
                                    className="tootip name">{codeText}{ProName.proName(msg.productType)}</span>会员，
                                    无法使用<span
                                    className="tootip name">{codeText}{ProName.proName(msg.productType)}{typeText}</span>功能。
                                    {/*<div className={'text-center'} style={{'margin-top': '15px'}}>*/}
                                    {/*    <img src={Customer} width={150} height={150}/>*/}
                                    {/*    <p>扫码联系客服</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    ),
                    okText: "立即升级",
                    cancelText: "取消",
                    onOk() {
                        let level = 1;
                        if (codeType == 1) {
                            level = 1;
                        }

                        if (codeType == 2) {
                            level = 2;
                        }
                        obj["itemType"] = msg.productType;
                        obj["flag"] = 2;
                        obj['level'] = level;
                        obj["target"] = "_black"
                        obj["appId"] = null
                        window.sessionStorage.setItem("renew", JSON.stringify(obj))
                        let href = window.location.href.split(window.location.pathname)[0]
                        window.open(href + "/index/mine/purchase/confirm")

                    },
                    onCancel() {

                    },
                });
        }

        if (msg.type == 3) {
            Modal.info({
                title: '',
                icon: <Icon/>,
                width: 520,
                zIndex: 999999,
                content: (
                    <div id="overdueTips">
                        <div className="overdueTips">
                            <i className="icon-tips-modal overdueTipsIcon"></i>
                            <span className="overdueTipsWord">提示</span>
                            {
                                arr.map((data, index) => {
                                    productName = ProName.proName(data.productType)
                                    productId = data.productType
                                    appIdArr.push(data.corpId);
                                    return (
                                        <p className="overdueTipsCon">您的企业微信<span
                                            className="tootip name">{data.corpName}</span>使用的<span
                                            className="tootip name">{productName}</span>产品已到期，添加下方客服微信申请开通时长
                                        </p>
                                    )
                                })
                            }
                            <div>
                                <img className='kfconImg' src={ImgWXqrcode}/>
                            </div>
                        </div>
                    </div>
                ),
                // okText: "立即升级",
                cancelText: "取消",
                // onOk() {
                //     obj["itemType"] = productId;
                //     obj["flag"] = 4;
                //     obj["target"] = "_black"
                //     obj["corpId"] = appIdArr
                //     window.sessionStorage.setItem("renew", JSON.stringify(obj))
                //     let href = window.location.href.split(window.location.pathname)[0]
                //     window.open(href + "/index/mine/purchase/confirm")
                // },
                onCancel() {

                },
            });
        }

        // $("#expriedProductName").html(name);

    }


    static checkDateTime(time) {
        var reg = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/;
        if (!reg.test(time)) {
            return false;
        }
        return true;
    }

    static getEmailLink(mail) {
        var arr = mail.split('@');
        if (arr.length > 1) {
            var $t = arr[1];
            $t = $t.toLowerCase();
            if ($t == '163.com') {
                return 'mail.163.com';
            } else if ($t == 'vip.163.com') {
                return 'vip.163.com';
            } else if ($t == '126.com') {
                return 'mail.126.com';
            } else if ($t == 'qq.com' || $t == 'vip.qq.com' || $t == 'foxmail.com') {
                return 'mail.qq.com';
            } else if ($t == 'gmail.com') {
                return 'mail.google.com';
            } else if ($t == 'sohu.com') {
                return 'mail.sohu.com';
            } else if ($t == 'tom.com') {
                return 'mail.tom.com';
            } else if ($t == 'vip.sina.com') {
                return 'vip.sina.com';
            } else if ($t == 'sina.com.cn' || $t == 'sina.com') {
                return 'mail.sina.com.cn';
            } else if ($t == 'tom.com') {
                return 'mail.tom.com';
            } else if ($t == 'yahoo.com.cn' || $t == 'yahoo.cn') {
                return 'mail.cn.yahoo.com';
            } else if ($t == 'tom.com') {
                return 'mail.tom.com';
            } else if ($t == 'yeah.net') {
                return 'www.yeah.net';
            } else if ($t == '21cn.com') {
                return 'mail.21cn.com';
            } else if ($t == 'hotmail.com') {
                return 'www.hotmail.com';
            } else if ($t == 'sogou.com') {
                return 'mail.sogou.com';
            } else if ($t == '188.com') {
                return 'www.188.com';
            } else if ($t == '139.com') {
                return 'mail.10086.cn';
            } else if ($t == '189.cn') {
                return 'webmail15.189.cn/webmail';
            } else if ($t == 'wo.com.cn') {
                return 'mail.wo.com.cn/smsmail';
            } else if ($t == '139.com') {
                return 'mail.10086.cn';
            }
        }
        return '';
    };

    static disableScroll() {
        window.contentScroll.disable();
    }

    static enableScroll() {
        window.contentScroll.enable();
    }

    static refreshScroll() {
        window.contentScroll.refresh();

    }

    static toTopScroll() {
        window.contentScroll.scrollTo(0, 0)
    }

    static getCorpId() {
        return corpid
    }

    static getProviderToken() {
        let fetchUtil = new FetchUtil()
        fetchUtil.init()
            .setUrl("https://qyapi.weixin.qq.com/cgi-bin/service/get_provider_token")
            .setMethod("POST")
            .setCookieCors()
            .setBody({
                corpid: corpid,
                provider_secret: provider_secret
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                let providerAccessToken = msg.provider_access_token
                return providerAccessToken
            })
            .catch((error) => {
                console.log(error)
            })
    }

    static initCorpWx(corpid) {

        let url = encodeURIComponent(window.location.href);
        // let url = encodeURIComponent('http://test.94mxd.com/index/corp/member/list');
        let fetchUtil = new FetchUtil()
        fetchUtil.init()
            .setUrl(URL_CORP_APP_AGENT_CONFIG + "?corpId=" + corpid + "&url=" + url)
            .setMethod("GET")
            .setCookieCors()
            .setBodyType('json')
            .dofetch()
            .then((msg) => {
                // data = JSON.parse(data)
                if (msg.respCode == 0) {
                    var data = msg.respMsg.config;
                    (async () => {
                        if (/MicroMessenger/i.test(navigator.userAgent)) {
                            await new Promise((resolve, reject) => {
                                window.wx.config({
                                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                    debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                    appId: corpid, // 必填，企业微信的corpID
                                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                                    signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                                    jsApiList: ['selectExternalContact'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                                });
                                window.wx.ready(resolve)
                                window.wx.error(reject)
                            })
                        }
                        await new Promise((success, fail) => {
                            window.wx.agentConfig({
                                corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                                agentid: Number(data.agentid), // 必填，企业微信的应用id （e.g. 1000247）
                                timestamp: data.timestamp, // 必填，生成签名的时间戳
                                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                                signature: data.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                                jsApiList: ['selectExternalContact'], //必填
                                success: function (res) {
                                    WWOpenData.useEffect(document.querySelectorAll('ww-open-data'))
                                },
                                fail: function (res) {
                                    if (res.errMsg.indexOf('function not exist') > -1) {
                                        alert('版本过低请升级')
                                    }
                                }
                            });
                        })
                        // 注意: 只有 agentConfig 成功回调后，WWOpenData 才会注入到 window 对象上面

                    })()

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    static dayOffToNow(expireAt) {
        let offset = expireAt - new Date().getTime();
        let fdays = offset * 1 / (24 * 60 * 60 * 1000);
        let days = Math.ceil(fdays);
        return days;
    }

    static msgSelectType() {
        return {
            SubsVal: "1",
            TASKPOSTER: "2"
        }
    }

    static uploadImage(files, size, success) {
        if (files.length === 0) {
            return;
        }
        let fileType = files[0].type.toLocaleLowerCase();
        if (!(fileType == 'image/png' || fileType == 'image/jpe' || fileType == 'image/jpeg')) {
            //不是图片文件
            Message.info('图片格式应为PNG/JPG/JPEG');
            return;
        }
        if (files[0].size > size) {
            //文件大小超过限制
            Message.info('图片大小超过' + (size / 1024) + 'KB');
            return;
        }

        let file = files[0];
        let fetchUtil = new FetchUtil();
        fetchUtil.init()
            .setUrl(URL_DISTRI_GOODS_UPLOAD)
            .setMethod('POST')
            .setCookieCors()
            .setBodyType('file')
            .setBody({
                uploadfile: file
            })
            .dofetch()
            .then((msg) => {
                if (msg.respCode == 0) {
                    if (success) {
                        success(msg);
                    }
                } else {
                    Message.info("上传图片失败，请稍后再试")
                }
            })
            .catch(() => {
                Message.error("上传图片失败，请稍后再试")
            });
    }

    /*
    * 动态导出
    * */
    static downLoadFile(url) {
        const ele = document.createElement('a');
        ele.setAttribute('href', url); //设置下载文件的url地址
        ele.click();
    }
}

export default Util